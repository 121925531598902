import "./PaiementPage.css";
import axios from "axios";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { VenteTitles } from "../../../constants/navigationTitles";
import { useCallback, useEffect, useState } from "react";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { useData } from "../../../context/DataContext";
import PaiementTable from "../../../components/Ventes/Paiement/PaiementTable/PaiementTable";

const PaiementPage = () => {
  const [payments, setPayments] = useState([]);
  const { users, reFetchUsers } = useData();
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const fetchAllPaymentsAppartments = async () => {
    if (!selectedDateRange.startDate || !selectedDateRange.endDate) return;

    const queryParams = new URLSearchParams({
      startDate: selectedDateRange.startDate.toISOString(),
      endDate: selectedDateRange.endDate.toISOString(),
    }).toString();

    try {
      const storedToken = localStorage.getItem("authToken");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/apartment/all-payments?${queryParams}`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      setPayments(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllPaymentsAppartments();
  }, [selectedDateRange]);

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    if (startDate && endDate) {
      setSelectedDateRange({ startDate, endDate });
    }
  }, []);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            onFilteredDataChange={handleFilteredDataChange}
            tabs={VenteTitles}
          />
        </div>
        <div className="bottomCrmPage">
          <PaiementTable
            data={payments}
            reFetch={fetchAllPaymentsAppartments}
            users={users}
            reFetchUsers={reFetchUsers}
          />
        </div>
      </div>
    </div>
  );
};

export default PaiementPage;
