import "./LeftSideInfo.css";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import axios from "axios";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSocket } from "../../../../context/SocketContext";
import { useData } from "../../../../context/DataContext";

const LeftSideInfo = ({ currentData, reFetchFromTable }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { status, users, reFetchAllLead } = useData();
  const [selectedStatus, setSelectedStatus] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState(
    currentData.assignTo ? currentData.assignTo._id : "Not assign"
  );

  const [leadData, setLeadData] = useState(currentData);
  const [updateLead, setUpdateLead] = useState({
    email: leadData.email,
    phone: leadData.phone,
    agencyName: leadData.agencyName,
    country: leadData.country,
    city: leadData.city,
    address: leadData.address,
    postalCode: leadData.postalCode,
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    if (currentData.name) {
      const names = currentData.name.split(" ");
      if (names.length > 1) {
        setLastName(names.pop());
        setFirstName(names.join(" "));
      } else {
        setFirstName(names[0]);
        setLastName("");
      }
    }
  }, [currentData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const pickerElement = document.querySelector(".dropdownSelectSales");
      if (pickerElement && !pickerElement.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    if (openDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else {
      setUpdateLead((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAssignmentChange = async (newAssigneeId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/update-assignation/${currentData._id}`,
        {
          newAssigneeId,
          leadId: currentData._id,
        }
      );
      setSelectedAssignee(newAssigneeId);
      reFetchFromTable();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'assignation :", error);
    }
  };

  const handleStatusChange = async (event, leadId) => {
    const newStatutId = event.target.value;
    setSelectedStatus((prev) => ({ ...prev, [leadId]: newStatutId }));

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/${leadId}`,
        { status: newStatutId }
      );

      reFetchFromTable();
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdatedLead = async (e) => {
    e.preventDefault();
    const updatedName = fullName.trim();
    const dataToSend = { ...updateLead, name: updatedName };

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/${currentData._id}`,
        dataToSend
      );
      setLeadData(dataToSend);
      setIsEditing(false);
      reFetchFromTable();
      reFetchAllLead();
    } catch (err) {
      console.log(err);
    }
  };

  const fullName = `${firstName} ${lastName}`;
  const assignedUser =
    users.find((user) => user._id === selectedAssignee) || {};

  return (
    <>
      <div className="popupLeftSideTop">
        <div className="editIconPopUp">
          <EditIcon onClick={() => setIsEditing(true)} />
        </div>
        <div className="ppCustomer">
          <img src="/images/user.png" alt="" />
        </div>
        <div className="popupLeftSideTopName">
          <span>{currentData.agencyName}</span>
        </div>
        <span style={{ color: "#00B161" }}>{currentData.reference}</span>
      </div>
      <div className="popupLeftSideBottom">
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Agence</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="agencyName"
            value={isEditing ? updateLead.agencyName : leadData.agencyName}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Prenom </label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="firstName"
            value={firstName}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Nom </label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="lastName"
            value={lastName}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>

        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Email</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="email"
            name="email"
            value={isEditing ? updateLead.email : leadData.email}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Phone</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="phone"
            value={isEditing ? updateLead.phone : leadData.phone}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Commission</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="percentage"
            value={isEditing ? updateLead.percentage : leadData.percentage}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>

        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Country</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="country"
            value={isEditing ? updateLead.country : leadData.country}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>

        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Status</label>
          <select
            className="popupLeftSideBottomFormSelect"
            value={
              selectedStatus[currentData._id] ||
              (currentData.status ? currentData.status._id : "default")
            }
            onChange={(e) => handleStatusChange(e, currentData._id)}
          >
            {currentData.status && (
              <option value={currentData.status._id}>
                {currentData.status.title}
              </option>
            )}
            {status.map((stat) => {
              if (!currentData.status || stat._id !== currentData.status._id) {
                return (
                  <option key={stat._id} value={stat._id}>
                    {stat.title}
                  </option>
                );
              }
              return null;
            })}
          </select>
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Address</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="address"
            value={isEditing ? updateLead.address : leadData.address}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Postal Code</label>
          <input
            className="popupLeftSideBottomFormInput"
            name="postalCode"
            value={isEditing ? updateLead.postalCode : leadData.postalCode}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">City</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="city"
            value={isEditing ? updateLead.city : leadData.city}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
      </div>
      <div className="containerNameAssign">
        {isEditing ? (
          <div className="isEditingButton">
            <button
              className="isEditingCancel"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
            <button className="isEditingValidate" onClick={handleUpdatedLead}>
              Save
            </button>
          </div>
        ) : (
          <div className="popupLeftSideBottomFormRowImgAndName">
            <label className="popupLeftSideBottomFormLabel">Assign to</label>

            <div
              className="imgAndNameAssignTo"
              onClick={() => setOpenDropdown(true)}
            >
              {assignedUser && (
                <>
                  {assignedUser.pPicture && (
                    <img
                      src={assignedUser.pPicture}
                      alt="Assigned person's profile"
                    />
                  )}
                  <span>{assignedUser.name || "Unassigned"}</span>
                  {openDropdown ? (
                    <KeyboardArrowDownIcon style={{ fontSize: "16px" }} />
                  ) : (
                    <KeyboardArrowUpIcon style={{ fontSize: "16px" }} />
                  )}
                </>
              )}
              {!assignedUser && <span>Not Assign</span>}
            </div>
          </div>
        )}
      </div>
      {openDropdown && (
        <div
          className="dropdownSelectSales"
          onClick={() => setOpenDropdown(false)}
        >
          {users
            .filter(
              (u) =>
                (u.isSales || u.isAdmin) &&
                (!assignedUser || u._id !== assignedUser._id)
            )
            .map((u) => (
              <div
                className="imgAndNameAssignToDropdowm"
                key={u._id}
                onClick={() => handleAssignmentChange(u._id, currentData._id)}
              >
                <img src={u.pPicture} alt={u.name} />
                <span>{u.name}</span>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default LeftSideInfo;
