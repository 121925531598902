import "./AssignAllLead.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../../hooks/useFetch";
import AddIcon from "@mui/icons-material/Add";

const AssignAllLead = ({
  setOpen,
  reFetchFromTable,
  checkedItems,
  setCheckedItems,
  setIsAllChecked,
  setShowLoader,
}) => {
  const { data: users, loading, reFetch: reFetchUsers } = useFetch("user");
  const [salesSelections, setSalesSelections] = useState([
    { userId: "", number: checkedItems.length },
  ]);

  useEffect(() => {
    setSalesSelections([{ userId: "", number: checkedItems.length }]);
  }, [checkedItems.length]);

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleAddSalesClick = () => {
    setSalesSelections([...salesSelections, { userId: "", number: 0 }]);
  };

  const handleSalesChange = (index, value) => {
    const updatedSalesSelections = salesSelections.map((selection, i) => {
      if (i === index) {
        return { ...selection, userId: value };
      }
      return selection;
    });
    setSalesSelections(updatedSalesSelections);
  };

  const handleNumberChange = (index, value) => {
    let newValue = Math.max(0, Math.min(value, checkedItems.length));
    let updatedSalesSelections = [...salesSelections];
    updatedSalesSelections[index] = {
      ...updatedSalesSelections[index],
      number: newValue,
    };

    // Calculer le total de leads assignés et les leads restants
    let totalAssigned = updatedSalesSelections.reduce(
      (sum, item) => sum + item.number,
      0
    );
    let remainingLeads = checkedItems.length - totalAssigned;

    if (salesSelections.length > 2) {
      updatedSalesSelections[index].isLocked = true; // Verrouillage de l'utilisateur actuel

      // Redistribuer les leads restants entre les utilisateurs non verrouillés pour les cas de 3 utilisateurs ou plus
      updatedSalesSelections.forEach((selection, i) => {
        if (i !== index && !selection.isLocked) {
          if (remainingLeads > 0) {
            let possibleIncrement = Math.min(
              remainingLeads,
              checkedItems.length - selection.number
            );
            updatedSalesSelections[i].number += possibleIncrement;
            remainingLeads -= possibleIncrement;
          } else if (remainingLeads < 0) {
            let reduction = Math.min(selection.number, -remainingLeads);
            updatedSalesSelections[i].number -= reduction;
            remainingLeads += reduction;
          }
        }
      });
    } else {
      // Logique originale pour 2 utilisateurs ou moins
      updatedSalesSelections.forEach((selection, i) => {
        if (i !== index) {
          if (remainingLeads > 0) {
            let possibleIncrement = Math.min(
              remainingLeads,
              checkedItems.length - selection.number
            );
            updatedSalesSelections[i].number += possibleIncrement;
            remainingLeads -= possibleIncrement;
          } else if (remainingLeads < 0) {
            let reduction = Math.min(selection.number, -remainingLeads);
            updatedSalesSelections[i].number -= reduction;
            remainingLeads += reduction;
          }
        }
      });
    }

    setSalesSelections(updatedSalesSelections);
  };

  const handleAssignRandom = (event) => {
    // Check if the checkbox is checked
    const shouldRandomize = event.target.checked;

    if (shouldRandomize) {
      // Calculate the total number of leads available
      const totalLeads = checkedItems.length;
      // Determine an equal distribution of leads per sales agent
      const leadsPerUser = Math.floor(totalLeads / salesSelections.length);
      // Calculate the number of leads remaining after equal distribution
      let remainingLeads = totalLeads - leadsPerUser * salesSelections.length;

      // Assign an equal number of leads to each sales agent
      const updatedSalesSelections = salesSelections.map((selection) => ({
        ...selection,
        number: leadsPerUser,
      }));

      // Randomly distribute the remaining leads among the sales agents
      while (remainingLeads > 0) {
        // Select a random sales agent
        const randomIndex = Math.floor(
          Math.random() * updatedSalesSelections.length
        );
        // Increment the lead count for the selected agent
        updatedSalesSelections[randomIndex].number += 1;
        // Decrease the count of remaining leads
        remainingLeads -= 1;
      }

      // Update the state with the new distribution of leads
      setSalesSelections(updatedSalesSelections);
    } else {
      // Optional: Handle the case when the checkbox is unchecked
    }
  };

  const handleValidation = async () => {
    setOpen(false);
    setShowLoader(true);
    try {
      // Préparation des assignations pour l'envoi
      const assignments = salesSelections
        .filter((selection) => selection.userId && selection.number > 0)
        .map((selection) => ({
          userId: selection.userId,
          leadIds: checkedItems.splice(0, selection.number),
        }));

      // Envoi des assignations au serveur
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/assign-leads`,
        { assignments }
      );

      setShowLoader(false);
      setCheckedItems([]);
      setIsAllChecked(false);
      reFetchFromTable();
    } catch (error) {
      console.error("Error during the assignment:", error);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="assignAllLead" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="assignAllLeadWrapper">
            <div className="topAssignAllLead">
              <span>Assign Sales</span>
            </div>
            <div className="numberLeadToAssign">
              <span>{checkedItems.length} leads to assign</span>
            </div>
            <div className="middleAssignAllLead">
              <div className="wrapperAssignAllLead">
                {salesSelections.map((selection, index) => (
                  <div className="assignAllLeadItem" key={index}>
                    <select
                      value={selection.userId}
                      onChange={(e) => handleSalesChange(index, e.target.value)}
                    >
                      <option value="">All Sales</option>
                      {users.map(
                        (user) =>
                          user.isSales && (
                            <option key={user._id} value={user._id}>
                              {user.name}
                            </option>
                          )
                      )}
                    </select>
                    <input
                      type="number"
                      min="0"
                      value={selection.number}
                      onChange={(e) =>
                        handleNumberChange(index, e.target.value)
                      }
                    />
                  </div>
                ))}
                <div className="addSalesAgent" onClick={handleAddSalesClick}>
                  <AddIcon />
                  <span>Add sales</span>
                </div>
                <div className="randomizeLeads">
                  <span>Randomize :</span>
                  <input type="checkbox" onChange={handleAssignRandom} />
                </div>
              </div>
            </div>
            <div className="bottomAssignAllLead">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleValidation}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignAllLead;
