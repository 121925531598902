export const AgenceTitles = [
  { name: "Attribution", path: "/management/crm/attribution" },
  { name: "Agences", path: "/management/crm/customers" },
  { name: "Signé", path: "/management/crm/signe" },
];
export const ProjetTitles = [
  { name: "Projet", path: "/management/projets/projet" },
  { name: "Appartements", path: "/management/projets/appartement" },
];
export const VenteTitles = [
  { name: "Vente", path: "/management/ventes/vente" },
  { name: "Client", path: "/management/ventes/client" },
  { name: "Paiement", path: "/management/ventes/paiement" },
];

export const AccountTitles = [
  { name: "Admin", path: "/management/accounts/admin" },
  { name: "Sales", path: "/management/accounts/sales" },
];
export const PromoteTitles = [
  { name: "Promote", path: "/management/promote/promote" },
  { name: "Template", path: "/management/promote/template" },
];

export const SettingsTitles = [
  { name: "Payments", path: "/management/settings/payments" },
  { name: "Status", path: "/management/settings/status" },
];

export const CatalogueTitles = [
  { name: "Products", path: "/management/catalogue/products" },
  { name: "Suppliers", path: "/management/catalogue/suppliers" },
];

export const DashboardTitles = [{ name: "Dashboard", path: "/management/" }];

export const BankingTitles = [
  { name: "Deposit", path: "/management/banking/deposit" },
  { name: "Refunds", path: "/management/banking/refund" },
  { name: "Pending", path: "/management/banking/pending" },
  { name: "Sales", path: "/management/banking/salesLead" },
  { name: "Rebought", path: "/management/banking/rebought" },
  { name: "Withdrawals", path: "/management/banking/withdrawals" },
];

export const CampaignTitles = [
  { name: "Campaign", path: "/management/campaign/dashboard" },
];
