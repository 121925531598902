import "./PopupCustomerContainer.css";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LeftSideInfo from "../LeftSideInfo/LeftSideInfo";
import TopTab from "../RightSide/TopTab/TopTab";
import { useSocket } from "../../../../context/SocketContext";
import useFetch from "../../../../hooks/useFetch";
import axios from "axios";
const PopupCustomerContainer = ({
  data,
  setOpen,
  reFetchFromTable,
  leads,
  reFetchAllLead,
}) => {

  const [findCorrespondingLead, setFindCorrespondingLead] = useState(null);
  const { socket } = useSocket();

  // Méthode pour re-fetcher les données d'un lead spécifique
  const fetchLeadData = async (leadId) => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/${leadId}`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setFindCorrespondingLead(response.data);
    } catch (error) {
      console.error("Error fetching lead data:", error);
    }
  };

  useEffect(() => {
    fetchLeadData(data._id);

    if (!socket) return;

    const refreshDataListener = () => reFetchAllLead();
    socket.on("refreshDataPlatform", refreshDataListener);

    return () => {
      socket.off("refreshDataPlatform", refreshDataListener);
    };
  }, [socket, data._id]);

  if (!findCorrespondingLead) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className="overlayPopupCustomer"
        onClick={() => setOpen(false)}
      ></div>
      <div className="popupContainer">
        <div className="closeIconPopupCustomer" onClick={() => setOpen(false)}>
          <CloseIcon />
        </div>
        <div className="popupWrapper">
          <div className="popupLeftSide">
            <LeftSideInfo
              currentData={findCorrespondingLead}
              reFetchFromTable={reFetchFromTable}
            />
          </div>
          <div className="popupRigthSide">
            <TopTab
              currentData={findCorrespondingLead}
              reFetchFromTable={reFetchFromTable}
              leads={leads}
              reFetchAllLead={reFetchAllLead}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupCustomerContainer;
