import "./DeleteMessage.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";

const DeleteMessage = ({
  setOpen,
  reFetchFromTable,
  endPoint,
  name,
  allLeads,
}) => {
  const handleDelete = async (templateName) => {
    try {
      const response = await axios.delete(
        `https://graph.facebook.com/v20.0/${process.env.REACT_APP_BUSINESS_ID}/message_templates?name=${name}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_FB}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        console.log(
          `Le template "${templateName}" a été supprimé avec succès.`
        );
      } else {
        console.log(`La suppression du template "${templateName}" a échoué.`);
      }
    } catch (err) {
      console.error(
        `Erreur lors de la suppression du template "${templateName}":`,
        err.response?.data || err.message
      );
    }
  };
  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus">
          <div className="closeIconStatus">
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>DEL</span>
            </div>
            <div className="middleCreateStatus">
              <span>Are you sure you want to perform this action?</span>
            </div>
            <div className="bottomCreateStatus">
              <button
                className="cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button className="saveButton" onClick={handleDelete}>
                <CheckIcon />
                Comfirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteMessage;
