import "./CreatePayment.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import axios from "axios";

const CreatePayment = ({ setOpen, reFetchFromTable }) => {
  const [newPayment, setNewPayment] = useState({
    title: "",
  });

  const handleClickInside = (e) => {
    e.stopPropagation(); 
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPayment((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleAddPayment = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/payment/`,
        newPayment
      );
      reFetchFromTable();
      setNewPayment({ title: "" });
      setOpen(false)
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>New Payment</span>
            </div>
            <div className="middleCreateStatus">
              <label>Name</label>
              <input
                type="text"
                name="title"
                value={newPayment.title}
                onChange={handleChange}
              />
            </div>
            <div className="bottomCreateStatus">
              <button className="cancelButton">Cancel</button>
              <button className="saveButton" onClick={handleAddPayment}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePayment;
