import "./AddPaymentPopup.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import { useData } from "../../../../context/DataContext";

const AddPaymentPopup = ({
  setOpen,
  paymentsType,
  currentData,
  updatePayments,
}) => {
  const { reFetchAppartments, reFetchClients, refetchVentes, reFetchProjects } =
    useData();
  const [newPayment, setNewPayment] = useState({
    amount: "",
    paymentDate: new Date().toISOString(),
    typePayment: "",
    client: currentData.client._id || "",
  });

  const handleAddPayment = async () => {
    const totalPayments = currentData.appartment.payment.reduce(
      (acc, payment) => acc + payment.amount,
      0
    );
    const paymentAmount = parseFloat(newPayment.amount); // S'assurer que le montant est un nombre

    try {
      if (!newPayment.typePayment) {
        alert("Le type de paiement est requis");
        return;
      }

      // Vérifiez si le nouveau paiement dépasse le prix total de l'appartement
      if (totalPayments + paymentAmount > currentData.appartment.price) {
        alert("Le paiement dépasse le prix total de l'appartement");
        return;
      }

      const paymentData = {
        amount: parseFloat(newPayment.amount),
        paymentDate: newPayment.paymentDate,
        typePayment: newPayment.typePayment,
        client: newPayment.client,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/apartment/add-payment/${currentData.appartment._id}`,
        paymentData
      );

      updatePayments(response.data.payment); // Update with the latest payments from the backend
      reFetchAppartments();
      reFetchClients();
      refetchVentes();
      reFetchProjects();
      // Reset the form fields
      setNewPayment({
        amount: "",
        paymentDate: new Date().toISOString(),
        typePayment: "", // Reset this to ensure proper selection for the next payment
        client: currentData.client._id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeletePayment = async (paymentId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/apartment/delete-payment/${currentData.appartment._id}`,
        { paymentId }
      );

      updatePayments(response.data.payment);
      reFetchAppartments();
      reFetchClients();
      refetchVentes();
      reFetchProjects();
    } catch (err) {
      console.error("Error deleting payment:", err);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewPayment({ ...newPayment, [name]: value });
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div
          className="createPayment"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="closeIconPayment">
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </div>
          <div className="createPaymentWrapper">
            <div className="topCreatePayment">
              <span>Ajouter un paiement</span>
            </div>
            <div className="progressContainerPopup">
              {Array.isArray(currentData.appartment.payment) &&
                currentData.appartment.payment.map((payment, index, array) => {
                  const percentage =
                    (payment.amount / currentData.appartment.price) * 100;

                  let backgroundColor;
                  if (array.length === 1) {
                    // Si un seul paiement, appliquer la couleur de départ
                    backgroundColor = "#00B161";
                  } else {
                    const startColor = "#00B161";
                    const endColor = "#00C6FF";
                    const colorStep = index / (array.length - 1);
                    backgroundColor = `rgba(${Math.round(
                      (1 - colorStep) * parseInt(startColor.slice(1, 3), 16) +
                        colorStep * parseInt(endColor.slice(1, 3), 16)
                    )}, ${Math.round(
                      (1 - colorStep) * parseInt(startColor.slice(3, 5), 16) +
                        colorStep * parseInt(endColor.slice(3, 5), 16)
                    )}, ${Math.round(
                      (1 - colorStep) * parseInt(startColor.slice(5, 7), 16) +
                        colorStep * parseInt(endColor.slice(5, 7), 16)
                    )}, 0.8)`;
                  }

                  return (
                    <div
                      key={index}
                      className="progressSegmentPopup"
                      style={{
                        width: `${percentage}%`,
                        backgroundColor,
                      }}
                    >
                      <div className="paymentTooltipPopup">
                        <span>
                          {payment.amount.toLocaleString("en-US")} € -{" "}
                          {new Date(payment.paymentDate).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  );
                })}
              <div className="progressPricePopup">
                {Array.isArray(currentData.appartment.payment)
                  ? currentData.appartment.payment
                      .reduce((acc, payment) => acc + (payment?.amount || 0), 0)
                      .toLocaleString("en-US")
                  : 0}{" "}
                € /{" "}
                {currentData.appartment.price.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div>

            <div className="middleCreatePayment">
              <div className="middleCreatePaymentTop">
                <select
                  name="typePayment"
                  value={newPayment.typePayment}
                  onChange={handleChange}
                >
                  <option value="">Choisir un type de paiement</option>
                  {paymentsType.map((p) => (
                    <option key={p._id} value={p._id}>
                      {p.title}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  placeholder="Entrer un montant"
                  name="amount"
                  value={newPayment.amount}
                  onChange={handleChange}
                />
                <input
                  type="date"
                  name="paymentDate"
                  value={newPayment.paymentDate}
                  onChange={handleChange}
                />
                <button onClick={handleAddPayment}>Valider</button>
              </div>
              <div className="middleCreatePaymentBottom">
                <label>Tout les paiements</label>
                <table className="tablePayments">
                  <thead>
                    <tr>
                      <th>Montant</th>
                      <th>Type de paiement</th>
                      <th>Client</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.appartment.payment &&
                      currentData.appartment.payment.map((p, index) => (
                        <tr key={index}>
                          <td>{p.amount || "N/A"}</td>
                          <td>{p.typePayment?.title || "N/A"}</td>
                          <td>{p.client?.name || "N/A"}</td>
                          <td>
                            {new Date(p.paymentDate).toLocaleDateString()}
                          </td>
                          <td>
                            {/* <span className="hover-effect">edit</span> */}
                            <span
                              className="hover-effect"
                              onClick={() => handleDeletePayment(p._id)}
                            >
                              del
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="bottomCreatePayment">
              <button
                className="cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button className="saveButton">
                <CheckIcon />
                Comfirm
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPaymentPopup;
