import React, { createContext, useContext, useState } from 'react';

// Création du contexte
const OpenTabContext = createContext();

// Composant fournisseur
export const OpenTabProvider = ({ children }) => {
  const [openTab, setOpenTab] = useState('');

  const handleOpenTab = (tabName) => {
    setOpenTab(tabName);
  };

  return (
    <OpenTabContext.Provider value={{ openTab, handleOpenTab }}>
      {children}
    </OpenTabContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useOpenTab = () => useContext(OpenTabContext);
