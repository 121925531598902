import "./PulseLoaderPopup.css"
import PulseLoader from "react-spinners/PulseLoader";

const PulseLoaderPopup = () => {
  return (
    <>
        <div className="overlay"></div>
      <div className="pulseLoaderPopup">
        <div className="topPulseLoaderPopup">
          <PulseLoader size={20} color="#36d7b7" cssOverride={{position: "relative", display: "flex"}}/>
        </div>
        <div className="bottomPulseLoaderPopup">
          <p>Delete in progress...</p>
        </div>
      </div>
    </>
  )
}

export default PulseLoaderPopup