import "./SelectOptionsTable.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";

const SelectOptionsTable = ({
  setOpen,
  selectCurrentPage,
  selectAllPages,
  allPageData,
  pageData,
  setIsAllChecked,
  name,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.id);
  };

  const handleSave = () => {
    if (selectedOption === "currentPage") {
      selectCurrentPage();
      setIsAllChecked(true);
    } else if (selectedOption === "allPages") {
      selectAllPages();
      setIsAllChecked(true);
    }
    setOpen(false);
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="selectOptions" onClick={handleClickInside}>
          <div className="selectOptionsWrapper">
            <div className="closeIconStatus">
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <div className="topSelectOptions">
              <span>Choose Selection</span>
            </div>
            <div className="middleSelectOptions">
              <span>Do you want to select customers</span>
              <div className="checkboxOption">
                <input
                  type="radio"
                  id="currentPage"
                  name="selectionOption"
                  onChange={handleOptionChange}
                />
                <label htmlFor="currentPage">
                  For this page only ({pageData && pageData} customers) ?
                </label>
              </div>
              <div className="checkboxOption">
                <input
                  type="radio"
                  id="allPages"
                  name="selectionOption"
                  onChange={handleOptionChange}
                />
                <label htmlFor="allPages">
                  For all the pages (
                  {name === "attribution"
                    ? allPageData && allPageData.length
                    : name === "customer"
                    ? allPageData && allPageData
                    : ""}{" "}
                  customers) ?
                </label>
              </div>
            </div>
            <div className="bottomSelectOptions">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleSave}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectOptionsTable;
