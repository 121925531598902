import "./AdminPage.css";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import { AccountTitles } from "../../../constants/navigationTitles";
import Admin from "../../../components/Accounts/Admin/Admin";
import useFetch from "../../../hooks/useFetch";
import { useState, useEffect } from "react";
import DeleteMessage from "../../../components/DeleteMessage/DeleteMessage";

const AdminPage = () => {
  const { data: users, reFetch } = useFetch("user");
  const [allAdmins, setAllAdmins] = useState([]);
  const [showDeleteAdminMessage, setShowDeleteAdminMessage] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);

  useEffect(() => {
    const adminUsers = users.filter((u) => u.isAdmin);
    setAllAdmins(adminUsers);
  }, [users]);

  const openDeleteAdminPopup = (adminId) => {
    setSelectedAdminId(adminId);
    setShowDeleteAdminMessage(true);
  };

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <div className="navigationAndFilter">
            <NavigationTabs tabs={AccountTitles} />
          </div>
        </div>
        <div className="bottomCrmPage">
          <Admin
            data={allAdmins}
            onDeleteClick={openDeleteAdminPopup}
            reFetchFromTable={reFetch}
          />
          {showDeleteAdminMessage && (
            <DeleteMessage
              setOpen={setShowDeleteAdminMessage}
              id={selectedAdminId}
              reFetchFromTable={reFetch}
              endPoint={"user"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
