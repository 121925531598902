import "./PromoteTable.css";
import { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteMessage from "../../../DeleteMessage/DeleteMessage";
import CreatePromoteWithTemplate from "../CreatePromoteWithTemplate/CreatePromoteWithTemplate";
import StartPromoteNew from "../StartPromoteNew/StartPromoteNew";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
const PromoteTable = ({ data, reFetch }) => {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openCreateProjet, setOpenCreateProjet] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [startPromotePopup, setStartPromotePopup] = useState(null);
  const [loading, setLoading] = useState(false); // Move loading state here
  const [resultMessage, setResultMessage] = useState(""); // Move resultMessage state here

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleOpenDeleteMessage = (projetId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId(projetId);
  };

  const handleOpenStartPromote = (promote) => {
    setStartPromotePopup(true);
    setSelectedData(promote);
  };

  const handleResultMessageNull = () => {
    setResultMessage(""); // Close the result message by setting it to an empty string
    setLoading(false); // Ensure loading state is set to false
  };

  return (
    <div>
      {loading && (
        <div className="loadingPopup">
          <p>Waiting for Facebook's response...</p>
        </div>
      )}

      {/* Result popup */}
      {resultMessage && (
        <>
          <div className="overlay">
            <div className="createCampaignResult">
              <div className="closeIconCampaignResult">
                <CloseIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleResultMessageNull(); // Close the popup when clicking the close icon
                  }}
                />
              </div>
              <div className="createCampaignResultWrapper">
                <div className="topCreateCampaignResult">
                  <span>Campaign result</span>
                </div>
                <div className="middleCreateCampaignResult">
                  <span>{resultMessage}</span>
                </div>
                <div className="bottomCreateCampaignResult">
                  <button
                    className="saveButton"
                    onClick={handleResultMessageNull}
                  >
                    <CheckIcon />
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Campagne : {data?.length}</span>
            </div>
            <div className="rightElements">
              <button onClick={() => setOpenCreateProjet(true)}>
                <AddIcon />
                <span>Crée Campagne</span>
              </button>
            </div>
          </div>
          <div className="pastilleContainer">
            {data?.map((item, index) => {
              return (
                <div className="pastilleItem" key={index}>
                  <div className="pastilleItemLeft">
                    <div className="pastilleItemLeftIcon">
                      <ChatBubbleOutlineIcon style={{ color: "grey" }} />
                    </div>
                    <div className="pastilleItemLeftRight">
                      <span>{item.name}</span>
                      <span>Started June 27, 2:23 pm, No finish date </span>
                      <span></span>
                    </div>
                  </div>
                  <div className="pastilleItemRight">
                    <div className="pastilleItemRightContainer">
                      <div className="pastilleItemRightContainerItem">
                        <div className="pastilleItemRightContainerItemTop">
                          Projet
                        </div>
                        <div className="pastilleItemRightContainerItemBottom">
                          {item.project.name}
                        </div>
                      </div>
                      <div className="pastilleItemRightContainerItem">
                        <div className="pastilleItemRightContainerItemTop">
                          Send
                        </div>
                        <div className="pastilleItemRightContainerItemBottom">
                          {item.messagesSent}
                        </div>
                      </div>
                      <div className="pastilleItemRightContainerItem">
                        <div className="pastilleItemRightContainerItemTop">
                          Distribued
                        </div>
                        <div className="pastilleItemRightContainerItemBottom">
                          {item.messagesDelivered}
                        </div>
                      </div>
                      <div className="pastilleItemRightContainerItem">
                        <div className="pastilleItemRightContainerItemTop">
                          Read
                        </div>
                        <div className="pastilleItemRightContainerItemBottom">
                          {item.messagesOpened}
                        </div>
                      </div>
                      <div className="pastilleItemRightContainerItem">
                        <div className="pastilleItemRightContainerItemTop">
                          Reply
                        </div>
                        <div className="pastilleItemRightContainerItemBottom">
                          {item.messagesReplied}
                        </div>
                      </div>
                      <div className="pastilleItemRightContainerItem">
                        <div className="pastilleItemRightContainerItemTop">
                          Not send
                        </div>
                        <div className="pastilleItemRightContainerItemBottom">
                          {item.messagesFailed}
                        </div>
                      </div>
                    </div>
                    <div className="pastilleSpan">
                      <span
                        style={{ fontSize: "12px" }}
                        onClick={() => handleOpenStartPromote(item)}
                      >
                        <PlayCircleOutlineIcon />
                      </span>
                      <span
                        style={{ fontSize: "12px" }}
                        onClick={() => handleOpenDeleteMessage(item._id)}
                      >
                        del
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />
                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">Campagne</div>
                </th>
                <th>
                  <div className="headerWithIcon">Projet</div>
                </th>
                <th>
                  <div className="headerWithIcon">Envoyés</div>
                </th>
                <th>
                  <div className="headerWithIcon">Distribués</div>
                </th>
                <th>
                  <div className="headerWithIcon">Ouverts</div>
                </th>
                <th>
                  <div className="headerWithIcon">Lus</div>
                </th>
                <th>
                  <div className="headerWithIcon">Répondu</div>
                </th>
                <th>
                  <div className="headerWithIcon">Non Envoyés</div>
                </th>
                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                .slice(currentPage * perPage, (currentPage + 1) * perPage)
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <label className="container">
                          <input
                            type="checkbox"
                            checked={checkedItems.includes(item.leadId)}
                            onChange={(e) =>
                              handleCheckboxChangeWrapper(e, item.leadId)
                            }
                          />
                          <div className="checkmark"></div>
                        </label>
                      </td>
                      <td>{item.name}</td>
                      <td>{item.project.name}</td>
                      <td>{item.messagesSent}</td>
                      <td>{item.messagesDelivered}</td>
                      <td>{item.messagesOpened}</td>
                      <td>{item.messagesRead}</td>
                      <td>{item.messagesReplied}</td>
                      <td>{item.messagesFailed}</td>
                      <td>
                        <span
                          className="hover-effect"
                          onClick={() => handleOpenStartPromote(item)}
                        >
                          start
                        </span>
                        <span
                          className="hover-effect"
                          onClick={() => handleOpenDeleteMessage(item._id)}
                        >
                          del
                        </span>
                      </td>
                    </tr>
                  );
                })}

              {data.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
  */}

          {openCreateProjet && (
            <CreatePromoteWithTemplate
              setOpen={setOpenCreateProjet}
              reFetchFromTable={reFetch}
              setLoading={setLoading} // Pass down setLoading
              setResultMessage={setResultMessage} // Pass down setResultMessage
            />
          )}
          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              endPoint={"promote"}
              id={selectedDeletedId}
            />
          )}
          {startPromotePopup && (
            <StartPromoteNew
              setOpen={setStartPromotePopup}
              reFetchFromTable={reFetch}
              selectedData={selectedData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoteTable;
