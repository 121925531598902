import "./LeftSideInfo.css";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import axios from "axios";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useData } from "../../../../../context/DataContext";

const LeftSideInfo = ({ currentData, reFetchFromTable }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { reFetchAllLead } = useData();
  const [isEditing, setIsEditing] = useState(false);

  const [leadData, setLeadData] = useState(currentData);
  const [updateClient, setUpdateClient] = useState({
    email: leadData.email,
    phone: leadData.phone,
    name: leadData.agencynameName,
    country: leadData.country,
    city: leadData.city,
    address: leadData.address,
    postalCode: leadData.postalCode,
    idendity: leadData.idendity,
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    if (currentData.name) {
      const names = currentData.name.split(" ");
      if (names.length > 1) {
        setLastName(names.pop());
        setFirstName(names.join(" "));
      } else {
        setFirstName(names[0]);
        setLastName("");
      }
    }
  }, [currentData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const pickerElement = document.querySelector(".dropdownSelectSales");
      if (pickerElement && !pickerElement.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    if (openDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else {
      setUpdateClient((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleUpdatedClient = async (e) => {
    e.preventDefault();
    const updatedName = fullName.trim();
    const dataToSend = { ...updateClient, name: updatedName };

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/client/${currentData._id}`,
        dataToSend
      );
      setLeadData(dataToSend);
      setIsEditing(false);
      reFetchFromTable();
      reFetchAllLead();
    } catch (err) {
      console.log(err);
    }
  };

  const fullName = `${firstName} ${lastName}`;

  return (
    <>
      <div className="popupLeftSideTop">
        <div className="editIconPopUp">
          <EditIcon onClick={() => setIsEditing(true)} />
        </div>
        <div className="ppCustomer">
          <img src="/images/user.png" alt="" />
        </div>
        <div className="popupLeftSideTopName">
          <span>{currentData.name}</span>
        </div>
        {/* <span style={{ color: "#00B161" }}>{currentData.reference}</span> */}
      </div>
      <div className="popupLeftSideBottom">
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Prenom </label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="firstName"
            value={firstName}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Nom </label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="lastName"
            value={lastName}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>

        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Email</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="email"
            name="email"
            value={isEditing ? updateClient.email : leadData.email}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Phone</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="phone"
            value={isEditing ? updateClient.phone : leadData.phone}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>

        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Country</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="country"
            value={isEditing ? updateClient.country : leadData.country}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>

        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Address</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="address"
            value={isEditing ? updateClient.address : leadData.address}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Postal Code</label>
          <input
            className="popupLeftSideBottomFormInput"
            name="postalCode"
            value={isEditing ? updateClient.postalCode : leadData.postalCode}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">City</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="city"
            value={isEditing ? updateClient.city : leadData.city}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">
            Numéro d'identité
          </label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="idendity"
            value={isEditing ? updateClient.idendity : leadData.idendity}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
      </div>
      <div className="containerNameAssign">
        {isEditing ? (
          <div className="isEditingButton">
            <button
              className="isEditingCancel"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
            <button className="isEditingValidate" onClick={handleUpdatedClient}>
              Save
            </button>
          </div>
        ) : (
          <div className="popupLeftSideBottomFormRowImgAndName">
            <label className="popupLeftSideBottomFormLabel">Agence</label>

            <div className="imgAndNameAssignTo">
              {currentData.agence.agencyName}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LeftSideInfo;
