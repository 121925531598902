import "./AgencePage.css";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { AgenceTitles } from "../../../constants/navigationTitles";
import { useState, useCallback, useContext, useEffect } from "react";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { AuthContext } from "../../../context/auth.context";
import axios from "axios";
import AgenceTable from "../../../components/CRM/Customers/AgenceTable/AgenceTable";
import { useSocket } from "../../../context/SocketContext";
import { useData } from "../../../context/DataContext";

const AgencePage = () => {
  const { status, allLeads, users, reFetchUsers, reFetchAllLead } = useData();
  const { user } = useContext(AuthContext);
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalLeads, setTotalLeads] = useState(0);
  const { socket } = useSocket();
  const [selectedFilters, setSelectedFilters] = useState({
    user: "All Sales",
    status: "All Status",
    country: "All Countries",
  });

  // Stockez les dates sélectionnées dans un état
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const fetchLeads = async () => {
    const queryParams = new URLSearchParams({
      ...selectedFilters,
      page: currentPage,
      userId: user._id,
      limit: 50,
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();

    const storedToken = localStorage.getItem("authToken");
    const apiEndpoint = user.isSuperAdmin
      ? "/api/agence/paginationSuper"
      : "/api/agence/paginationUser";

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}${apiEndpoint}?${queryParams}`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setLeads(response.data.leads);
      setTotalLeads(response.data.total);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, [currentPage, selectedFilters, selectedDateRange, user.isSuperAdmin]);

  useEffect(() => {
    if (socket == null) return;

    const handleRefresh = () => {
      fetchLeads();
    };
    socket.on("refreshData", handleRefresh);

    return () => {
      socket.off("refreshData", handleRefresh);
    };
  }, [selectedFilters, socket]);

  // Modifier la fonction onFilteredDataChange pour mettre à jour la plage de dates sélectionnées
  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  const filteredNoSignedAgency = leads.filter((l) => !l.signed.isSigned);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            tabs={AgenceTitles}
            onFilteredDataChange={handleFilteredDataChange}
          />
        </div>
        <div className="bottomCrmPage">
          <AgenceTable
            data={filteredNoSignedAgency}
            reFetch={fetchLeads}
            currentPage={currentPage}
            setPage={setCurrentPage}
            totalLeads={totalLeads}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            userId={user._id}
            status={status}
            users={users}
            reFetchUsers={reFetchUsers}
            allLeads={allLeads}
            reFetchAllLead={reFetchAllLead}
          />
        </div>
      </div>
    </div>
  );
};

export default AgencePage;
