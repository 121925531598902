import "./AddDeposit.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSocket } from "../../../../context/SocketContext";

const AddDeposit = ({ setOpen, reFetch, reFetchLead }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: leads } = useFetch("lead");
  const { data: users } = useFetch("user");
  const { data: payments } = useFetch("payment");
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [deposit, setDeposit] = useState([]);
  const [selectedSalesId, setSelectedSalesId] = useState("Select");
  const [newDeposit, setNewDeposit] = useState({
    amount: 0,
    depositDate: new Date().toISOString(),
    typePayment: "",
  });
  const { emitDeposit, emitRefreshDataPlatform } = useSocket();

  useEffect(() => {
    const findCorrespondingLead = leads.find((l) => l._id === selectedLeadId);

    if (findCorrespondingLead) {
      setDeposit(findCorrespondingLead.deposit);
      setSelectedSalesId(findCorrespondingLead.assignTo._id);
    } else {
      // Réinitialiser si aucun lead correspondant n'est trouvé
      setSelectedSalesId("Select");
    }
  }, [selectedLeadId, leads]);

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.length === 0) {
      setSelectedLeadId(null);
      setSelectedSalesId("Select");
      setFilteredLeads([]);
      setShowDropdown(true);
    } else {
      const filtered = leads.filter(
        (l) =>
          l?.reference?.toString().toLowerCase().includes(query) ||
          l?.email?.toLowerCase().includes(query) ||
          l?.name?.toLowerCase().includes(query)
      );
      setFilteredLeads(filtered);
      setShowDropdown(filtered.length > 0);
    }
  };
  const handleLeadSelect = (leadId, leadName) => {
    setSelectedLeadId(leadId);
    setSearchQuery(leadName);
    setShowDropdown(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "assignTo") {
      setSelectedSalesId(value);
    }

    setNewDeposit({ ...newDeposit, [name]: value });
  };

  const handleAddNewDeposit = async () => {
    if (!selectedLeadId) {
      alert("Please select a lead.");
      return;
    }

    // Combinez la date sélectionnée avec l'heure actuelle
    let depositDateTime = new Date(newDeposit.depositDate);
    const now = new Date();
    depositDateTime.setHours(
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    );

    const updatedDeposit = {
      ...newDeposit,
      depositDate: depositDateTime.toISOString(),
      assignTo: selectedSalesId !== "Select" ? selectedSalesId : null,
    };

    const findCorrespondingLead = leads.find((l) => l._id === selectedLeadId);
    const updateLeadBalance =
      findCorrespondingLead.balance + Number(updatedDeposit.amount);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/${selectedLeadId}`,
        {
          deposit: [...deposit, updatedDeposit],
          isFTDS: true,
          balance: updateLeadBalance,
        }
      );

      if (response.data) {
        // Create a notification for the deposit
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/notification`,
          {
            typeNotification: "deposit",
            lead: selectedLeadId,
            text: "A deposit has been made in your account.",
            amount: Number(updatedDeposit.amount),
          }
        );

        emitDeposit();
        emitRefreshDataPlatform();
      }
      setOpen(false);
      reFetchLead();
      reFetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createDeposit" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createDepositWrapper">
            <div className="topCreateStatus">
              <span>Add Deposit</span>
            </div>
            <div className="middleCreateDeposit">
              <div className="searchContainer">
                <label>Select Customer</label>
                <input
                  type="text"
                  className="searchInput"
                  placeholder="Search..."
                  autoFocus
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                {showDropdown && filteredLeads.length > 0 && (
                  <ul className="searchResults">
                    {filteredLeads
                      .filter((lead) => lead.isAttribued)
                      .map((lead) => (
                        <li
                          key={lead._id}
                          onClick={() => handleLeadSelect(lead._id, lead.name)}
                        >
                          <AccountCircleIcon className="searchResultIcon" />
                          <div className="searchResultDetails">
                            <span className="searchResultName">
                              {lead.name}
                            </span>
                            <span
                              className="searchResultId"
                              style={{ color: "#757575" }}
                            >
                              ID: {lead.reference}
                            </span>
                          </div>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
              <label>Select Sales</label>
              <select
                className="depositSelect"
                onChange={handleChange}
                name="assignTo"
                value={selectedSalesId}
              >
                <option value="Select">Select</option>
                {users.map((u) => (
                  <option key={u._id} value={u._id}>
                    {u.name}
                  </option>
                ))}
              </select>
              <label>Type Payment</label>
              <select
                className="depositSelect"
                onChange={handleChange}
                name="typePayment"
              >
                <option value="Select">Select</option>
                {payments.map((payment) => (
                  <option key={payment._id} value={payment._id}>
                    {payment.title}
                  </option>
                ))}
              </select>
              <label>Amount</label>
              <input type="number" name="amount" onChange={handleChange} />
              <label>Date</label>
              <input
                type="date"
                name="depositDate"
                value={newDeposit.depositDate}
                onChange={handleChange}
              />
            </div>
            <div className="bottomCreateDeposit">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button
                className="saveButton"
                type="submit"
                onClick={handleAddNewDeposit}
              >
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDeposit;
