import { useEffect, useMemo, useRef, useState } from "react";
import "./Activite.css";
import DottedMap from "dotted-map/without-countries";
import MyMapString from "../../../myMap.json";
import axios from "axios";
import ReactCountryFlag from "react-country-flag";
import { CountryListCode, getCountryPosition } from "../../CountryListCode";
import { countries } from "countries-list";
import * as d3 from "d3";
import worldGeoJSON from "./ne_50m_admin_0_countries.json"; // Assurez-vous d'avoir ce fichier dans votre projet

const Activite = ({ activeTab, allStats }) => {
  const [statsByCountryCA, setStatsByCountryCA] = useState({});
  const [statsByCountryAgence, setStatsByCountryAgence] = useState({});
  const [statsByCountryVente, setStatsByCountryVente] = useState({});
  const [statsTest, setStatsTest] = useState({});

  const getCountryCode = (countryNameOrCode) => {
    const country =
      CountryListCode.find((c) => c.name === countryNameOrCode) ||
      CountryListCode.find((c) => c.code === countryNameOrCode);
    return country ? country.code : null;
  };

  useEffect(() => {
    const fetchGeographicalStats = async (type) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/agence/stats/geographical`,
          {
            params: {
              startDate: "2024-01-01",
              endDate: "2024-12-31",
              type: type,
            },
          }
        );
        if (type === "projects") {
          setStatsByCountryCA(response.data.statsByCountry);
        } else if (type === "agencies") {
          setStatsByCountryAgence(response.data.statsByCountry);
        } else if (type === "sales") {
          setStatsByCountryVente(response.data.statsByCountry);
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (activeTab === "ca") {
      fetchGeographicalStats("projects");
    } else if (activeTab === "agence") {
      fetchGeographicalStats("agencies");
    } else if (activeTab === "vente") {
      fetchGeographicalStats("sales");
    }
  }, [activeTab]);

  const createMap = (stats, color) => {
    // Création d'une nouvelle instance de DottedMap pour chaque onglet
    const map = new DottedMap({ map: MyMapString, width: 400, height: 200 });
    if (statsTest !== stats) {
      // Ajout des pins pour les pays
      if (statsTest) {
        Object.keys(statsTest).forEach((country) => {
          // Convertir le code du pays en nom de pays si nécessaire
          const countryName = getCountryNameByISO(country) || country;
          const position = getCountryPosition(countryName);
          if (position) {
            map.addPin({
              lat: position.lat,
              lng: position.lng,
              svgOptions: { color: "rgba(56, 109, 245, 0)", radius: 1 },
            });
          }
        });
      }
      Object.keys(stats).forEach((country) => {
        // Convertir le code du pays en nom de pays si nécessaire
        const countryName = getCountryNameByISO(country) || country;
        const position = getCountryPosition(countryName);

        if (position) {
          map.addPin({
            lat: position.lat,
            lng: position.lng,
            svgOptions: { color: color, radius: 1 },
          });
        }
        setStatsTest(stats);
      });
    }

    // Génération du SVG de la carte
    const svgMap = map.getSVG({
      radius: 0.22,
      color: "#c5c5c5",
      shape: "circle",
      backgroundColor: "white",
    });
    return (
      <img
        src={`data:image/svg+xml;utf8,${encodeURIComponent(svgMap)}`}
        alt="Dotted Map"
      />
    );
  };

  function getCountryNameByISO(code) {
    return countries[code]?.name || code;
  }

  return (
    <div className="dashboardContainerMiddleContent">
      <div className="dashboardContainerMiddleLeft">
        {activeTab === "ca" &&
          createMap(statsByCountryCA, "rgba(56, 109, 245, 0.6)")}
        {activeTab === "agence" &&
          createMap(statsByCountryAgence, "rgba(255, 102, 204, 0.6)")}
        {activeTab === "vente" &&
          createMap(statsByCountryVente, "rgba(102, 204, 255, 0.6)")}
      </div>
      {activeTab === "ca" && (
        <div className="dashboardContainerMiddleRight">
          {Object.keys(statsByCountryCA).map((country) => {
            const countryStats = statsByCountryCA[country];
            const countryCode = getCountryCode(country);

            if (!countryCode) {
              console.warn(`No country code found for ${country}`);
              return null;
            }

            const totalPayments = countryStats.totalPayments || 0;
            const totalValue = countryStats.totalValue || 0;

            return (
              <div
                className="dashboardContainerMiddleRightContent"
                key={country}
              >
                <div className="dashboardContainerMiddleRightContentLeft">
                  <div
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      backgroundImage: `url(https://flagcdn.com/w320/${countryCode.toLowerCase()}.png)`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div className="dashboardContainerMiddleRightContentRight">
                  <div className="dashboardContainerMiddleRightContentRightTop">
                    <span>{getCountryNameByISO(country)}</span>
                    <span>
                      {totalPayments.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                      &nbsp;/&nbsp;
                      {totalValue.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </span>
                  </div>
                  <div className="dashboardContainerMiddleRightContentRightBottom">
                    <div className="progressContainerDashboard">
                      <div
                        className="progressDashboard"
                        style={{
                          width: `${
                            totalValue > 0
                              ? (totalPayments / totalValue) * 100
                              : 0
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {activeTab === "agence" && (
        <div className="dashboardContainerMiddleRight">
          {Object.keys(statsByCountryAgence).map((country) => {
            const countryStats = statsByCountryAgence[country];
            const countryCode = getCountryCode(country);

            if (!countryCode) {
              console.warn(`No country code found for ${country}`);
              return null;
            }

            const signedAgenciesCount = countryStats.signedAgenciesCount || 0;
            console.log(signedAgenciesCount)

            return (
              <div
                className="dashboardContainerMiddleRightContent"
                key={country}
              >
                <div className="dashboardContainerMiddleRightContentLeft">
                  <div
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      backgroundImage: `url(https://flagcdn.com/w320/${countryCode.toLowerCase()}.png)`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div className="dashboardContainerMiddleRightContentRight">
                  <div className="dashboardContainerMiddleRightContentRightTop">
                    <span>{getCountryNameByISO(country)}</span>
                    <span style={{ color: "black" }}>
                      {signedAgenciesCount}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {activeTab === "vente" && (
        <div className="dashboardContainerMiddleRight">
          {Object.keys(statsByCountryVente).map((country) => {
            const countryStats = statsByCountryVente[country];
            const countryCode = getCountryCode(country);

            if (!countryCode) {
              console.warn(`No country code found for ${country}`);
              return null;
            }

            const clientCount = countryStats.clientCount || 0;

            return (
              <div
                className="dashboardContainerMiddleRightContent"
                key={country}
              >
                <div className="dashboardContainerMiddleRightContentLeft">
                  <div
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      backgroundImage: `url(https://flagcdn.com/w320/${countryCode.toLowerCase()}.png)`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div className="dashboardContainerMiddleRightContentRight">
                  <div className="dashboardContainerMiddleRightContentRightTop">
                    <span>{getCountryNameByISO(country)}</span>
                    <span style={{ color: "black" }}>{clientCount}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Activite;
