import React, { useState } from "react";
import "./SalesPerfomance.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SalesPerfomance = ({ allStats }) => {
  const [sortConfig, setSortConfig] = useState({
    key: "caSales", // Par défaut, tri par CA
    direction: "desc", // Par défaut, tri décroissant
  });

  if (!allStats || !allStats.performanceSales) {
    return <div>Loading...</div>;
  }

  const sortData = (key) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    } else if (sortConfig.key !== key) {
      direction = "desc"; // Par défaut pour la nouvelle colonne
    }
    setSortConfig({ key, direction });
  };

  const sortedSales = [...allStats.performanceSales].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  return (
    <div className="tableContainerDashboard">
      <div className="tableWrapper">
        <table className="customTable">
          <thead className="customThead">
            <tr>
              <th style={{ width: "50%" }}>
                <div className="customHeaderWithIcon">Teleprospecteur</div>
              </th>
              <th
                onClick={() => sortData("numberOfAgences")}
                style={{ width: "15%" }}
              >
                <div className="customHeaderWithIcon">
                  Agences
                  {sortConfig.key === "numberOfAgences" &&
                    (sortConfig.direction === "asc" ? (
                      <KeyboardArrowUpIcon style={{ fontSize: "12px" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fontSize: "12px" }} />
                    ))}
                </div>
              </th>
              <th
                onClick={() => sortData("numberOfSales")}
                style={{ width: "15%" }}
              >
                <div className="customHeaderWithIcon">
                  Ventes
                  {sortConfig.key === "numberOfSales" &&
                    (sortConfig.direction === "asc" ? (
                      <KeyboardArrowUpIcon style={{ fontSize: "12px" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fontSize: "12px" }} />
                    ))}
                </div>
              </th>
              <th onClick={() => sortData("caSales")} style={{ width: "20%" }}>
                <div className="customHeaderWithIcon">
                  CA
                  {sortConfig.key === "caSales" &&
                    (sortConfig.direction === "asc" ? (
                      <KeyboardArrowUpIcon style={{ fontSize: "12px" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fontSize: "12px" }} />
                    ))}
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="tableBodyWrapper">
        <table className="customTable">
          <tbody className="customTableBody">
            {sortedSales.map((salesPerson) => (
              <tr key={salesPerson.userName}>
                <td
                  className="ppAgencesDashboardTableTdWithImg"
                  style={{ width: "50%" }}
                >
                  <img
                    src={salesPerson.pPicture || "/images/avatar/default.png"}
                    alt={salesPerson.userName}
                  />
                  <span>{salesPerson.userName}</span>
                </td>
                <td style={{ width: "15%" }}>{salesPerson.numberOfAgences}</td>
                <td style={{ width: "15%" }}>{salesPerson.numberOfSales}</td>
                <td style={{ width: "20%" }}>
                  {salesPerson.caSales.toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesPerfomance;
