import "./AppartmentTable.css";
import { handleCheckboxChange } from "../../../../utils/datatable.utils";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import AddIcon from "@mui/icons-material/Add";
import CreateApartment from "./CreateApartment/CreateApartment";
import DeleteMessage from "./DeleteMessage/DeleteMessage";
import { useData } from "../../../../context/DataContext";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { countries } from "countries-list";
import EditAppartment from "./EditAppartment/EditAppartment";
import PopupClientContainer from "../../../Ventes/Client/PopupClient/PopupClientContainer/PopupClientContainer";

const AppartmentTable = ({ data, reFetch }) => {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [openPopupClient, setOpenPopupClient] = useState(false);
  const [openPopUpCreateAppartment, setOpenPopUpCreateAppartment] =
    useState(false);
  const [openPopUpEditAppartment, setOpenPopUpEditAppartment] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    projects: "All Projects",
    status: "All Status",
  });
  const {
    appartments,
    reFetchAppartments,
    clients,
    projects,
    ventes,
    reFetchClients,
  } = useData();

  const tableRef = useRef(null);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };
  const selectCurrentPage = () => {
    const start = currentPage * perPage;
    const end = start + perPage;
    const currentPageItems = data.slice(start, end).map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = () => {
    const allItems = data.map((item) => item._id);
    setCheckedItems(allItems);
  };

  const handleOpenDeleteMessage = (appartmentId) => {
    const findCorrespondingAppartment = appartments.find(
      (a) => a._id === appartmentId
    );
    if (findCorrespondingAppartment.isSold) {
      alert(
        "Cette appartement est en vente, veuillez supprimer la vente associée"
      );
    } else {
      setOpenDeleteMessage(true);
      setSelectedDeletedId(appartmentId);
    }
  };

  const handleOpenPopuEditAppartment = (data) => {
    setSelectedData(data);
    setOpenPopUpEditAppartment(true);
  };

  const handleOpenPopupClient = (item) => {
    setSelectedData(item);
    setOpenPopupClient(true);
  };

  const handleProjectFilterChange = (e) => {
    setSelectedFilters((prev) => ({
      ...prev,
      projects: e.target.value,
    }));
  };

  // Handling Status Filter Change
  const handleStatusFilterChange = (e) => {
    setSelectedFilters((prev) => ({
      ...prev,
      status: e.target.value,
    }));
  };

  const filteredData = data.filter((apartment) => {
    const matchProject =
      selectedFilters.projects === "All Projects" ||
      apartment.projects._id === selectedFilters.projects;
    const matchStatus =
      selectedFilters.status === "All Status" ||
      (selectedFilters.status === "En vente" && !apartment.isSold) ||
      (selectedFilters.status === "Paye" &&
        apartment.isSold &&
        apartment.payment.reduce((sum, pay) => sum + pay.amount, 0) <
          apartment.price) ||
      (selectedFilters.status === "Livre" &&
        apartment.payment.reduce((sum, pay) => sum + pay.amount, 0) ===
          apartment.price);

    return matchProject && matchStatus;
  });

  const findClientByApartmentId = (apartmentId) => {
    // Trouver la vente associée à l'appartement
    const venteAssociee = ventes.find(
      (v) => v.appartment._id.toString() === apartmentId.toString()
    );

    // Si une vente est trouvée, retourner l'objet complet du client associé
    if (venteAssociee && venteAssociee.client) {
      const client = clients.find(
        (c) => c._id.toString() === venteAssociee.client._id.toString()
      );
      return client || { name: "Client inconnu" };
    }

    // Si l'appartement n'a pas été vendu ou n'a pas de client associé
    return { name: " " };
  };

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Appartements : {data.length} </span>
              <div className="selectWithArrow">
                <UnfoldMoreIcon
                  className="selectWithArrowIcon"
                  style={{
                    fontSize: "13px",
                    color:
                      selectedFilters.projects !== "All Projects"
                        ? "var(--primary-color)"
                        : "gray",
                  }}
                />
                <select
                  value={selectedFilters.projects}
                  onChange={handleProjectFilterChange}
                  className={
                    selectedFilters.projects !== "All Projects"
                      ? "greenBackgroundAfterSelect"
                      : ""
                  }
                >
                  <option value="All Projects">All Projects</option>
                  {projects.map((project) => (
                    <option key={project._id} value={project._id}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="selectWithArrow">
                <UnfoldMoreIcon
                  className="selectWithArrowIcon"
                  style={{
                    fontSize: "13px",
                    color:
                      selectedFilters.status !== "All Status"
                        ? "var(--primary-color)"
                        : "gray",
                  }}
                />
                <select
                  value={selectedFilters.status}
                  onChange={handleStatusFilterChange}
                  className={
                    selectedFilters.status !== "All Status"
                      ? "greenBackgroundAfterSelect"
                      : ""
                  }
                >
                  <option value="All Status">All Status</option>
                  <option value="En vente">En vente</option>
                  <option value="Paye">Paye</option>
                  <option value="Livre">Livre</option>
                </select>
              </div>
            </div>
            <div className="rightElements">
              <button onClick={() => setOpenPopUpCreateAppartment(true)}>
                <AddIcon />
                <span>Crée Appartement</span>
              </button>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />

                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">Reference</div>
                </th>
                <th>
                  <div className="headerWithIcon">Projet</div>
                </th>
                <th>
                  <div className="headerWithIcon">Mettre carre</div>
                </th>
                <th>
                  <div className="headerWithIcon">Prix</div>
                </th>
                <th>
                  <div className="headerWithIcon">Client</div>
                </th>
                <th>
                  <div className="headerWithIcon">Status</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData
                .slice(currentPage * perPage, (currentPage + 1) * perPage)
                .map((item, index) => (
                  <tr key={index}>
                    <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          checked={checkedItems.includes(item.leadId)}
                          onChange={(e) =>
                            handleCheckboxChangeWrapper(e, item.leadId)
                          }
                        />
                        <div className="checkmark"></div>
                      </label>
                    </td>

                    <td>
                      <div className="nameAndStatus">
                        <span style={{ color: "#00B161" }}>
                          {item.reference}
                        </span>
                      </div>
                    </td>

                    <td>{item.projects.name}</td>

                    <td>{<span>{item.surface} </span>}</td>
                    <td>{<span>{item.price.toLocaleString()}€</span>}</td>
                    <td
                      onClick={() =>
                        handleOpenPopupClient(
                          findClientByApartmentId(item._id),
                          index
                        )
                      }
                    >
                      {<span>{findClientByApartmentId(item._id).name}</span>}
                    </td>
                    <td className="apptTdTest">
                      {!item.isSold ? (
                        "En vente"
                      ) : item.payment.reduce(
                          (sum, pay) => sum + pay.amount,
                          0
                        ) === item.price ? (
                        "Livré"
                      ) : (
                        <>
                          <div className="apptProgressContainer">
                            {item.payment.map((payment, index, array) => {
                              const percentage =
                                (payment.amount / item.price) * 100;

                              let backgroundColor;
                              if (array.length === 1) {
                                backgroundColor = "#00B161";
                              } else {
                                const startColor = "#00B161";
                                const endColor = "#00C6FF";
                                const colorStep = index / (array.length - 1);
                                backgroundColor = `rgba(${Math.round(
                                  (1 - colorStep) *
                                    parseInt(startColor.slice(1, 3), 16) +
                                    colorStep *
                                      parseInt(endColor.slice(1, 3), 16)
                                )}, ${Math.round(
                                  (1 - colorStep) *
                                    parseInt(startColor.slice(3, 5), 16) +
                                    colorStep *
                                      parseInt(endColor.slice(3, 5), 16)
                                )}, ${Math.round(
                                  (1 - colorStep) *
                                    parseInt(startColor.slice(5, 7), 16) +
                                    colorStep *
                                      parseInt(endColor.slice(5, 7), 16)
                                )}, 0.8)`;
                              }

                              return (
                                <div
                                  key={index}
                                  className="progressSegment" // Utilisez la même classe que dans VenteTable
                                  style={{
                                    width: `${percentage}%`,
                                    backgroundColor,
                                  }}
                                >
                                  <div className="paymentTooltip">
                                    <span>
                                      {payment.amount.toLocaleString("en-US")}€
                                      &nbsp;-&nbsp;&nbsp;
                                      {new Date(
                                        payment.paymentDate
                                      ).toLocaleDateString()}
                                      &nbsp;&nbsp; - &nbsp;&nbsp;
                                      {payment.client.name}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="apptProgressPrice">
                              {item.payment
                                .reduce(
                                  (acc, payment) => acc + payment.amount,
                                  0
                                )
                                .toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              € /{" "}
                              {item.price.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              €
                            </div>
                          </div>
                        </>
                      )}
                    </td>
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleOpenPopuEditAppartment(item)}
                      >
                        edit
                      </span>
                      <span
                        className="hover-effect"
                        onClick={() => handleOpenDeleteMessage(item._id)}
                      >
                        del
                      </span>
                    </td>
                  </tr>
                ))}
              {data.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {data.length > 0 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(data.length / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}

          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={data}
              pageData={data}
              setIsAllChecked={setIsAllChecked}
            />
          )}
          {openPopUpCreateAppartment && (
            <CreateApartment
              setOpen={setOpenPopUpCreateAppartment}
              reFetchFromTable={reFetch}
            />
          )}
          {openPopUpEditAppartment && (
            <EditAppartment
              setOpen={setOpenPopUpEditAppartment}
              reFetchFromTable={reFetch}
              selectedData={selectedData}
            />
          )}
          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              id={selectedDeletedId}
            />
          )}
          {openPopupClient && (
            <PopupClientContainer
              setOpen={setOpenPopupClient}
              data={selectedData}
              reFetchFromTable={reFetch}
              clients={clients}
              reFetchClients={reFetchClients}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppartmentTable;
