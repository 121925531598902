import "./VenteTable.css";
import { handleCheckboxChange } from "../../../../utils/datatable.utils";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import AddIcon from "@mui/icons-material/Add";
import CreateVentePopUp from "../CreateVentePopUp/CreateVentePopUp";
import DeleteMessage from "../DeleteMessage/DeleteMessage";
import { useData } from "../../../../context/DataContext";
import CreateSingleClient from "../../Client/CreateSingleClient/CreateSingleClient";
import AddPaymentPopup from "../AddPaymentPopup/AddPaymentPopup";
import moment from "moment";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import PopupCustomerContainer from "../../../CRM/PopupCustomer/PopupClientContainer/PopupCustomerContainer";
import PopupProjetContainer from "../../../Projet/Projet/PopupProjet/PopupProjetContainer/PopupProjetContainer";
import PopupClientContainer from "../../Client/PopupClient/PopupClientContainer/PopupClientContainer";
import EditAppartment from "../../../Projet/Appartment/AppartmentTable/EditAppartment/EditAppartment";

const VenteTable = ({
  data,
  reFetch,
  clients,
  projects,
  allLeads,
  selectedFilters,
  setSelectedFilters,
  setPage,
}) => {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [openCreateVente, setOpenCreateVente] = useState(false);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [openPopupAgence, setOpenPopupAgence] = useState(false);
  const [openPopupPayment, setOpenPopupPayment] = useState(false);
  const [openPopupClient, setOpenPopupClient] = useState(false);
  const { reFetchAllLead, paymentsType, reFetchProjects, reFetchClients } =
    useData();
  const [openPopUpEditAppartment, setOpenPopUpEditAppartment] = useState(false);

  const [venteState, setVenteState] = useState({
    agence: "",
    client: "",
    project: "",
    appartment: "",
  });
  const [apartmentsState, setApartmentsState] = useState([]);
  const [clientsState, setClientsState] = useState([]);

  const tableRef = useRef(null);

  const handleAgencyChange = (agencyId) => {
    const selectedAgence = allLeads.find((p) => p._id === agencyId);
    if (selectedAgence) {
      setClientsState(selectedAgence.client || []); // Met à jour les clients en fonction de l'agence sélectionnée
      setVenteState((prev) => ({
        ...prev,
        agence: agencyId,
        client: "", // Réinitialiser le client sélectionné
      }));
    }
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected + 1);
  };

  useEffect(() => {
    reFetch();
  }, [currentPage]);

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };
  const selectCurrentPage = () => {
    const currentPageItems = data.map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = () => {
    const allItems = data.map((item) => item._id);
    setCheckedItems(allItems);
  };

  const handleOpenDeleteMessage = (vente) => {
    if (vente.appartment.payment.length > 0) {
      alert(
        "Vous devez d'abord effacer tous les paiements avant de supprimer cette vente."
      );
    } else {
      setOpenDeleteMessage(true);
      setSelectedDeletedId(vente);
    }
  };

  const handleOpenPopupAgence = (data) => {
    setSelectedData(data);
    setOpenPopupAgence(true);
  };

  const handleOpenPopupPaiement = (item) => {
    setSelectedData(item);
    setOpenPopupPayment(true);
  };

  const handleOpenPopuEditAppartment = (data) => {
    setSelectedData(data);
    setOpenPopUpEditAppartment(true);
  };

  const handleOpenPopupClient = (item) => {
    setSelectedData(item);
    setOpenPopupClient(true);
  };

  const updatePayments = (updatedPayments) => {
    setSelectedData((prevData) => {
      const updatedAppartment = {
        ...prevData.appartment,
        payment: updatedPayments,
      };

      const updatedItem = { ...prevData, appartment: updatedAppartment };

      // Update the main data table as well
      const updatedData = data.map((item) =>
        item._id === selectedData._id ? updatedItem : item
      );
      reFetch(updatedData);

      return updatedItem;
    });
  };


  const handleClientFormClose = (newClient) => {
    if (newClient) {
      // Mise à jour du client dans la vente
      setClientsState((prevClients) => [...prevClients, newClient]);
      setVenteState((prevVente) => ({
        ...prevVente,
        client: newClient._id,
      }));
    }
    setOpenCreateClient(false);
    setOpenCreateVente(true);
  };

  const handleAgenceFilterChange = (e) => {
    setSelectedFilters((filters) => ({
      ...filters,
      agence: e.target.value,
    }));
    setPage(1);
  };

  // Handle the change for project filter
  const handleProjectsFilterChange = (e) => {
    setSelectedFilters((filters) => ({
      ...filters,
      project: e.target.value,
    }));
    setPage(1);
  };

  const totalCA = data.reduce((acc, vente) => acc + vente.appartment.price, 0);

  const totalPaid = data.reduce(
    (acc, vente) =>
      acc +
      vente.appartment.payment.reduce(
        (sum, payment) => sum + payment.amount,
        0
      ),
    0
  );

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>
                Ventes : {data.length}&nbsp;&nbsp;-&nbsp;&nbsp;CA :{" "}
                {totalCA.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                €&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Payé :{" "}
                {totalPaid.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                €
              </span>
              <div className="selectWithArrow">
                <UnfoldMoreIcon
                  className="selectWithArrowIcon"
                  style={{
                    fontSize: "13px",
                    color:
                      selectedFilters.agence !== "All Agencies"
                        ? "var(--primary-color)"
                        : "gray",
                  }}
                />
                <select
                  value={selectedFilters.agence}
                  onChange={handleAgenceFilterChange}
                  className={
                    selectedFilters.agence !== "All Agencies"
                      ? "greenBackgroundAfterSelect"
                      : ""
                  }
                >
                  <option value="All Agencies">All Agencies</option>
                  {allLeads
                    .filter((a) => a.signed.isSigned)
                    .map((lead) => (
                      <option key={lead._id} value={lead._id}>
                        {lead.agencyName}
                      </option>
                    ))}
                </select>
              </div>

              {/* Project Filter */}
              <div className="selectWithArrow">
                <UnfoldMoreIcon
                  className="selectWithArrowIcon"
                  style={{
                    fontSize: "13px",
                    color:
                      selectedFilters.project !== "All Projects"
                        ? "var(--primary-color)"
                        : "gray",
                  }}
                />
                <select
                  value={selectedFilters.project}
                  onChange={handleProjectsFilterChange}
                  className={
                    selectedFilters.project !== "All Projects"
                      ? "greenBackgroundAfterSelect"
                      : ""
                  }
                >
                  <option value="All Projects">All Projects</option>
                  {projects.map((proj) => (
                    <option key={proj._id} value={proj._id}>
                      {proj.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="rightElements">
              <button onClick={() => setOpenCreateVente(true)}>
                <AddIcon />
                <span>Crée Vente</span>
              </button>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label className="container">
                      <input type="checkbox" checked={isAllChecked} readOnly />

                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>

                <th>
                  <div className="headerWithIcon">Projet</div>
                </th>
                <th>
                  <div className="headerWithIcon">Appartement</div>
                </th>
                <th>
                  <div className="headerWithIcon">Agence</div>
                </th>
                <th>
                  <div className="headerWithIcon">Client</div>
                </th>
                <th>
                  <div className="headerWithIcon">Montant</div>
                </th>
                <th>
                  <div className="headerWithIcon">Date</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                .slice(currentPage * perPage, (currentPage + 1) * perPage)
                .map((item, index) => (
                  <tr key={index}>
                    <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          checked={checkedItems.includes(item._id)}
                          onChange={(e) =>
                            handleCheckboxChangeWrapper(e, item._id)
                          }
                        />
                        <div className="checkmark"></div>
                      </label>
                    </td>

                    <td>
                      <span>{item.project.name}</span>
                    </td>

                    <td
                      onClick={() =>
                        handleOpenPopuEditAppartment(item.appartment)
                      }
                    >
                      <span>{item.appartment.reference} </span>
                    </td>
                    <td
                      onClick={() => handleOpenPopupAgence(item.agence, index)}
                    >
                      <span>{item.agence.agencyName} </span>
                    </td>
                    <td
                      onClick={() => handleOpenPopupClient(item.client, index)}
                    >
                      <span>{item.client.name} </span>
                    </td>
                    <td
                      className="progressContainerVenteTd"
                      onClick={() => handleOpenPopupPaiement(item, index)}
                    >
                      <div className="progressContainerVente">
                        {item.appartment.payment.map(
                          (payment, index, array) => {
                            const percentage =
                              (payment.amount / item.appartment.price) * 100;

                            let backgroundColor;
                            if (array.length === 1) {
                              // Si un seul paiement, appliquer la couleur de départ
                              backgroundColor = "#00B161";
                            } else {
                              const startColor = "#00B161";
                              const endColor = "#00C6FF";
                              const colorStep = index / (array.length - 1);
                              backgroundColor = `rgba(${Math.round(
                                (1 - colorStep) *
                                  parseInt(startColor.slice(1, 3), 16) +
                                  colorStep * parseInt(endColor.slice(1, 3), 16)
                              )}, ${Math.round(
                                (1 - colorStep) *
                                  parseInt(startColor.slice(3, 5), 16) +
                                  colorStep * parseInt(endColor.slice(3, 5), 16)
                              )}, ${Math.round(
                                (1 - colorStep) *
                                  parseInt(startColor.slice(5, 7), 16) +
                                  colorStep * parseInt(endColor.slice(5, 7), 16)
                              )}, 0.8)`;
                            }

                            return (
                              <div
                                key={index}
                                className="progressSegment"
                                style={{
                                  width: `${percentage}%`,
                                  backgroundColor,
                                }}
                              >
                                <div className="paymentTooltip">
                                  <span>
                                    {payment.amount.toLocaleString("en-US")} € -{" "}
                                    {new Date(
                                      payment.paymentDate
                                    ).toLocaleDateString()}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )}
                        <div className="progressPriceVente">
                          {item.appartment.payment
                            .reduce((acc, payment) => acc + payment.amount, 0)
                            .toLocaleString("en-US")}{" "}
                          € / {item.appartment.price.toLocaleString("en-US")} €
                        </div>
                      </div>
                    </td>
                    <td>
                      <span>{moment(item.createdAt).format("DD/MM/YYYY")}</span>
                    </td>
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleOpenDeleteMessage(item)}
                      >
                        del
                      </span>
                    </td>
                  </tr>
                ))}
              {data.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {data.length > 0 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(data.length / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}

          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={data}
              pageData={data}
              setIsAllChecked={setIsAllChecked}
            />
          )}
          {openCreateVente && (
            <CreateVentePopUp
              setOpen={setOpenCreateVente}
              reFetch={reFetch}
              projects={projects}
              allLeads={allLeads}
              onCreateClient={() => {}}
              newVente={venteState}
              setNewVente={setVenteState}
              apartments={apartmentsState}
              setApartments={setApartmentsState}
              clients={clientsState}
              setClients={setClientsState}
              onAgencyChange={handleAgencyChange} // Passe la fonction au composant enfant
            />
          )}

          {openCreateClient && (
            <CreateSingleClient
              setOpen={setOpenCreateClient}
              reFetchFromTable={reFetch}
              reFetchAllLead={reFetchAllLead}
              onClose={handleClientFormClose}
              selectedAgence={venteState.agence}
            />
          )}

          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              id={selectedDeletedId}
            />
          )}

          {openPopupAgence && (
            <PopupCustomerContainer
              setOpen={setOpenPopupAgence}
              data={selectedData}
              leads={allLeads}
              reFetchAllLead={reFetchAllLead}
              reFetchFromTable={reFetch}
            />
          )}

          {openPopupClient && (
            <PopupClientContainer
              setOpen={setOpenPopupClient}
              data={selectedData}
              reFetchFromTable={reFetch}
              clients={clients}
              reFetchClients={reFetchClients}
            />
          )}
          {openPopupPayment && (
            <AddPaymentPopup
              setOpen={setOpenPopupPayment}
              paymentsType={paymentsType}
              currentData={selectedData}
              updatePayments={updatePayments}
            />
          )}
          {openPopUpEditAppartment && (
            <EditAppartment
              setOpen={setOpenPopUpEditAppartment}
              reFetchFromTable={reFetch}
              selectedData={selectedData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VenteTable;
