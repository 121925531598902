import "./Dashboard.css";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ModeIcon from "@mui/icons-material/Mode";
import Comment from "../Comment/Comment";
import "react-circular-progressbar/dist/styles.css";
import BalanceIcon from "@mui/icons-material/Balance";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useContext, useEffect, useState } from "react";
import { useSocket } from "../../../../../context/SocketContext";
import { useData } from "../../../../../context/DataContext";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { AuthContext } from "../../../../../context/auth.context";
import Vente from "../Vente/Vente";
import Clients from "../Clients/Clients";
import Commission from "../Commission/Commission";
const Dashboard = ({
  currentData,
  reFetchFromTable,
  leads,
  reFetchAllLead,
}) => {
  const [activeTab, setActiveTab] = useState("Ventes");
  const { user } = useContext(AuthContext);
  const { ventes, clients, refetchVentes, reFetchClients, paymentAgence } =
    useData();

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const venteAssignedToAgence = ventes.filter(
    (v) => v.agence?._id === currentData?._id
  );

  const getCAofAgence = venteAssignedToAgence.reduce((sum, vente) => {
    if (vente?.appartment?.price) {
      return sum + vente.appartment.price;
    }
    return sum;
  }, 0);

  const findCorrespondingPaymentAgence =
    paymentAgence?.filter((p) => p?.agence?._id === currentData?._id) || [];

  const totalCACommission = currentData?.percentage
    ? (getCAofAgence * currentData.percentage) / 100
    : 0;

  const totalPayments = findCorrespondingPaymentAgence.reduce(
    (sum, payment) => sum + Number(payment?.amount || 0),
    0
  );

  return (
    <>
      <div className="popupRigthSideBottomLeft">
        <div className="popupRigthSideBottomLeftTop">
          <div className="statsWrapper">
            <div className="statsWrapperPerTwo">
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTop">
                  <PermIdentityIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>Clients</span>
                  <span>{currentData?.client?.length || 0} </span>
                </div>
              </div>
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTop">
                  <LocalOfferIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>Ventes</span>
                  <span>{venteAssignedToAgence.length}</span>
                </div>
              </div>
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTop">
                  <CreditCardIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>CA</span>
                  <span>{getCAofAgence.toLocaleString("en-US")} €</span>
                </div>
              </div>
            </div>
            <div className="statsWrapperPerTwo">
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTopAbsolute">
                  <CurrencyExchangeIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>Commission</span>
                  <div className="progressComparisonCommission">
                    <span>
                      {totalPayments.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      € /{" "}
                      {totalCACommission.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      €
                    </span>
                  </div>
                  <div className="progressContainerCommissionTd">
                    <div className="progressContainerCommission">
                      {findCorrespondingPaymentAgence.map(
                        (payment, index, array) => {
                          const percentage =
                            totalCACommission > 0
                              ? (payment.amount / totalCACommission) * 100
                              : 0;

                          let backgroundColor;
                          if (array.length === 1) {
                            backgroundColor = "#00B161";
                          } else {
                            const startColor = "#00B161";
                            const endColor = "#00C6FF";
                            const colorStep = index / (array.length - 1);
                            backgroundColor = `rgba(${Math.round(
                              (1 - colorStep) *
                                parseInt(startColor.slice(1, 3), 16) +
                                colorStep * parseInt(endColor.slice(1, 3), 16)
                            )}, ${Math.round(
                              (1 - colorStep) *
                                parseInt(startColor.slice(3, 5), 16) +
                                colorStep * parseInt(endColor.slice(3, 5), 16)
                            )}, ${Math.round(
                              (1 - colorStep) *
                                parseInt(startColor.slice(5, 7), 16) +
                                colorStep * parseInt(endColor.slice(5, 7), 16)
                            )}, 0.8)`;
                          }

                          return (
                            <div
                              key={index}
                              className="progressSegmentCommission"
                              style={{
                                width: `${percentage}%`,
                                backgroundColor,
                              }}
                            >
                              <div className="paymentTooltipCommission">
                                <span>
                                  {payment?.amount.toLocaleString("en-US")} € -{" "}
                                  {new Date(
                                    payment?.paymentDate
                                  ).toLocaleDateString()}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popupTopNav">
          <div className="popupRigthSideTop">
            {/* <div
            className={`popupRigthSideTopItem ${
              activeTab === "Dashboard" ? "active" : ""
            }`}
            onClick={() => handleTabClick("Dashboard")}
          >
            <span>Dashboard</span>
          </div> */}
            <div
              className={`popupRigthSideTopItem ${
                activeTab === "Ventes" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Ventes")}
            >
              <span>Ventes</span>
            </div>
            <div
              className={`popupRigthSideTopItem ${
                activeTab === "Clients" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Clients")}
            >
              <span>Clients</span>
            </div>
            <div
              className={`popupRigthSideTopItem ${
                activeTab === "Paiement" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Paiement")}
            >
              <span>Paiement</span>
            </div>
          </div>
        </div>
        <div className="popupRigthSideBottomLeftBottom">
          {activeTab === "Ventes" && (
            <Vente
              currentData={currentData}
              reFetchFromTable={reFetchFromTable}
              ventes={ventes}
              refetchVentes={refetchVentes}
              leads={leads}
              reFetchAllLead={reFetchAllLead}
            />
          )}
          {activeTab === "Clients" && (
            <Clients
              currentData={currentData}
              clients={clients}
              reFetchClients={reFetchClients}
              reFetchFromTable={reFetchFromTable}
              leads={leads}
              reFetchAllLead={reFetchAllLead}
              ventes={ventes}
            />
          )}
          {activeTab === "Paiement" && (
            <Commission
              currentData={currentData}
              reFetchFromTable={reFetchFromTable}
              leads={leads}
              reFetchAllLead={reFetchAllLead}
            />
          )}
          {/* <div className="popupRigthSideBottomLeftBottomLeft">
            <div className="iconWithTitle">
              <AccessTimeFilledIcon />
              <span>Liste des clients</span>
            </div>
            <div className="timelineWrapper">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Action</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div> */}
        </div>
      </div>
      <div className="popupRigthSideBottomRight">
        <div className="iconWithTitle">
          <ModeIcon />
          <span>COMMENTS</span>
        </div>
        <Comment
          currentData={currentData}
          reFetchFromTable={reFetchFromTable}
          leads={leads}
        />
      </div>
    </>
  );
};

export default Dashboard;
