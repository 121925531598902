import "./CreateProject.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useState } from "react";
import { countries } from "countries-list";
import axios from "axios";
import { useData } from "../../../../context/DataContext";
const CreateProject = ({ setOpen, reFetchFromTable }) => {
  const { reFetchProjects } = useData();
  const [newProject, setNewProject] = useState({
    name: "",
    city: "",
    address: "",
    country: "",
    description: "",
    zipcode: "",
  });

  const countriesArray = Object.values(countries);

  const sortedCountries = countriesArray
    .filter((country) => country.code !== "FR")
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleCreateProject = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/project/`,
        newProject
      );

      setNewProject({
        name: "",
        city: "",
        address: "",
        country: "",
        zipcode: "",
      });
      reFetchProjects();
      reFetchFromTable();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}></div>
      <div className="createProject">
        <div className="closeIconStatus">
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        <div className="topCreateProject">
          <span>Create Project</span>
        </div>
        <div className="CreateProjectWrapper">
          <div className="middleCreateProject">
            <div className="inputRow">
              <label>Name</label>
              <input type="text" name="name" onChange={handleChange} />
            </div>
            <div className="inputRowCountry">
              <label>Country</label>
              <select name="country" onChange={handleChange}>
                <option value="default">Select</option>
                <option value="France">France</option>
                {sortedCountries.map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              <label>City</label>
              <input type="text" name="city" onChange={handleChange} />
            </div>
            <div className="inputRow">
              <label>Address</label>
              <input type="text" name="address" onChange={handleChange} />
              <label>Zipcode</label>
              <input type="text" name="zipcode" onChange={handleChange} />
            </div>
          </div>
        </div>

        <div className="bottomCreateProject">
          <button className="cancelButton" onClick={() => setOpen(false)}>
            Cancel
          </button>
          <button className="saveButton" onClick={handleCreateProject}>
            <CheckIcon />
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateProject;
