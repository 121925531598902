// countryCodes.js
const countryCodes = {
    France: "+33",
    Belgium: "+32",
    China: "+86",
    USA: "+1",
    Canada: "+1",
    Mexico: "+52",
    Brazil: "+55",
    Argentina: "+54",
    Colombia: "+57",
    Chile: "+56",
    Peru: "+51",
    Venezuela: "+58",
    Uruguay: "+598",
    Paraguay: "+595",
    Bolivia: "+591",
    Germany: "+49",
    Austria: "+43",
    Switzerland: "+41",
    Spain: "+34",
    Portugal: "+351",
    Italy: "+39",
    United_Kingdom: "+44",
    Ireland: "+353",
    Netherlands: "+31",
    Luxembourg: "+352",
    Denmark: "+45",
    Norway: "+47",
    Sweden: "+46",
    Finland: "+358",
    Iceland: "+354",
    Greece: "+30",
    Turkey: "+90",
    Poland: "+48",
    Czech_Republic: "+420",
    Slovakia: "+421",
    Hungary: "+36",
    Romania: "+40",
    Bulgaria: "+359",
    Croatia: "+385",
    Serbia: "+381",
    Slovenia: "+386",
    Bosnia_and_Herzegovina: "+387",
    Montenegro: "+382",
    Albania: "+355",
    North_Macedonia: "+389",
    Estonia: "+372",
    Latvia: "+371",
    Lithuania: "+370",
    Ukraine: "+380",
    Russia: "+7",
    Belarus: "+375",
    Moldova: "+373",
    Georgia: "+995",
    Armenia: "+374",
    Azerbaijan: "+994",
    Kazakhstan: "+7",
    Uzbekistan: "+998",
    Kyrgyzstan: "+996",
    Turkmenistan: "+993",
    Israel: "+972",
    Cyprus: "+357",
    Malta: "+356",
    Andorra: "+376",
    Monaco: "+377",
    Liechtenstein: "+423",
    San_Marino: "+378",
    Vatican_City: "+39",
    Greenland: "+299",
    // Ajoutez ici d'autres pays si nécessaire
  };
  
  const getCountryCode = (country) => {
    return countryCodes[country] || ''; // Retourne un string vide si le pays n'est pas trouvé
  };
  
  export { countryCodes, getCountryCode };
  