// src/context/DataContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "./auth.context";

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [status, setStatus] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [users, setUsers] = useState([]);
  const [appartments, setAppartments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [paymentAgence, setPaymentAgence] = useState([]);
  const [ventes, setVentes] = useState([]);
  const [paymentsType, setPaymentsType] = useState([]);
  const [promote, setPromote] = useState([]);
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn) {
      fetchStatus();
      fetchLeads();
      fetchUsers();
      fetchVentes();
      fetchAppartments();
      fetchProjects();
      fetchClients();
      fetchPaymentsType();
      fetchPaymentAgence();
      fetchPromote();
    }
  }, [isLoggedIn]);

  const fetchPromote = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/promote`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setPromote(response.data);
    } catch (error) {
      console.error("Error fetching status:", error);
    }
  };
  const fetchStatus = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/status`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setStatus(response.data);
    } catch (error) {
      console.error("Error fetching status:", error);
    }
  };

  const fetchLeads = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/agence`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setAllLeads(response.data);
    } catch (error) {
      console.error("Error fetching Leads:", error);
    }
  };

  const fetchUsers = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };
  const fetchAppartments = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/apartment`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setAppartments(response.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };

  const fetchClients = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/client`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };
  const fetchProjects = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/project`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };
  const fetchVentes = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/vente`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setVentes(response.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };
  const fetchPaymentsType = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/payment`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setPaymentsType(response.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };
  const fetchPaymentAgence = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/paymentAgence`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setPaymentAgence(response.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };

  useEffect(() => {
    fetchStatus();
    fetchLeads();
    fetchUsers();
    fetchAppartments();
    fetchClients();
    fetchProjects();
    fetchVentes();
    fetchPaymentsType();
    fetchPaymentAgence();
    fetchPromote();
  }, []);

  return (
    <DataContext.Provider
      value={{
        status,
        allLeads,
        users,
        clients,
        appartments,
        projects,
        ventes,
        paymentsType,
        paymentAgence,
        promote,
        reFetchStatus: fetchStatus,
        reFetchAllLead: fetchLeads,
        reFetchUsers: fetchUsers,
        reFetchClients: fetchClients,
        reFetchAppartments: fetchAppartments,
        reFetchProjects: fetchProjects,
        refetchVentes: fetchVentes,
        refetchPaymentsType: fetchPaymentsType,
        refetchPaymentAgence: fetchPaymentAgence,
        refetchPromote: fetchPromote,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
