import "./TemplateTable.css";
import { handleCheckboxChange } from "../../../../../utils/datatable.utils";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import SelectOptionsTable from "../../../../SelectOptionsTable/SelectOptionsTable";
import AddIcon from "@mui/icons-material/Add";
import DeleteMessage from "../../DeleteMessage/DeleteMessage";
import { useData } from "../../../../../context/DataContext";
import CreateTemplate from "../../CreateTemplate/CreateTemplate";

const TemplateTable = ({ data, reFetch }) => {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedName, setSelectedDeletedName] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [openCreateTemplate, setOpenCreateTemplate] = useState(null);

  const { projects, refetchProjects } = useData();

  const tableRef = useRef(null);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };
  const selectCurrentPage = () => {
    const start = currentPage * perPage;
    const end = start + perPage;
    const currentPageItems = data.slice(start, end).map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = () => {
    const allItems = data.map((item) => item._id);
    setCheckedItems(allItems);
  };

  const handleOpenDeleteMessage = (templateName) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedName(templateName);
  };

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Template : </span>
            </div>
            <div className="rightElements">
              <button onClick={() => setOpenCreateTemplate(true)}>
                <AddIcon />
                <span>Crée Template</span>
              </button>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />
                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">Nom</div>
                </th>
                <th>
                  <div className="headerWithIcon">Categorie</div>
                </th>

                <th>
                  <div className="headerWithIcon">Status</div>
                </th>
                <th>
                  <div className="headerWithIcon">Langue</div>
                </th>
                <th>
                  <div className="headerWithIcon">Text</div>
                </th>
                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                const fullText = item.components?.[0]?.text || ""; // Texte du composant (si disponible)
                const previewText = fullText.split(" ").slice(0, 3).join(" "); // Extraire les 3 premiers mots

                return (
                  <tr key={index}>
                    <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          checked={checkedItems.includes(item.leadId)}
                          onChange={(e) =>
                            handleCheckboxChangeWrapper(e, item.leadId)
                          }
                        />
                        <div className="checkmark"></div>
                      </label>
                    </td>
                    <td>{item.name}</td>
                    <td>{item.category}</td>
                    <td>{item.status}</td>
                    <td>{item.language}</td>
                    <td>
                      <div className="textPreview" title={fullText}>
                        {previewText}...
                      </div>
                    </td>
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleOpenDeleteMessage(item.name)}
                      >
                        del
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {data?.length > 0 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(data?.length / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}

          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={data}
              pageData={data}
              setIsAllChecked={setIsAllChecked}
            />
          )}

          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              name={selectedDeletedName}
            />
          )}
          {openCreateTemplate && (
            <CreateTemplate setOpen={setOpenCreateTemplate} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateTable;
