import "./TemplatePage.css";
import axios from "axios";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { PromoteTitles } from "../../../constants/navigationTitles";
import { useCallback, useEffect, useState } from "react";
import { useData } from "../../../context/DataContext";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import TemplateTable from "../../../components/Promote/Template/Template/PromoteTable/TemplateTable";

const TemplatePage = () => {
  const [templates, setTemplates] = useState();

  const refetchTemplate = {};

  const fetchAllTemplates = async () => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v20.0/${process.env.REACT_APP_BUSINESS_ID}/message_templates`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_FB}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Accéder aux données des modèles et les mettre dans l'état
      if (response.data && response.data.data) {
        setTemplates(response.data.data); // Assure-toi d'accéder à la propriété data de la réponse
      } else {
        console.log("Aucun modèle trouvé.");
      }
    } catch (err) {
      console.log("Erreur lors de la récupération des modèles :", err);
    }
  };

  console.log(templates);
  useEffect(() => {
    fetchAllTemplates();
  }, []);

  const data = {};
  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabs tabs={PromoteTitles} />
        </div>
        <div className="bottomCrmPage">
          <TemplateTable data={templates} reFetch={fetchAllTemplates} />
        </div>
      </div>
    </div>
  );
};

export default TemplatePage;
