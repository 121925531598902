import "./Admin.css";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import CreateAccount from "../CreateAccount/CreateAccount";
import EditAccount from "../EditAccount/EditAccount";

const Admin = ({ data, onDeleteClick, reFetchFromTable }) => {
  const [openCreateAdmin, setOpenCreateAdmin] = useState(false);
  const [openEditAdmin, setOpenEditAdmin] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);

  const handleOpenEditPopup = (userId) => {
    setSelectedAdminId(userId);
    setOpenEditAdmin(true);
  };
  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Administrators : {data.length}</span>
            </div>

            <div className="rightElements">
              <button onClick={() => setOpenCreateAdmin(true)}>
                <AddIcon />
                <span>Create</span>
              </button>
            </div>
          </div>
          <table className="tableData">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">
                    Full name <KeyboardArrowDownIcon />
                  </div>
                </th>
                <th></th>
                <th>
                  <div className="headerWithIcon">
                    Username <KeyboardArrowDownIcon />
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">
                    Email <KeyboardArrowDownIcon />
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">
                    Extention <KeyboardArrowDownIcon />
                  </div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr>
                  <td>
                    <div className="nameAndStatusAndImg">
                      <img
                        src={item.pPicture || `/images/defaultImg.png`}
                        alt={item.pPicture}
                      />
                      <span>{item.name}</span>
                    </div>
                  </td>
                  <td>
                    <div className="rondConnectStatus"></div>
                  </td>
                  <td>
                    <span>{item.username}</span>
                  </td>
                  <td>
                    <span>{item.email}</span>
                  </td>
                  <td>
                    <span>{item.phone}</span>
                  </td>

                  <td>
                    <span
                      className="hover-effect"
                      onClick={() => handleOpenEditPopup(item._id)}
                    >
                      edit
                    </span>
                    <span
                      className="hover-effect"
                      onClick={() => onDeleteClick(item._id)}
                    >
                      del
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {openCreateAdmin && (
            <CreateAccount
              setOpen={setOpenCreateAdmin}
              role={"isAdmin"}
              reFetchFromTable={reFetchFromTable}
            />
          )}
          {openEditAdmin && (
            <EditAccount
              setOpen={setOpenEditAdmin}
              reFetchFromTable={reFetchFromTable}
              id={selectedAdminId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Admin;
