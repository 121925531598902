import "./CreateVentePopUp.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import { useData } from "../../../../context/DataContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CreateVentePopUp = ({
  setOpen,
  reFetch,
  projects,
  allLeads,
  onCreateClient,
  newVente,
  setNewVente,
  apartments,
  setApartments,
  setClients,
  onAgencyChange,
  clients,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAgency, setFilteredAgency] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const { reFetchProjects, refetchVentes } = useData();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [apartmentPrice, setApartmentPrice] = useState(""); // New state for price
  const { reFetchAppartments } = useData();
  useEffect(() => {
    if (newVente.agence) {
      const selectedAgence = allLeads.find((p) => p._id === newVente.agence);
      if (selectedAgence) {
        setClients(selectedAgence.client);
      }
    }
  }, [allLeads, newVente.agence, setClients]);

  useEffect(() => {
    // Update price when a new apartment is selected
    const selectedApartment = apartments.find(
      (a) => a._id === newVente.appartment
    );
    if (selectedApartment) {
      setApartmentPrice(selectedApartment.price);
    }
  }, [newVente.appartment, apartments]);

  const handleCreateVente = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/vente/`,
        newVente
      );
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/apartment/${newVente.appartment}`,
        { isSold: true, price: apartmentPrice }
      );
      reFetch();
      handleClose();
      reFetchProjects();
      refetchVentes();
      reFetchAppartments();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewVente((prev) => ({ ...prev, [name]: value }));

    if (name === "project") {
      const selectedProject = projects.find((p) => p._id === value);
      if (selectedProject) {
        setApartments(selectedProject.apartments);
      } else {
        setApartments([]);
      }
    } else if (name === "agence") {
      onAgencyChange(value); // Appel de la fonction pour mettre à jour les clients
    }
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = allLeads.filter(
      (l) =>
        l.signed.isSigned &&
        (l?.agencyName?.toLowerCase().includes(query) ||
          l?.email?.toLowerCase().includes(query) ||
          l?.reference?.toLowerCase().includes(query) ||
          l?.name?.toLowerCase().includes(query))
    );

    setFilteredAgency(filtered);
  };

  const handleLeadSelect = (leadId, leadName) => {
    setNewVente((prev) => ({ ...prev, agence: leadId, agencyName: leadName }));
    setIsDropdownOpen(false);
  };

  const handleClose = () => {
    setSearchQuery("");
    setNewVente({
      project: "",
      appartment: "",
      agence: "",
      client: "",
    });
    setOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (!isDropdownOpen) {
      setFilteredAgency(allLeads);
    }
  };

  return (
    <>
      <div className="overlay" onClick={handleClose}>
        <div
          className="createSignature"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="closeIconStatus">
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>Crée une vente</span>
            </div>
            <div className="middleCreateStatus">
              <div className="createVenteContainerInput">
                <select
                  name="project"
                  value={newVente.project}
                  onChange={handleChange}
                >
                  <option value="">Choisir Projet</option>
                  {projects.map((p) => (
                    <option key={p._id} value={p._id}>
                      {p.name}
                    </option>
                  ))}
                </select>

                <select
                  name="appartment"
                  value={newVente.appartment}
                  onChange={handleChange}
                >
                  <option value="">Choisir Appartement</option>
                  {apartments
                    .filter((a) => !a.isSold)
                    .map((a) => (
                      <option key={a._id} value={a._id}>
                        {a.name || `Appartement ${a.reference}`}
                      </option>
                    ))}
                </select>

                {newVente.appartment && (
                  <input
                    type="number"
                    value={apartmentPrice}
                    onChange={(e) => setApartmentPrice(e.target.value)}
                    placeholder="Prix de l'appartement"
                    className="apartmentPriceInput"
                  />
                )}

                <div
                  className={`selectWithSearch ${isDropdownOpen ? "open" : ""}`}
                >
                  <div className="selectInput" onClick={toggleDropdown}>
                    {newVente.agencyName ? (
                      <span>{newVente.agencyName}</span>
                    ) : (
                      <span>Choisir Agence</span>
                    )}
                  </div>
                  {isDropdownOpen && (
                    <div className="dropdownContainer">
                      <input
                        type="text"
                        className="searchInput"
                        placeholder="Rechercher une agence"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <ul className="dropdownList">
                        {filteredAgency.length > 0 ? (
                          filteredAgency
                            .filter((a) => a.signed.isSigned)
                            .map((agency) => (
                              <li
                                key={agency._id}
                                onClick={() =>
                                  handleLeadSelect(
                                    agency._id,
                                    agency.agencyName
                                  )
                                }
                              >
                                {agency.agencyName} (ID: {agency.reference})
                              </li>
                            ))
                        ) : (
                          <li>Aucun résultat</li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>

                <select
                  name="client"
                  value={newVente.client}
                  onChange={handleChange}
                >
                  <option value="">Choisir Client</option>
                  {clients.map((client) => (
                    <option key={client._id} value={client._id}>
                      {client.name || `Client ${client.name}`}
                    </option>
                  ))}
                </select>

                {newVente.client === "" && newVente.agence && (
                  <button onClick={onCreateClient}>Cree un client</button>
                )}
              </div>
            </div>
            <div className="bottomCreateStatus">
              <button
                className="cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button className="saveButton" onClick={handleCreateVente}>
                <CheckIcon />
                Comfirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateVentePopUp;
