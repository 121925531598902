import "./PaiementTable.css";
import { handleCheckboxChange } from "../../../../utils/datatable.utils";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import DeleteMessage from "../DeleteMessage/DeleteMessage";
import axios from "axios";
import PopupClientContainer from "../../Client/PopupClient/PopupClientContainer/PopupClientContainer";
import { useData } from "../../../../context/DataContext";
import EditAppartment from "../../../Projet/Appartment/AppartmentTable/EditAppartment/EditAppartment";

const PaiementTable = ({ data, reFetch, users, reFetchUsers }) => {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [openPopupClient, setOpenPopupClient] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { clients, reFetchClients } = useData();
  const tableRef = useRef(null);
  const [openPopUpEditAppartment, setOpenPopUpEditAppartment] = useState(false);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };
  const selectCurrentPage = () => {
    const start = currentPage * perPage;
    const end = start + perPage;
    const currentPageItems = data.slice(start, end).map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = () => {
    const allItems = data.map((item) => item._id);
    setCheckedItems(allItems);
  };

  const handleOpenDeleteMessage = (appartmentId, paymentId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId(appartmentId);
    setSelectedPaymentId(paymentId);
  };

  const totalPayments = data.reduce(
    (acc, item) => acc + item.payment.length,
    0
  );

  const totalValuePayment = data.reduce((acc, item) => {
    const totalPaymentForItem = item.payment.reduce(
      (sum, payment) => sum + payment.amount,
      0
    );
    return acc + totalPaymentForItem;
  }, 0);

  const handleOpenPopupClient = (item) => {
    setSelectedData(item);
    setOpenPopupClient(true);
  };
  const handleOpenPopuEditAppartment = (data) => {
    setSelectedData(data);
    setOpenPopUpEditAppartment(true);
  };

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>
                Paiements : {totalPayments}&nbsp;&nbsp;-&nbsp;&nbsp;CA &nbsp;:{" "}
                {totalValuePayment.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                €
              </span>
            </div>
            <div className="rightElements">
              <button style={{ visibility: "hidden" }}>None</button>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />
                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">Projet</div>
                </th>
                <th>
                  <div className="headerWithIcon">Appartements</div>
                </th>
                <th>
                  <div className="headerWithIcon">Client</div>
                </th>
                <th>
                  <div className="headerWithIcon">Montant</div>
                </th>
                <th>
                  <div className="headerWithIcon">Date</div>
                </th>
                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                .slice(currentPage * perPage, (currentPage + 1) * perPage)
                .map((item, index) =>
                  item.payment.map((payment, paymentIndex) => (
                    <tr key={`${index}-${paymentIndex}`}>
                      <td>
                        <label className="container">
                          <input
                            type="checkbox"
                            checked={checkedItems.includes(item._id)}
                            onChange={(e) =>
                              handleCheckboxChangeWrapper(e, item._id)
                            }
                          />
                          <div className="checkmark"></div>
                        </label>
                      </td>

                      <td>
                        <span>{item.projects.name}</span>
                      </td>

                      <td onClick={() => handleOpenPopuEditAppartment(item)}>
                        <span>{item.reference}</span>
                      </td>

                      <td
                        onClick={() =>
                          handleOpenPopupClient(payment.client, index)
                        }
                      >
                        <span>{payment.client.name}</span>
                      </td>

                      <td>
                        <span>{payment.amount} €</span>
                      </td>
                      <td>
                        <span>
                          {moment(payment.paymentDate).format("DD/MM/YYYY")}
                        </span>
                      </td>

                      <td>
                        <span
                          className="hover-effect"
                          onClick={() =>
                            handleOpenDeleteMessage(item._id, payment._id)
                          }
                        >
                          del
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              {data.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {data.length > 0 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(data.length / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}

          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={data}
              pageData={data}
              setIsAllChecked={setIsAllChecked}
            />
          )}

          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              endPoint={"apartment"}
              id={selectedDeletedId}
              paymentId={selectedPaymentId}
            />
          )}
          {openPopupClient && (
            <PopupClientContainer
              setOpen={setOpenPopupClient}
              data={selectedData}
              reFetchFromTable={reFetch}
              clients={clients}
              reFetchClients={reFetchClients}
            />
          )}
        </div>
        {openPopUpEditAppartment && (
          <EditAppartment
            setOpen={setOpenPopUpEditAppartment}
            reFetchFromTable={reFetch}
            selectedData={selectedData}
          />
        )}
      </div>
    </div>
  );
};

export default PaiementTable;
