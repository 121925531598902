import Dashboard from "../Dashboard/Dashboard";
import "./TopTab.css";
import { useContext, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { AuthContext } from "../../../../../context/auth.context";
import Vente from "../Vente/Vente";
import Clients from "../Clients/Clients";
import Commission from "../Commission/Commission";
import { useData } from "../../../../../context/DataContext";
const TopTab = ({ currentData, reFetchFromTable, leads, reFetchAllLead }) => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const { user } = useContext(AuthContext);
  const { ventes, clients, refetchVentes, reFetchClients } = useData();
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="popupRigthSideWrapper">
      <div className="popupRigthSideBottom">
        {activeTab === "Dashboard" && (
          <Dashboard
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
            leads={leads}
            reFetchAllLead={reFetchAllLead}
          />
        )}
        {activeTab === "Ventes" && (
          <Vente
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
            ventes={ventes}
            refetchVentes={refetchVentes}
            leads={leads}
            reFetchAllLead={reFetchAllLead}
          />
        )}
        {activeTab === "Clients" && (
          <Clients
            currentData={currentData}
            clients={clients}
            reFetchClients={reFetchClients}
            reFetchFromTable={reFetchFromTable}
            leads={leads}
            reFetchAllLead={reFetchAllLead}
            ventes={ventes}
          />
        )}
        {activeTab === "Paiement" && (
          <Commission
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
            leads={leads}
            reFetchAllLead={reFetchAllLead}
          />
        )}
      </div>
    </div>
  );
};

export default TopTab;
