import "./EditStatus.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";

const EditStatus = ({ setOpen, reFetchFromTable, id }) => {
  const { data: status } = useFetch("status");
  const [editStatus, setEditStatus] = useState({ title: "" });

  useEffect(() => {
    if (status) {
      const findCorrespondingStatus = status.find((s) => s._id === id);
      if (findCorrespondingStatus) {
        setEditStatus({ title: findCorrespondingStatus.title });
      }
    }
  }, [status, id]);

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleChange = (e) => {
    setEditStatus({ ...editStatus, [e.target.name]: e.target.value });
  };

  const handleEditStatus = async (e) => {
    e.preventDefault();

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/status/${id}`,
        editStatus
      );
      reFetchFromTable();
      setEditStatus({ title: "" });
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>Edit Status</span>
            </div>
            <div className="middleCreateStatus">
              <label>Name</label>
              <input
                type="text"
                name="title"
                value={editStatus.title}
                onChange={handleChange}
              />
            </div>
            <div className="bottomCreateStatus">
              <button className="cancelButton">Cancel</button>
              <button className="saveButton" onClick={handleEditStatus}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStatus;
