import "./AttributionPage.css";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import Attribution from "../../../components/CRM/Attribution/AttributionTable/AttributionTable";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { AgenceTitles } from "../../../constants/navigationTitles";
import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useSocket } from "../../../context/SocketContext";
import { useData } from "../../../context/DataContext";

const AttributionPage = () => {
  const { allLeads, reFetchAllLead } = useData();
  const [leads, setLeads] = useState([]);
  const [allLeadIds, setAllLeadIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalLeads, setTotalLeads] = useState(0);
  const { socket } = useSocket();
  const [selectedFilters, setSelectedFilters] = useState({
    country: "All Countries",
  });
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const fetchLeads = async (fetchAllIds = false) => {
    const queryParams = new URLSearchParams({
      ...selectedFilters,
      page: currentPage,
      limit: 50,
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
      fetchAllIds: fetchAllIds ? "true" : undefined,
    }).toString();

    try {
      const storedToken = localStorage.getItem("authToken");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/unassigned/pagination?${queryParams}`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      if (fetchAllIds) {
        setAllLeadIds(response.data);
      } else {
        setLeads(response.data.agences);
        setTotalLeads(response.data.total);
      }
      reFetchAllLead();
    } catch (error) {
      console.error("Erreur lors de la récupération des leads:", error);
    }
  };

  useEffect(() => {
    if (socket == null) return;

    const handleRefresh = () => {
      fetchLeads();
    };
    socket.on("refreshData", handleRefresh);

    return () => {
      socket.off("refreshData", handleRefresh);
    };
  }, [selectedFilters, socket]);

  useEffect(() => {
    fetchLeads();
  }, [currentPage, selectedFilters, selectedDateRange]);

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);


  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            tabs={AgenceTitles}
            onFilteredDataChange={handleFilteredDataChange}
          />
        </div>
        <div className="bottomCrmPage">
          <Attribution
            data={leads}
            reFetch={fetchLeads}
            currentPage={currentPage}
            setPage={setCurrentPage}
            totalLeads={totalLeads}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            allLeadIds={allLeadIds}
            allLeads={allLeads}
            reFetchAllLead={reFetchAllLead}
          />
        </div>
      </div>
    </div>
  );
};

export default AttributionPage;
