import Dashboard from "../Dashboard/Dashboard";
import "./TopTab.css";
import { useContext, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { AuthContext } from "../../../../../../context/auth.context";
import Vente from "../Vente/Vente";
import { useData } from "../../../../../../context/DataContext";
const TopTab = ({ currentData, reFetchFromTable, reFetchClients }) => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const { user } = useContext(AuthContext);
  const { ventes, clients, refetchVentes } = useData();
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="popupRigthSideWrapper">
      <div className="popupRigthSideBottom">
        {activeTab === "Dashboard" && (
          <Dashboard
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
            reFetchClients={reFetchClients}
          />
        )}
        {activeTab === "Ventes" && (
          <Vente
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
            ventes={ventes}
            refetchVentes={refetchVentes}
            reFetchClients={reFetchClients}
          />
        )}
      </div>
    </div>
  );
};

export default TopTab;
