import moment from "moment";
import "./Paiement.css";
import DeleteMessage from "../../../../../DeleteMessage/DeleteMessage";
import { useState } from "react";

const Paiement = ({ payments }) => {
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);

  const handleDeletePayment = (paymentId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId(paymentId);
  };


  return (
    <>
      <div className="containerDatatableHistory">
        <div className="datatableHistory">
          <table className="tableDataHistory">
            <thead className="dtThead">
              <tr>
                <th>Type</th>
                <th>Montant</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {payments && payments.length > 0 ? (
                payments.map((payment) => (
                  <tr key={payment._id}>
                    <td>{payment.typePayment?.title || "N/A"}</td>
                    <td>{payment.amount}</td>
                    <td>{moment(payment.paymentDate).format("DD/MM/YYYY")}</td>
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleDeletePayment(payment._id)}
                      >
                        del
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No payments found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {openDeleteMessage && (
          <DeleteMessage
            setOpen={setOpenDeleteMessage}
            id={selectedDeletedId}
          />
        )}
      </div>
    </>
  );
};

export default Paiement;
