import "./SalesPage.css";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import { AccountTitles } from "../../../constants/navigationTitles";
import Sales from "../../../components/Accounts/Sales/Sales";
import useFetch from "../../../hooks/useFetch";
import { useState, useEffect } from "react";
import DeleteMessage from "../../../components/DeleteMessage/DeleteMessage";
import { useData } from "../../../context/DataContext";
const SalesPage = () => {
  const { data: users, reFetch } = useFetch("user");
  const { reFetchUsers } = useData();
  const [allSales, setAllSales] = useState([]);
  const [showDeleteSalesMessage, setShowdeleteSalesMessage] = useState(false);
  const [selectedSalesId, setSelectedSalesId] = useState(null);

  useEffect(() => {
    const salesUsers = users.filter((u) => u.isSales);
    setAllSales(salesUsers);
  }, [users]);

  const openDeleteAdminPopup = (salesId) => {
    setSelectedSalesId(salesId);
    setShowdeleteSalesMessage(true);
  };

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <div className="navigationAndFilter">
            <NavigationTabs tabs={AccountTitles} />
          </div>
        </div>
        <div className="bottomCrmPage">
          <Sales
            data={allSales}
            onDeleteClick={openDeleteAdminPopup}
            reFetchFromTable={reFetch}
          />
          {showDeleteSalesMessage && (
            <DeleteMessage
              setOpen={setShowdeleteSalesMessage}
              id={selectedSalesId}
              reFetchFromTable={reFetch}
              endPoint={"user"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesPage;
