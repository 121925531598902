import "./CreateSingleAgence.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import axios from "axios";
import CountrySelect from "../../../../CountrySelect/CountrySelect";
import useFetch from "../../../../../hooks/useFetch";

const CreateSingleAgence = ({ setOpen, reFetchFromTable }) => {
  const { data: agences } = useFetch("agence");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState({});

  const [newLead, setNewLead] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
  });

  const isFormValid = () => {
    let isValid = true;
    let newError = {};

    if (!newLead.name.trim()) {
      newError.name = "Name is required";
      isValid = false;
    }
    if (!lastName.trim()) {
      newError.lastName = "Last name is required";
      isValid = false;
    }
    if (!newLead.email.trim()) {
      newError.email = "Email is required";
      isValid = false;
    }

    setError(newError);
    return isValid;
  };

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "lastName") {
      setLastName(value);
    } else {
      setNewLead((prev) => ({ ...prev, [name]: value }));
    }

    // Réinitialiser l'erreur spécifique à ce champ
    setError((prevError) => ({ ...prevError, [name]: "" }));
  };

  const handleAddLead = async (e) => {
    e.preventDefault();

    if (!isFormValid()) return;

    const emailExists = agences.some(
      (lead) => lead.email === newLead.email.trim()
    );
    if (emailExists) {
      setError((prevError) => ({
        ...prevError,
        email: "Email already exists",
      }));
      return;
    }

    const combinedLead = {
      ...newLead,
      name: `${newLead.name} ${lastName}`,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/`,
        combinedLead
      );
      reFetchFromTable();
      setNewLead({ name: "", email: "", phone: "", country: "" });
      setLastName("");
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createLead" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createLeadWrapper">
            <div className="topCreateStatus">
              <span>Crée Agence</span>
            </div>
            <div className="middleCreateLead">
              <label>Nom de l'agence</label>
              <input
                type="text"
                name="agencyName"
                value={newLead.agencyName}
                onChange={handleChange}
              />
              <label>Prenom</label>
              <input
                type="text"
                name="name"
                value={newLead.name}
                onChange={handleChange}
              />
              {error.name && <div className="errormessage">{error.name}</div>}
              <label>Nom</label>
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {error.lastName && (
                <div className="errormessage">{error.lastName}</div>
              )}
              <label>Email</label>
              <input
                type="text"
                name="email"
                value={newLead.email}
                onChange={handleChange}
              />
              {error.email && <div className="errormessage">{error.email}</div>}
              <label>Telephone</label>
              <input
                type="text"
                name="phone"
                value={newLead.phone}
                onChange={handleChange}
              />

              <CountrySelect
                label="Country"
                className="countrySelectCreateLead"
                value={newLead.country}
                onChange={handleChange}
                name="country"
              />
            </div>
            <div className="bottomCreateLead">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleAddLead}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSingleAgence;
