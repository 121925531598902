import { useState, useContext, useEffect } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { AuthContext } from "../../../context/auth.context";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";

const Sidebar = () => {
  const { user, logOutUser } = useContext(AuthContext);
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState("");
  const [sideBarOpen, setSideBarOpen] = useState(() => {
    const savedState = localStorage.getItem("sidebarOpen");
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  useEffect(() => {
    localStorage.setItem("sidebarOpen", JSON.stringify(sideBarOpen));
  }, [sideBarOpen]);

  const handleMenuIconClick = () => {
    setSideBarOpen(!sideBarOpen);
  };

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const currentPath =
      pathSegments.length > 2 ? capitalize(pathSegments[2]) : "";

    setSelectedItem(currentPath);
  }, [location.pathname]);

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className={`sidebar ${sideBarOpen ? "" : "closed"}`}>
      <div className={`iconSideBarArrow ${sideBarOpen ? "" : "closed"}`}>
        {sideBarOpen ? (
          <KeyboardArrowLeftIcon onClick={handleMenuIconClick} />
        ) : (
          <KeyboardArrowRightIcon onClick={handleMenuIconClick} />
        )}
      </div>
      <div className={`topLogoSidebar ${sideBarOpen ? "" : "closed"}`}>
        {sideBarOpen ? (
          <img src="/images/mobestate.png" alt="Logo" />
        ) : (
          <img src="/images/logoMobile.png" alt="Logo" />
        )}
      </div>
      <div className={`centerSidebar ${sideBarOpen ? "" : "closed"}`}>
        <div className={`centerImgSidebar ${sideBarOpen ? "" : "closed"}`}>
          <img src={user.pPicture || "/images/userimg.jpeg"} alt="" />
        </div>
        <div className={`centerNameSidebar ${sideBarOpen ? "" : "closed"}`}>
          <span>{user && user.name}</span>
        </div>
        <div
          className={`centerSignOutSidebar ${sideBarOpen ? "" : "closed"}`}
          onClick={logOutUser}
        >
          {sideBarOpen ? (
            <span>Sign Out</span>
          ) : (
            <LogoutIcon style={{ fontSize: "16px" }} />
          )}
        </div>
      </div>
      <div className={`bottomLinkSidebar ${sideBarOpen ? "" : "closed"}`}>
        <div className={`wrapperItemSidebar ${sideBarOpen ? "" : "closed"}`}>
          <Link
            to="/management/"
            className={`link ${selectedItem === "" ? "selected" : ""} ${
              !sideBarOpen ? "closed" : ""
            }`}
            onClick={() => handleItemClick("/")}
          >
            <div className={`singleItemSidebar ${sideBarOpen ? "" : "closed"}`}>
              <div className={`iconContainer ${sideBarOpen ? "" : "closed"}`}>
                <GridViewIcon style={{ fontSize: 20 }} />
              </div>
              {sideBarOpen && <span>Dashboard</span>}
            </div>
          </Link>
          <Link
            to={
              user.isSuperAdmin
                ? "/management/crm/attribution"
                : "/management/crm/customers"
            }
            className={`link ${selectedItem === "Crm" ? "selected" : ""} ${
              !sideBarOpen ? "closed" : ""
            }`}
            onClick={() => handleItemClick("Crm")}
          >
            <div className="singleItemSidebar">
              <div className={`iconContainer ${sideBarOpen ? "" : "closed"}`}>
                <SupervisedUserCircleIcon style={{ fontSize: 20 }} />
              </div>
              {sideBarOpen && <span>Agences</span>}
            </div>
          </Link>

          {user.isSuperAdmin && (
            <Link
              to="/management/ventes/vente"
              className={`link ${selectedItem === "Ventes" ? "selected" : ""} ${
                !sideBarOpen ? "closed" : ""
              }`}
              onClick={() => handleItemClick("Catalogue")}
            >
              <div className="singleItemSidebar">
                <div className={`iconContainer ${sideBarOpen ? "" : "closed"}`}>
                  <ShoppingCartIcon style={{ fontSize: 20 }} />
                </div>
                {sideBarOpen && <span>Ventes</span>}
              </div>
            </Link>
          )}
          {user.isSuperAdmin && (
            <Link
              to="/management/projets/projet"
              className={`link ${
                selectedItem === "Projets" ? "selected" : ""
              } ${!sideBarOpen ? "closed" : ""}`}
              onClick={() => handleItemClick("Projets")}
            >
              <div className="singleItemSidebar">
                <div className={`iconContainer ${sideBarOpen ? "" : "closed"}`}>
                  <HomeIcon style={{ fontSize: 20 }} />
                </div>
                {sideBarOpen && <span>Projets</span>}
              </div>
            </Link>
          )}
          {user.isSuperAdmin && (
            <Link
              to="/management/promote/promote"
              className={`link ${
                selectedItem === "Promote" ? "selected" : ""
              } ${!sideBarOpen ? "closed" : ""}`}
              onClick={() => handleItemClick("Promote")}
            >
              <div className="singleItemSidebar">
                <div className={`iconContainer ${sideBarOpen ? "" : "closed"}`}>
                  <CallMissedOutgoingIcon style={{ fontSize: 20 }} />
                </div>
                {sideBarOpen && <span>Promote</span>}
              </div>
            </Link>
          )}

          {user.isSuperAdmin && (
            <Link
              to="/management/accounts/admin"
              className={`link ${
                selectedItem === "Accounts" ? "selected" : ""
              } ${!sideBarOpen ? "closed" : ""}`}
              onClick={() => handleItemClick("Accounts")}
            >
              <div className="singleItemSidebar">
                <div className={`iconContainer ${sideBarOpen ? "" : "closed"}`}>
                  <PersonAddIcon style={{ fontSize: 20 }} />
                </div>
                {sideBarOpen && <span>Profils</span>}
              </div>
            </Link>
          )}
          {user.isSuperAdmin && (
            <Link
              to="/management/settings/payments"
              className={`link ${
                selectedItem === "Settings" ? "selected" : ""
              } ${!sideBarOpen ? "closed" : ""}`}
              onClick={() => handleItemClick("Settings")}
            >
              <div className="singleItemSidebar">
                <div className={`iconContainer ${sideBarOpen ? "" : "closed"}`}>
                  <SettingsIcon style={{ fontSize: 20 }} />
                </div>
                {sideBarOpen && <span>Settings</span>}
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
