import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import AttributionPage from "./pages/CRM/AttributionPage/AttributionPage";
import AgencePage from "./pages/CRM/AgencePage/AgencePage";
import AdminPage from "./pages/Accounts/AdminPage/AdminPage";
import SalesPage from "./pages/Accounts/SalesPage/SalesPage";
import PaymentPage from "./pages/Settings/PaymentPage/PaymentPage";
import StatusPage from "./pages/Settings/StatusPage/StatusPage";
import { AuthContext } from "./context/auth.context";
import { useContext } from "react";
import DepositPage from "./pages/Banking/DepositPage/DepositPage";
import ProjetPage from "./pages/Projet/ProjetPage/ProjetPage";
import AppartmentPage from "./pages/Projet/AppartmentPage/AppartmentPage";
import SignePage from "./pages/CRM/SignePage/SignePage";
import VentePage from "./pages/Vente/VentePage/VentePage";
import ClientPage from "./pages/Vente/ClientPage/ClientPage";
import PaiementPage from "./pages/Vente/PaiementPage/PaiementPage";
import PromotePage from "./pages/Promote/PromotePage/PromotePage";
import TemplatePage from "./pages/Promote/TemplatePage/TemplatePage";

function App() {
  const { isLoggedIn, user } = useContext(AuthContext);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/management/"
          element={isLoggedIn ? <HomePage /> : <LoginPage />}
        />

        <Route
          path="/management/crm/attribution"
          element={
            isLoggedIn && user.isSuperAdmin ? (
              <AttributionPage />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route
          path="/management/crm/customers"
          element={isLoggedIn ? <AgencePage /> : <LoginPage />}
        />
        <Route
          path="/management/crm/signe"
          element={isLoggedIn ? <SignePage /> : <LoginPage />}
        />
        <Route
          path="/management/projets/projet"
          element={isLoggedIn ? <ProjetPage /> : <LoginPage />}
        />
        <Route
          path="/management/projets/appartement"
          element={isLoggedIn ? <AppartmentPage /> : <LoginPage />}
        />
        <Route
          path="/management/ventes/vente"
          element={isLoggedIn ? <VentePage /> : <LoginPage />}
        />
        <Route
          path="/management/ventes/client"
          element={isLoggedIn ? <ClientPage /> : <LoginPage />}
        />
        <Route
          path="/management/ventes/paiement"
          element={isLoggedIn ? <PaiementPage /> : <LoginPage />}
        />

        <Route
          path="/management/banking/deposit"
          element={
            isLoggedIn && user.isSuperAdmin ? <DepositPage /> : <LoginPage />
          }
        />

        <Route
          path="/management/accounts/admin"
          element={
            isLoggedIn && user.isSuperAdmin ? <AdminPage /> : <LoginPage />
          }
        />

        <Route
          path="/management/accounts/sales"
          element={
            isLoggedIn && user.isSuperAdmin ? <SalesPage /> : <LoginPage />
          }
        />

        <Route
          path="/management/settings/payments"
          element={
            isLoggedIn && user.isSuperAdmin ? <PaymentPage /> : <LoginPage />
          }
        />
        <Route
          path="/management/settings/status"
          element={
            isLoggedIn && user.isSuperAdmin ? <StatusPage /> : <LoginPage />
          }
        />

        <Route
          path="/management/promote/promote"
          element={
            isLoggedIn && user.isSuperAdmin ? <PromotePage /> : <LoginPage />
          }
        />
        <Route
          path="/management/promote/template"
          element={
            isLoggedIn && user.isSuperAdmin ? <TemplatePage /> : <LoginPage />
          }
        />

        <Route path="/management/login" element={<LoginPage />} />
      </Routes>
    </div>
  );
}

export default App;
