import { handleCheckboxChange } from "../../../../utils/datatable.utils";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import CommentPopup from "../CommentPopup/CommentPopup";
import DeleteAllMessageCustomers from "../DeleteSigne/DeleteSigne";
import { countries } from "countries-list";
import PulseLoaderPopup from "../../../Loaders/PulseLoaderPopup/PulseLoaderPopup";
import PopupCustomerContainer from "../../PopupCustomer/PopupClientContainer/PopupCustomerContainer";
import { useSocket } from "../../../../context/SocketContext";
import { AuthContext } from "../../../../context/auth.context";
import moment from "moment";
import { useData } from "../../../../context/DataContext";
import DeleteSigne from "../DeleteSigne/DeleteSigne";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import SearchIcon from "@mui/icons-material/Search";

const SigneTable = ({
  data,
  reFetch,
  currentPage,
  setPage,
  totalLeads,
  selectedFilters,
  setSelectedFilters,
  userId,
  users,
  reFetchUsers,
  allLeads,
  reFetchAllLead,
}) => {
  const [perPage, setPerPage] = useState(50);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [isAllRowsHighlighted, setIsAllRowsHighlighted] = useState(false);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [openDeleteAllMessage, setOpenDeleteAllMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState({});
  const [selectedData, setSelectedData] = useState(null);
  const [openCommentPopUp, setOpenCommentPopUp] = useState(null);
  const [openPopupCustomer, setOpenPopupCustomer] = useState(false);
  const [showPulseLoader, setShowPulseLoader] = useState(false);
  const { emitRefreshData } = useSocket();
  const { user } = useContext(AuthContext);
  const { ventes, clients, projects } = useData();
  const tableRef = useRef(null);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchContainerRef, setSearchContainerRef] = useState(null);

  useEffect(() => {
    reFetch();
  }, [currentPage]);

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected + 1);
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };

  const selectCurrentPage = () => {
    const currentPageItems = data.map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = async () => {
    try {
      const queryParams = {
        userId: userId,
        user: selectedFilters.user,
        campaign: selectedFilters.campaign,
        status: selectedFilters.status,
        country: selectedFilters.country,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/userLeadsWithFilters`,
        { params: queryParams }
      );
      const allFilteredLeads = response.data;
      const allItems = allFilteredLeads.map((item) => item._id);
      setCheckedItems(allItems);
    } catch (err) {
      console.error("Erreur lors de la récupération des leads filtrés:", err);
    }
  };

  const handleOpenDeleteMessage = (agence) => {
    console.log("Clients:", agence.client); // Vérifiez la longueur et les clients
    console.log("Ventes associées:", ventes); // Vérifiez les ventes disponibles

    // Vérifiez si l'agence a des ventes associées
    const findAgenceInVentes = ventes.some((v) => v.agence._id === agence._id);

    console.log("Ventes trouvées pour l'agence:", findAgenceInVentes);

    if (agence.client.length > 0 || findAgenceInVentes) {
      alert("Cette agence contient des clients ou des ventes associées");
    } else {
      setOpenDeleteMessage(true);
      setSelectedDeletedId(agence);
    }
  };

  //Filter
  const handleUserFilterChange = (e) => {
    setSelectedFilters((filters) => ({
      ...filters,
      user: e.target.value,
    }));
    setPage(1);
  };

  const handleCountryFilterChange = (e) => {
    setSelectedFilters((filters) => ({
      ...filters,
      country: e.target.value,
    }));
    setPage(1);
  };

  const handleReferenceClick = (data) => {
    setSelectedData(data);
    setOpenCommentPopUp(true);
  };

  const handleOpenPopupCustomer = (data) => {
    setSelectedData(data);
    setOpenPopupCustomer(true);
  };

  function getCountryNameByISO(code) {
    return countries[code]?.name || code;
  }

  const agencyCountries = [
    ...new Set(allLeads.map((agency) => agency.country)),
  ];

  // Filtrer les pays disponibles à partir de la liste des agences
  const availableCountries = Object.keys(countries)
    .filter((code) => agencyCountries.includes(code))
    .map((code) => ({
      code: code,
      name: countries[code]?.name,
    }));

  const sortedAvailableCountries = availableCountries.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const countriesArray = Object.keys(countries).map((code) => ({
    code: code,
    name: countries[code].name,
  }));

  const sortedCountries = countriesArray.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const handleAssignmentChange = async (event, leadId) => {
    const newAssigneeId = event.target.value;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/assign-lead`,
        { leadId, newAssigneeId }
      );

      if (response.data) {
        emitRefreshData();
        reFetch();
        reFetchUsers();
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'assignation :", error);
    }
  };

  // Calculate the total CA for the filtered agencies
  const allVenteAgency = data.reduce((sum, agency) => {
    // Find all sales (ventes) related to this agency
    const salesForAgency = ventes.filter(
      (vente) =>
        vente.agence && vente.agence._id.toString() === agency._id.toString()
    );

    // Sum up the prices of all apartments sold by this agency
    const totalSalesValueForAgency = salesForAgency.reduce(
      (agencySum, vente) => {
        if (vente.appartment && vente.appartment.price) {
          return agencySum + vente.appartment.price;
        }
        return agencySum;
      },
      0
    );

    return sum + totalSalesValueForAgency;
  }, 0);

  const handleNumberOfVentes = (agencyId) => {
    const findCorrespondingVenteInAgency = ventes.filter(
      (v) => v.agence._id === agencyId
    );

    return findCorrespondingVenteInAgency.length;
  };

  const handleCAofAgency = (agencyId) => {
    const findCorrespondingVenteInAgency = ventes.filter(
      (v) => v.agence._id === agencyId
    );
    const getPriceOfAllVente = findCorrespondingVenteInAgency.reduce(
      (sum, vente) => {
        if (vente.appartment && vente.appartment.price) {
          return sum + vente.appartment.price;
        }
        return sum;
      },
      0
    );

    return getPriceOfAllVente.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Filtrer les leads non attribués basé sur la recherche
  const filteredLeads =
    searchValue.length > 0
      ? data.filter(
          (lead) =>
            lead.reference.toLowerCase().includes(searchValue.toLowerCase()) ||
            lead.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            lead.agencyName.toLowerCase().includes(searchValue.toLowerCase())
        )
      : data;

  const handleClickOutside = (event) => {
    if (searchContainerRef && !searchContainerRef.contains(event.target)) {
      setSearchActive(false);
      setSearchValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchContainerRef]);

  const getInitials = (agencyName) => {
    return agencyName.charAt(0).toUpperCase();
  };
  const getColorClass = (agencyName) => {
    const colorClasses = [
      "red",
      "blue",
      "purple",
      "green",
      "pink",
      "orange",
      "teal",
      "yellow",
      "brown",
      "gray",
    ];
    const index = agencyName.length % colorClasses.length;
    return colorClasses[index];
  };

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>
                Agences signées : {data.length}
                &nbsp;&nbsp;-&nbsp;&nbsp;Ventes : {ventes.length}
                &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;CA :&nbsp;&nbsp;
                {allVenteAgency.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                &nbsp;€
              </span>
              <div className="selectWithArrow">
                <UnfoldMoreIcon
                  className="selectWithArrowIcon"
                  style={{
                    fontSize: "13px",
                    color:
                      selectedFilters.country !== "All Countries"
                        ? "var(--primary-color)"
                        : "gray",
                  }}
                />
                <select
                  value={selectedFilters.country}
                  onChange={handleCountryFilterChange}
                  className={
                    selectedFilters.country !== "All Countries"
                      ? "greenBackgroundAfterSelect"
                      : ""
                  }
                >
                  <option value="All Countries">All Countries</option>
                  {sortedAvailableCountries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              {user.isSuperAdmin && (
                <div className="selectWithArrow">
                  <UnfoldMoreIcon
                    className="selectWithArrowIcon"
                    style={{
                      fontSize: "13px",
                      color:
                        selectedFilters.user !== "All Sales"
                          ? "var(--primary-color)"
                          : "gray",
                    }}
                  />
                  <select
                    value={selectedFilters.user}
                    onChange={handleUserFilterChange}
                    className={
                      selectedFilters.user !== "All Sales"
                        ? "greenBackgroundAfterSelect"
                        : ""
                    }
                  >
                    <option value="All Sales">All Sales</option>
                    {users
                      .filter((user) => user.isSales || user.isAdmin)
                      .map((user) => (
                        <option key={user._id} value={user._id}>
                          {user.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
            </div>

            <div className="rightElements">
              <div className="searchContainer" ref={setSearchContainerRef}>
                {!searchActive && (
                  <button onClick={() => setSearchActive(true)}>
                    <SearchIcon />
                    <span>Search</span>
                  </button>
                )}
                {searchActive && (
                  <div>
                    <input
                      type="text"
                      className="searchInput"
                      onChange={(e) => setSearchValue(e.target.value)}
                      autoFocus
                    />
                    <div className="searchResults">
                      {searchValue.length > 0 &&
                        filteredLeads.map((lead, index) => (
                          <div
                            key={index}
                            className="searchResultItem"
                            onClick={() => handleOpenPopupCustomer(lead, index)}
                          >
                            {lead.reference} - {lead.agencyName}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />

                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th></th>
                <th>
                  <div className="headerWithIcon">Agence</div>
                </th>

                <th>
                  <div className="headerWithIcon">Signe le</div>
                </th>

                <th>
                  <div className="headerWithIcon">Ventes</div>
                </th>
                <th>
                  <div className="headerWithIcon">CA</div>
                </th>
                <th>
                  <div className="headerWithIcon">%</div>
                </th>
                <th>
                  <div className="headerWithIcon">Sales</div>
                </th>
                <th>
                  <div className="headerWithIcon">Pays</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={
                    isAllRowsHighlighted || highlightedRows.includes(item._id)
                      ? "highlighted"
                      : ""
                  }
                >
                  <td>
                    <label className="container">
                      <input
                        type="checkbox"
                        checked={checkedItems.includes(item._id)}
                        onChange={(e) =>
                          handleCheckboxChangeWrapper(e, item._id)
                        }
                      />
                      <div className="checkmark"></div>
                    </label>
                  </td>

                  <td className="specialTd">
                    <span style={{ color: "var(--primary-color)" }}>
                      {item.reference}
                    </span>
                  </td>
                  <td onClick={() => handleOpenPopupCustomer(item, index)}>
                    <div className="nameAndStatus">
                      <div
                        className={`circleAgence ${getColorClass(
                          item.agencyName
                        )}`}
                      >
                        {getInitials(item.agencyName)}
                      </div>
                      <span style={{ color: "var(--primary-color)" }}>
                        {item.agencyName}
                      </span>
                    </div>
                  </td>

                  <td>
                    <span>
                      {moment(item.signed.signedDate).format("DD/MM/YYYY")}
                    </span>
                  </td>

                  <td>
                    <span>{handleNumberOfVentes(item._id)}</span>
                  </td>

                  <td>
                    <span>{handleCAofAgency(item._id)} €</span>
                  </td>
                  <td>
                    <span>{item.percentage} %</span>
                  </td>

                  <td className="tdWithArrow">
                    <UnfoldMoreIcon
                      className="selectWithArrowIconInTD"
                      style={{ fontSize: " 13px" }}
                    />
                    <select
                      className="statusLead"
                      value={
                        selectedAssignee[item._id] ||
                        (item.assignTo ? item.assignTo._id : "Not assign")
                      }
                      onChange={(e) => handleAssignmentChange(e, item._id)}
                    >
                      {item.assignTo ? (
                        <option value={item.assignTo._id}>
                          {item.assignTo.name}
                        </option>
                      ) : (
                        <option value="Not assign">Not assign</option>
                      )}
                      {users
                        .filter(
                          (user) =>
                            (user.isSales || user.isAdmin) &&
                            (!item.assignTo || user._id !== item.assignTo._id)
                        )
                        .map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <span>{getCountryNameByISO(item.country)}</span>
                  </td>
                  <td>
                    <span
                      className="hover-effect"
                      onClick={() => handleReferenceClick(item)}
                    >
                      com({item.comment.length}){" "}
                    </span>

                    <span
                      className="hover-effect"
                      onClick={() => handleOpenDeleteMessage(item)}
                    >
                      cancel
                    </span>
                  </td>
                </tr>
              ))}
              {data.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {data.length > 0 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(data.length / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={currentPage - 1}
            />
          )}

          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={totalLeads}
              pageData={data.length}
              setIsAllChecked={setIsAllChecked}
              name="customer"
            />
          )}

          {openPopupCustomer && (
            <PopupCustomerContainer
              setOpen={setOpenPopupCustomer}
              data={selectedData}
              leads={allLeads}
              reFetchAllLead={reFetchAllLead}
              reFetchFromTable={reFetch}
            />
          )}
          {openDeleteMessage && (
            <DeleteSigne
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              agence={selectedDeletedId}
              allLeads={allLeads}
            />
          )}
          {openDeleteAllMessage && (
            <DeleteAllMessageCustomers
              setOpen={setOpenDeleteAllMessage}
              reFetchFromTable={reFetch}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              setShowLoader={setShowPulseLoader}
              setIsAllChecked={setIsAllChecked}
              allLeads={allLeads}
            />
          )}
          {showPulseLoader && <PulseLoaderPopup />}
          {openCommentPopUp && (
            <CommentPopup
              data={selectedData}
              setOpen={setOpenCommentPopUp}
              reFetchFromTable={reFetch}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SigneTable;
