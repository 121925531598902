import "./CreatePromoteWithTemplate.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import axios from "axios";
import { useData } from "../../../../context/DataContext";
import { countries } from "countries-list";
import UploadIcon from "@mui/icons-material/Upload";

const CreatePromoteWithTemplate = ({
  setOpen,
  reFetchFromTable,
  setLoading,
  setResultMessage,
}) => {
  const { projects, allLeads } = useData();
  const [step, setStep] = useState(1);
  const [newPromote, setNewPromote] = useState({
    name: "",
    agence: [],
    project: "",
    messagesByCountry: {}, // Messages spécifiques par pays
  });
  const [pdfFileName, setPdfFileName] = useState("");
  const [selectedPlaceholder, setSelectedPlaceholder] = useState("");
  const [placeholderCounterByCountry, setPlaceholderCounterByCountry] =
    useState({}); // Compteurs de placeholders par pays
  const [showTooltip, setShowTooltip] = useState(false); // Tooltip visibility
  const [charCountByCountry, setCharCountByCountry] = useState({}); // Track character count by country
  const [pdfFileNameByCountry, setPdfFileNameByCountry] = useState({}); // Track PDF file names by country
  const [pdfLinkByCountry, setPdfLinkByCountry] = useState({}); // Track the PDF link by country

  // Placeholder data
  const exampleValues = {
    clientName: "Marc Jones",
    clientEmail: "marcjones@example.com",
    clientPhone: "+123456789",
    clientCountry: "USA",
  };

  // Fonction pour extraire les placeholders du texte
  const extractPlaceholders = (text) => {
    const regex = /\{\{\d+\}\}/g;
    return text.match(regex) || [];
  };

  // Insertion des placeholders dans le message spécifique au pays
  const insertPlaceholder = (country) => {
    if (selectedPlaceholder) {
      const currentMessage =
        newPromote.messagesByCountry[country]?.message || "";
      const placeholderCount = placeholderCounterByCountry[country] || 1;
      const newMessage = `${currentMessage} {{${placeholderCount}}}`; // Placeholder correctement inséré

      const placeholders =
        newPromote.messagesByCountry[country]?.placeholders || [];
      placeholders.push({
        placeholder: `{{${placeholderCount}}}`,
        example: selectedPlaceholder,
      });

      setNewPromote((prev) => ({
        ...prev,
        messagesByCountry: {
          ...prev.messagesByCountry,
          [country]: {
            ...prev.messagesByCountry[country],
            message: newMessage,
            placeholders: placeholders, // Stocker les placeholders pour ce pays
          },
        },
      }));

      setPlaceholderCounterByCountry((prev) => ({
        ...prev,
        [country]: placeholderCount + 1,
      }));
    }
  };

  const handleAgenceClick = (agenceId) => {
    setNewPromote((prev) => {
      const agenceAlreadySelected = prev.agence.includes(agenceId);

      if (agenceAlreadySelected) {
        return {
          ...prev,
          agence: prev.agence.filter((id) => id !== agenceId),
        };
      } else {
        return {
          ...prev,
          agence: [...prev.agence, agenceId],
        };
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPromote((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeTemplate = (e, country) => {
    const { name, value } = e.target;

    setNewPromote((prev) => ({
      ...prev,
      messagesByCountry: {
        ...prev.messagesByCountry,
        [country]: {
          ...prev.messagesByCountry[country],
          [name]: value, // Mise à jour du templateName, category, language
        },
      },
    }));
  };

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  function getCountryNameByISO(code) {
    return countries[code]?.name || code || "Inconnu"; // Retourne 'Inconnu' si le code est absent
  }

  const groupedAgencesByCountry = newPromote.agence.reduce((acc, agenceId) => {
    const agence = allLeads.find((a) => a._id === agenceId);
    if (agence) {
      const country = agence.country || "Inconnu"; // Utiliser "Inconnu" si le pays est manquant
      if (!acc[country]) {
        acc[country] = [];
      }
      acc[country].push(agence);
    }
    return acc;
  }, {});

  // Handle textarea input and update character count
  const handleCountryMessageChange = (e, country) => {
    const message = e.target.value;
    setNewPromote((prev) => ({
      ...prev,
      messagesByCountry: {
        ...prev.messagesByCountry,
        [country]: {
          ...prev.messagesByCountry[country],
          message,
        },
      },
    }));
    setCharCountByCountry((prev) => ({
      ...prev,
      [country]: message.length, // Update character count for the specific country
    }));
  };

  const handlePdfUpload = async (e, country) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/promote/upload-pdf`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const savedPdfPath = response.data.savedPdfPath;
      const pdfLink = `${process.env.REACT_APP_SERVER_URL}${savedPdfPath}`;
      setPdfFileNameByCountry((prev) => ({
        ...prev,
        [country]: file.name,
      }));
      setPdfLinkByCountry((prev) => ({
        ...prev,
        [country]: pdfLink,
      }));
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du fichier PDF:", error);
    }
  };

  // Function to copy the PDF link to the clipboard
  const handleCopyPdfLink = (pdfLink) => {
    navigator.clipboard.writeText(pdfLink).then(() => {
      alert("Le lien PDF a été copié dans le presse-papiers.");
    });
  };

  const deletePromote = async (promoteId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/promote/${promoteId}`
      );
      reFetchFromTable();
      console.log(`Promote ${promoteId} supprimée.`);
    } catch (err) {
      console.error(
        `Erreur lors de la suppression de la campagne Promote ${promoteId} :`,
        err.message
      );
    }
  };

  // Fonction pour supprimer un template spécifique sur Facebook
  const deleteTemplate = async (templateName) => {
    try {
      await axios.delete(
        `https://graph.facebook.com/v20.0/${process.env.REACT_APP_BUSINESS_ID}/message_templates?name=${templateName}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_FB}`,
          },
        }
      );
      console.log(`Template ${templateName} supprimé.`);
    } catch (err) {
      console.error(
        `Erreur lors de la suppression du template ${templateName}:`,
        err.message
      );
    }
  };

  const createMultipleTemplates = async (promoteId) => {
    const errors = [];
    const acceptedTemplates = [];

    setLoading(true); // Start loading

    try {
      for (const country of Object.keys(newPromote.messagesByCountry)) {
        const messageData = newPromote.messagesByCountry[country];
        const message = messageData?.message?.trim();
        const templateDetails = {
          name: messageData?.templateName,
          category: messageData?.category,
          language: messageData?.language,
        };

        if (!templateDetails.name) {
          errors.push({ country, error: "Nom du template manquant" });
          continue;
        }

        const placeholders = extractPlaceholders(message);
        if (placeholders.length > 0) {
          const exampleData = placeholders.map((placeholder, index) => ({
            placeholder,
            example:
              exampleValues[Object.keys(exampleValues)[index]] ||
              `Exemple manquant`,
          }));

          newPromote.messagesByCountry[country].placeholders = exampleData;
          const components = [
            {
              type: "BODY",
              text: message,
              example: { body_text: [exampleData.map((data) => data.example)] },
            },
          ];

          try {
            const response = await axios.post(
              `https://graph.facebook.com/v20.0/${process.env.REACT_APP_BUSINESS_ID}/message_templates`,
              {
                name: templateDetails.name,
                category: templateDetails.category,
                language: templateDetails.language,
                components,
              },
              {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_TOKEN_FB}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (response.data.status === "REJECTED") {
              console.error(`Template pour ${country} rejeté.`);
              errors.push({ country, templateName: templateDetails.name });
              await deletePromote(promoteId); // Delete the Promote campaign
              await deleteTemplate(templateDetails.name); // Delete the rejected template
              break;
            } else {
              acceptedTemplates.push({
                country,
                templateName: templateDetails.name,
              });
            }
          } catch (err) {
            errors.push({ country, error: err.response?.data || err.message });
          }
        }
      }

      setLoading(false); // End loading

      // Handling rejected templates
      if (errors.length > 0) {
        const rejectedTemplates = errors
          .map((err) => err.templateName)
          .join(", ");
        const accepted = acceptedTemplates
          .map((tmpl) => tmpl.templateName)
          .join(", ");
        setResultMessage(
          `Votre template: ${rejectedTemplates} a été refusé par Facebook, la campagne Promote a été supprimée. Merci de réessayer.`
        );
        return { success: false, errors };
      }

      // Handling successful creation
      const accepted = acceptedTemplates
        .map((tmpl) => tmpl.templateName)
        .join(", ");
      setResultMessage(
        `Les templates: ${accepted} ont été acceptés par Facebook, la campagne a été créée avec succès.`
      );
      return { success: true, errors };
    } catch (err) {
      await deletePromote(promoteId);
      setLoading(false);
      setResultMessage("Erreur inattendue lors de la création des templates.");
      return { success: false, errors: [err.message] };
    }
  };

  const handleCreatePromoteWithTemplates = async () => {
    const templateNames = getTemplateNamesFromTemplatesByCountry();

    if (!newPromote.project || !newPromote.name || templateNames.length === 0) {
      alert("Veuillez compléter toutes les informations avant de continuer.");
      return;
    }

    setLoading(true); // Start loading indicator
    setOpen(false); // Close the modal immediately

    const promoteId = await handleCreatePromote(templateNames);

    if (promoteId) {
      const { success, errors } = await createMultipleTemplates(promoteId);

      if (success) {
        reFetchFromTable();
        // Modal is already closed, no need to setOpen(false) again
      } else {
        console.error("Erreur lors de la création de templates.", errors);
      }
    } else {
      console.error("La création de la campagne Promote a échoué.");
    }

    setLoading(false); // End loading indicator
  };

  const handleCreatePromote = async (templateNames) => {
    try {
      const promoteData = {
        name: newPromote.name,
        project: newPromote.project,
        agence: newPromote.agence,
        messagesByCountry: newPromote.messagesByCountry,
        templateNames, // Utilisation des noms de templates capturés à la soumission
        status: "draft",
      };

      // Poster la campagne Promote et récupérer la réponse avec l'ID
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/promote/`,
        promoteData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const promoteId = response.data._id; // Récupérer l'ID du promote créé
      console.log("Campagne Promote créée avec succès, ID :", promoteId);

      return promoteId; // Retourner l'ID de la campagne
    } catch (err) {
      console.error(
        "Erreur lors de la création de la campagne Promote :",
        err.message
      );
      return null; // Retourner null si une erreur survient
    }
  };

  const getTemplateNamesFromTemplatesByCountry = () => {
    return Object.keys(newPromote.messagesByCountry)
      .map(
        (country) => newPromote.messagesByCountry[country]?.templateName || ""
      )
      .filter((templateName) => templateName !== ""); // Filtrer les noms vides
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}></div>

      <div className="createPromoteContainer">
        <div className="createPromoteContainerTop">
          <span>CREE UNE CAMPAGNE</span>
        </div>
        <div className="createPromote">
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createPromoteSidebar">
            <div className={`step ${step >= 1 ? "active" : ""}`}>
              <span className="step-number">1</span>
              <span className="step-label">Details</span>
              <div className={`step-bar ${step >= 2 ? "filled" : ""}`}></div>
            </div>
            <div className={`step ${step >= 2 ? "active" : ""}`}>
              <span className="step-number">2</span>
              <span className="step-label">Message</span>
              <div className={`step-bar ${step >= 3 ? "filled" : ""}`}></div>
            </div>
            <div className={`step ${step >= 3 ? "active" : ""}`}>
              <span className="step-number">3</span>
              <span className="step-label">Review & Save</span>
            </div>
          </div>

          <div className="createPromoteContent">
            {step === 1 && (
              <div className="stepContent">
                <div className="inputRowPromote">
                  <label>Nom</label>
                  <input type="text" name="name" onChange={handleChange} />
                </div>
                <div className="inputRowPromote">
                  <label>Sélectionner un projet</label>
                  <select name="project" onChange={handleChange}>
                    <option value="">Choisir un projet</option>
                    {projects.map((project) => (
                      <option key={project._id} value={project._id}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="inputRowPromote">
                  <label>Sélectionner des agences</label>
                  <div className="agencesListContainer">
                    <ul className="agencesList">
                      {allLeads
                        .filter((a) => a.signed.isSigned)
                        .map((agence) => (
                          <li
                            key={agence._id}
                            className={`agenceItem ${
                              newPromote.agence.includes(agence._id)
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => handleAgenceClick(agence._id)}
                          >
                            {agence.name} -{" "}
                            {getCountryNameByISO(agence.country)}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="stepContent">
                <div className="importantMessageContainer">
                  <span
                    className="importantMessage"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                  >
                    IMPORTANT : Lire avant de creer une template
                  </span>

                  {showTooltip && (
                    <div className="tooltip">
                      <ul>
                        <li>
                          Le nom du template ne doit pas contenir d'espaces ou
                          de majuscules
                        </li>
                        <li>Toujours rajouter au moins un placeholder.</li>
                        <li>
                          Utilisez une catégorie appropriée pour le template.
                        </li>
                        <li>Choisir la langue correspondantes</li>
                        <li>
                          Assurez-vous que le message ne contient pas de contenu
                          interdit (spam, publicité non autorisée, etc.).
                        </li>
                        <li>Nom du modele : 512 caractères.</li>
                        <li>
                          Le champ de contenu du modèle de message est limité à
                          1 024 caractères.
                        </li>
                        <li>
                          Les comptes WhatsApp Business peuvent créer un maximum
                          de 100 modèles de message par heure.
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                {Object.keys(groupedAgencesByCountry).map((country) => (
                  <div key={country} className="promote-countrySection">
                    <div className="promote-countryHeader">
                      <h3>{country}</h3>
                      <div className="promote-selectedAgences">
                        {groupedAgencesByCountry[country].map((agence) => (
                          <div
                            key={agence._id}
                            className="promote-selectedAgenceItem"
                          >
                            {agence.name}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="promote-inputRow">
                      <input
                        type="text"
                        name="templateName"
                        placeholder="Nom Template"
                        onChange={(e) => handleChangeTemplate(e, country)}
                        value={
                          newPromote.messagesByCountry[country]?.templateName ||
                          ""
                        }
                      />

                      <select
                        className="promote-select"
                        name="category"
                        onChange={(e) => handleChangeTemplate(e, country)}
                        value={
                          newPromote.messagesByCountry[country]?.category || ""
                        }
                      >
                        <option value="">Sélectionner une catégorie</option>
                        <option value="MARKETING">Marketing</option>
                        <option value="UTILITY">Utilitaire</option>
                        <option value="AUTHENTICATION">Authentification</option>
                      </select>

                      <select
                        className="promote-select"
                        name="language"
                        onChange={(e) => handleChangeTemplate(e, country)}
                        value={
                          newPromote.messagesByCountry[country]?.language || ""
                        }
                      >
                        <option value="">Sélectionner une langue</option>
                        <option value="fr">Français</option>
                        <option value="en_US">Anglais US</option>
                        <option value="es">Espagnol</option>
                      </select>

                      {/* Zone de texte pour le message spécifique au pays */}
                      <div className="charCountContainer">
                        <textarea
                          className="promote-textarea"
                          name={`message_${country}`}
                          onChange={(e) =>
                            handleCountryMessageChange(e, country)
                          }
                          value={
                            newPromote.messagesByCountry[country]?.message || ""
                          }
                          placeholder="Écrivez votre message ici..."
                        />
                        {/* Character count display */}
                        <div className="charCount">
                          {charCountByCountry[country] || 0}/1024
                        </div>
                      </div>

                      <div className="promote-placeholderInsert">
                        <label>Insérer un placeholder:</label>
                        <select
                          className="promote-select"
                          onChange={(e) =>
                            setSelectedPlaceholder(e.target.value)
                          }
                        >
                          <option value="">Sélectionner un placeholder</option>
                          <option value="clientName">Nom du Client</option>
                          <option value="clientEmail">Email du Client</option>
                          <option value="clientPhone">
                            Téléphone du Client
                          </option>
                          <option value="clientCountry">Pays du Client</option>
                        </select>
                        <button
                          className="promote-insertPlaceholderButton"
                          onClick={() => insertPlaceholder(country)}
                        >
                          Ajouter Placeholder{" "}
                          {"{{" +
                            (placeholderCounterByCountry[country] || 1) +
                            "}}"}
                        </button>
                      </div>
                      <div className="promote-pdfUpload">
                        <label>Générez un lien PDF :</label>
                        <input
                          type="file"
                          accept="application/pdf"
                          onChange={(e) => handlePdfUpload(e, country)}
                        />
                        {pdfLinkByCountry[country] && (
                          <div className="pdfLinkContainer">
                            <p>Lien PDF : {pdfLinkByCountry[country]}</p>
                            <button
                              onClick={() =>
                                handleCopyPdfLink(pdfLinkByCountry[country])
                              }
                            >
                              Copier le lien
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {step === 3 && (
              <div className=" reviewStep">
                <h3>Résumé & Sauvegarde</h3>
                <p>
                  Vérifiez attentivement les informations avant de confirmer.
                </p>

                <div className="reviewSection">
                  <div className="reviewItem">
                    <span className="reviewLabel">Nom:</span>
                    <span className="reviewValue">{newPromote.name}</span>
                  </div>

                  <div className="reviewItem">
                    <span className="reviewLabel">Projet:</span>
                    <span className="reviewValue">
                      {
                        projects.find(
                          (project) => project._id === newPromote.project
                        )?.name
                      }
                    </span>
                  </div>

                  <div className="reviewItem">
                    <span className="reviewLabel">Agences Sélectionnées:</span>
                    <ul className="reviewValue">
                      {newPromote.agence.map((agenceId) => {
                        const agence = allLeads.find((a) => a._id === agenceId);
                        return (
                          <li key={agenceId}>
                            {agence.name} ({agence.country})
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="reviewItem">
                    <span className="reviewLabel">Messages par Pays:</span>
                    <div className="reviewValue">
                      {Object.keys(groupedAgencesByCountry).map((country) => (
                        <div key={country} className="countryMessageReview">
                          <h4>{country}:</h4>
                          <p>{newPromote[`message_${country}`]}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="reviewItem">
                    <span className="reviewLabel">Fichier PDF:</span>
                    <span className="reviewValue">{pdfFileName}</span>
                  </div>
                </div>
              </div>
            )}

            <div className="bottomCreatePromote">
              {step > 1 && (
                <button className="previousButton" onClick={handlePreviousStep}>
                  Précédent
                </button>
              )}
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Annuler
              </button>
              {step < 3 ? (
                <button className="nextButton" onClick={handleNextStep}>
                  Suivant
                </button>
              ) : (
                <button
                  className="saveButton"
                  onClick={handleCreatePromoteWithTemplates}
                >
                  <CheckIcon />
                  Sauvegarder
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePromoteWithTemplate;
