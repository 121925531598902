import "./ClimbingBoxLoaderPopup.css"
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

const ClimbingBoxLoaderPopup = () => {
  return (
    <>
        <div className="overlay"></div>
      <div className="climbingBoxLoaderPopup">
        <div className="topClimbingBoxLoaderPopup">
          <ClimbingBoxLoader size={20} color="#36d7b7" cssOverride={{position: "relative", display: "flex"}}/>
        </div>
        <div className="bottomClimbingBoxLoaderPopup">
          <p>Assign in progress...</p>
        </div>
      </div>
    </>
  )
}

export default ClimbingBoxLoaderPopup