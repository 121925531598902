import "./CreateTemplate.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";

const CreateTemplate = ({ setOpen }) => {
  const [template, setTemplate] = useState({
    name: "",
    category: "",
    language: "",
    text: "",
    format: "",
    pdfFile: "",
  });
  const [pdfFileName, setPdfFileName] = useState("");
  const [fileType, setFileType] = useState(""); // Valeur par défaut pour le fichier
  const [fileLength, setFileLength] = useState(0);
  const [placeholderCounter, setPlaceholderCounter] = useState(1); // Pour suivre le nombre de placeholders
  const [selectedPlaceholder, setSelectedPlaceholder] = useState("");
  const [placeholdersData, setPlaceholdersData] = useState([]);

  // Exemple d'exemples de données pour les placeholders
  // Exemple de données pour chaque placeholder
  const exampleValues = {
    clientName: "Marc Jones",
    clientEmail: "marcjones@example.com",
    clientPhone: "+123456789",
    clientCountry: "USA",
  };

  const extractPlaceholders = (text) => {
    const regex = /\{\{\d+\}\}/g; // Regex pour détecter les placeholders
    return text.match(regex) || []; // Retourne un tableau avec les placeholders trouvés
  };

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTemplate((prev) => ({ ...prev, [name]: value }));
  };

  const createTemplate = async () => {
    try {
      console.log("Création du template avec placeholders...");

      const placeholders = extractPlaceholders(template.text);

      // Générer les exemples correspondant aux placeholders
      const exampleData = placeholders.map((_, index) => {
        const key = Object.keys(exampleValues)[index]; // Associe chaque placeholder à son exemple
        return exampleValues[key];
      });

      // Créer la structure du composant BODY
      const components = [
        {
          type: "BODY",
          text: template.text, // Le texte principal avec les placeholders
          example: {
            body_text: [exampleData], // Fournir un exemple de remplacement des placeholders
          },
        },
      ];

      console.log(components);

      const response = await axios.post(
        `https://graph.facebook.com/v20.0/${process.env.REACT_APP_BUSINESS_ID}/message_templates`,
        {
          name: template.name,
          category: template.category,
          language: template.language,

          components, // Utilisation des composants dynamiques
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_FB}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Template créé avec succès :", response.data);
      setOpen(false);
    } catch (err) {
      console.error(
        "Erreur lors de la création du template :",
        err.response?.data || err.message
      );
    }
  };

  const handlePdfUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTemplate((prev) => ({
        ...prev,
        pdfFile: file, // Stocker le fichier PDF sélectionné
      }));
      setPdfFileName(file.name); // Stocker le nom du fichier pour l'afficher
      setFileLength(file.size); // Stocker la taille du fichier
    }
  };

  const insertPlaceholder = () => {
    if (selectedPlaceholder) {
      const currentText = template.text || "";
      const newText = `${currentText}{{${placeholderCounter}}}`; // Ajouter {{1}}, {{2}}, etc.
      setTemplate((prev) => ({
        ...prev,
        text: newText,
      }));
      setPlaceholdersData((prev) => [...prev, selectedPlaceholder]); // Associer le placeholder sélectionné
      setPlaceholderCounter((prev) => prev + 1);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createTemplate" onClick={handleClickInside}>
          <div className="closeIconTemplate">
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </div>
          <div className="createTemplateWrapper">
            <div className="topCreateTemplate">
              <span>CREATE TEMPLATE</span>
            </div>
            <div className="middleCreateTemplate">
              <div className="createVenteContainerInput">
                <input
                  type="text"
                  name="name"
                  placeholder="Nom Template"
                  onChange={handleChange}
                  value={template.name}
                />
                <select
                  name="category"
                  onChange={handleChange}
                  value={template.category}
                >
                  <option value="">Select Categorie</option>
                  <option value="MARKETING">MARKETING</option>
                  <option value="UTILITY">UTILITY</option>
                  <option value="AUTHENTICATION">AUTHENTICATION</option>
                </select>
                <select
                  name="language"
                  onChange={handleChange}
                  value={template.language}
                >
                  <option value="">Select Langue</option>
                  <option value="fr_FR">Francais</option>
                  <option value="en_US">Anglais US</option>
                  <option value="es_ES">Espagnol</option>
                  <option value="el_GR">Grec</option>
                  <option value="he_IL">Hebreu</option>
                </select>
                {/* <select
                  name="document"
                  onChange={handleChange}
                  value={template.document}
                >
                  <option value="">Format du docuement</option>
                  <option value="IMAGE">Image</option>
                  <option value="VIDEO">Video</option>
                  <option value="DOCUMENT">Document</option>
                </select> */}

                <div className="pdfUploadSection">
                  <label htmlFor="pdfUploadInput" className="pdfUploadWrapper">
                    <input
                      type="file"
                      accept={fileType} // Adapter le type de fichier selon le type MIME sélectionné
                      onChange={handlePdfUpload}
                      className="pdfUploadInput"
                      id="pdfUploadInput"
                    />
                    <div className="pdfUploadIcon">
                      <UploadIcon />
                    </div>
                    <div className="pdfUploadText">
                      Cliquez ou glissez un fichier ici pour télécharger
                    </div>
                  </label>

                  {pdfFileName && (
                    <div className="pdfFileName">
                      <span>Fichier sélectionné : {pdfFileName}</span>
                    </div>
                  )}
                </div>
                <textarea
                  name="text"
                  placeholder="Entrez le contenu du template avec des placeholders comme {{1}}, {{2}}, etc."
                  onChange={handleChange}
                  value={template.text}
                ></textarea>

                {/* Sélecteur de placeholder */}
                <select
                  onChange={(e) => setSelectedPlaceholder(e.target.value)}
                >
                  <option value="">Sélectionner un placeholder</option>
                  <option value="clientName">Nom du Client</option>
                  <option value="clientEmail">Email du Client</option>
                  <option value="clientPhone">Téléphone du Client</option>
                  <option value="clientCountry">Pays du Client</option>
                </select>

                <button
                  className="insertPlaceholderButton"
                  onClick={insertPlaceholder}
                >
                  Ajouter Placeholder {"{{" + placeholderCounter + "}}"}{" "}
                </button>
              </div>
            </div>
            <div className="bottomCreateTemplate">
              <button
                className="cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="saveButton"
                onClick={(e) => {
                  e.stopPropagation();
                  createTemplate();
                }}
              >
                <CheckIcon />
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTemplate;
