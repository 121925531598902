const CountryListCode = [
  // Europe
  { code: "AL", name: "Albania" },
  { code: "AT", name: "Austria" },
  { code: "BE", name: "Belgium" },
  { code: "FR", name: "France" },
  { code: "DE", name: "Germany" },
  { code: "GR", name: "Greece" },
  { code: "IE", name: "Ireland" },
  { code: "IT", name: "Italy" },
  { code: "NL", name: "Netherlands" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "RU", name: "Russia" },
  { code: "ES", name: "Spain" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "GB", name: "United Kingdom" },
  { code: "LU", name: "Luxembourg" },
  { code: "TR", name: "Turkey" },
  { code: "IS", name: "Iceland" },
  { code: "DK", name: "Denmark" },
  { code: "NO", name: "Norway" },
  { code: "FI", name: "Finland" },

  // Middle East
  { code: "IL", name: "Israel" },
  { code: "LB", name: "Lebanon" },
  { code: "IR", name: "Iran" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "QA", name: "Qatar" },
  { code: "KW", name: "Kuwait" },
  { code: "OM", name: "Oman" },
  { code: "BH", name: "Bahrain" },

  // Africa
  { code: "DZ", name: "Algeria" },
  { code: "AO", name: "Angola" },
  { code: "BJ", name: "Benin" },
  { code: "BW", name: "Botswana" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "CM", name: "Cameroon" },
  { code: "CV", name: "Cape Verde" },
  { code: "CF", name: "Central African Republic" },
  { code: "TD", name: "Chad" },
  { code: "KM", name: "Comoros" },
  { code: "CG", name: "Congo - Brazzaville" },
  { code: "CD", name: "Congo - Kinshasa" },
  { code: "CI", name: "Côte d’Ivoire" },
  { code: "DJ", name: "Djibouti" },
  { code: "EG", name: "Egypt" },
  { code: "GQ", name: "Equatorial Guinea" },
  { code: "ER", name: "Eritrea" },
  { code: "SZ", name: "Eswatini" },
  { code: "ET", name: "Ethiopia" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambia" },
  { code: "GH", name: "Ghana" },
  { code: "GN", name: "Guinea" },
  { code: "GW", name: "Guinea-Bissau" },
  { code: "KE", name: "Kenya" },
  { code: "LS", name: "Lesotho" },
  { code: "LR", name: "Liberia" },
  { code: "LY", name: "Libya" },
  { code: "MG", name: "Madagascar" },
  { code: "MW", name: "Malawi" },
  { code: "ML", name: "Mali" },
  { code: "MR", name: "Mauritania" },
  { code: "MU", name: "Mauritius" },
  { code: "MA", name: "Morocco" },
  { code: "MZ", name: "Mozambique" },
  { code: "NA", name: "Namibia" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigeria" },
  { code: "RW", name: "Rwanda" },
  { code: "ST", name: "São Tomé and Príncipe" },
  { code: "SN", name: "Senegal" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SO", name: "Somalia" },
  { code: "ZA", name: "South Africa" },
  { code: "SS", name: "South Sudan" },
  { code: "SD", name: "Sudan" },
  { code: "TZ", name: "Tanzania" },
  { code: "TG", name: "Togo" },
  { code: "TN", name: "Tunisia" },
  { code: "UG", name: "Uganda" },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabwe" },

  // Asia
  { code: "AF", name: "Afghanistan" },
  { code: "AM", name: "Armenia" },
  { code: "AZ", name: "Azerbaijan" },
  { code: "BD", name: "Bangladesh" },
  { code: "BT", name: "Bhutan" },
  { code: "BN", name: "Brunei" },
  { code: "KH", name: "Cambodia" },
  { code: "CN", name: "China" },
  { code: "CY", name: "Cyprus" },
  { code: "GE", name: "Georgia" },
  { code: "IN", name: "India" },
  { code: "ID", name: "Indonesia" },
  { code: "JP", name: "Japan" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "KR", name: "South Korea" },
  { code: "MY", name: "Malaysia" },
  { code: "MV", name: "Maldives" },
  { code: "MN", name: "Mongolia" },
  { code: "MM", name: "Myanmar" },
  { code: "NP", name: "Nepal" },
  { code: "PK", name: "Pakistan" },
  { code: "PH", name: "Philippines" },
  { code: "SG", name: "Singapore" },
  { code: "LK", name: "Sri Lanka" },
  { code: "TH", name: "Thailand" },
  { code: "TJ", name: "Tajikistan" },
  { code: "TM", name: "Turkmenistan" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "VN", name: "Vietnam" },
  { code: "MO", name: "Macao" },

  // Oceania
  { code: "AU", name: "Australia" },
  { code: "FJ", name: "Fiji" },
  { code: "KI", name: "Kiribati" },
  { code: "MH", name: "Marshall Islands" },
  { code: "FM", name: "Micronesia" },
  { code: "NR", name: "Nauru" },
  { code: "NZ", name: "New Zealand" },
  { code: "PW", name: "Palau" },
  { code: "PG", name: "Papua New Guinea" },
  { code: "WS", name: "Samoa" },
  { code: "SB", name: "Solomon Islands" },
  { code: "TO", name: "Tonga" },
  { code: "TV", name: "Tuvalu" },
  { code: "VU", name: "Vanuatu" },

  // South America
  { code: "AR", name: "Argentina" },
  { code: "BR", name: "Brazil" },
  { code: "CL", name: "Chile" },
  { code: "CO", name: "Colombia" },
  { code: "PE", name: "Peru" },
  { code: "VE", name: "Venezuela" },

  // North America
  { code: "CA", name: "Canada" },
  { code: "MX", name: "Mexico" },
  { code: "US", name: "United States" },

  // Central America
  { code: "CR", name: "Costa Rica" },
  { code: "PA", name: "Panama" },
];

// Fonction pour obtenir la position d'un pays
const getCountryPosition = (country) => {
  const positions = {
    // Europe
    France: { lat: 48.8566, lng: 2.3522 },
    Germany: { lat: 52.52, lng: 13.405 },
    Italy: { lat: 41.9028, lng: 12.4964 },
    Spain: { lat: 40.4168, lng: -3.7038 },
    United_Kingdom: { lat: 51.5074, lng: -0.1278 },
    Switzerland: { lat: 46.8182, lng: 8.2275 },
    Netherlands: { lat: 52.3676, lng: 4.9041 },
    Belgium: { lat: 50.8503, lng: 4.3517 },
    Austria: { lat: 48.2082, lng: 16.3738 },
    Sweden: { lat: 59.3293, lng: 18.0686 },
    Norway: { lat: 59.9139, lng: 10.7522 },
    Denmark: { lat: 55.6761, lng: 12.5683 },
    Greece: { lat: 37.9838, lng: 23.7275 },
    Portugal: { lat: 38.7223, lng: -9.1393 },
    Poland: { lat: 52.2297, lng: 21.0122 },
    Russia: { lat: 55.7558, lng: 37.6173 },
    Ireland: { lat: 53.3498, lng: -6.2603 },
    Albania: { lat: 41.3275, lng: 19.8189 },
    Czech_Republic: { lat: 50.0755, lng: 14.4378 },
    Hungary: { lat: 47.4979, lng: 19.0402 },
    Finland: { lat: 60.1695, lng: 24.9355 },
    Bulgaria: { lat: 42.6977, lng: 23.3219 },
    Romania: { lat: 44.4268, lng: 26.1025 },
    Croatia: { lat: 45.815, lng: 15.9819 },
    Serbia: { lat: 44.7866, lng: 20.4489 },
    Slovakia: { lat: 48.1486, lng: 17.1077 },
    Slovenia: { lat: 46.0569, lng: 14.5058 },
    Ukraine: { lat: 50.4501, lng: 30.5234 },
    Latvia: { lat: 56.9496, lng: 24.1052 },
    Lithuania: { lat: 54.6872, lng: 25.2797 },
    Estonia: { lat: 59.437, lng: 24.7535 },
    Belarus: { lat: 53.9045, lng: 27.5615 },
    Bosnia_and_Herzegovina: { lat: 43.8563, lng: 18.4131 },
    Montenegro: { lat: 42.4304, lng: 19.2594 },
    North_Macedonia: { lat: 41.9981, lng: 21.4254 },
    Moldova: { lat: 47.0105, lng: 28.8638 },
    Luxembourg: { lat: 49.6117, lng: 6.1319 },
    Iceland: { lat: 64.1355, lng: -21.8954 },
    Malta: { lat: 35.8989, lng: 14.5146 },
    Cyprus: { lat: 35.1856, lng: 33.3823 },

    // North America
    United_States: { lat: 37.0902, lng: -95.7129 },
    Canada: { lat: 45.4215, lng: -75.6972 },
    Mexico: { lat: 19.4326, lng: -99.1332 },

    // Central America
    Costa_Rica: { lat: 9.7489, lng: -83.7534 },
    Panama: { lat: 8.538, lng: -80.7821 },

    // South America
    Argentina: { lat: -34.6037, lng: -58.3816 },
    Brazil: { lat: -23.5505, lng: -46.6333 },
    Chile: { lat: -33.4489, lng: -70.6693 },
    Colombia: { lat: 4.711, lng: -74.0721 },
    Peru: { lat: -12.0464, lng: -77.0428 },
    Venezuela: { lat: 10.4806, lng: -66.9036 },

    // Asia
    China: { lat: 39.9042, lng: 116.4074 },
    Japan: { lat: 35.6895, lng: 139.6917 },
    India: { lat: 28.6139, lng: 77.209 },
    South_Korea: { lat: 37.5665, lng: 126.978 },
    Israel: { lat: 31.7683, lng: 35.2137 },
    Saudi_Arabia: { lat: 24.7136, lng: 46.6753 },
    Turkey: { lat: 39.9334, lng: 32.8597 },
    Indonesia: { lat: -6.2088, lng: 106.8456 },
    Philippines: { lat: 14.5995, lng: 120.9842 },
    Thailand: { lat: 13.7563, lng: 100.5018 },

    // Africa
    South_Africa: { lat: -33.9249, lng: 18.4241 },
    Nigeria: { lat: 9.082, lng: 8.6753 },
    Kenya: { lat: -1.2864, lng: 36.8172 },
    Egypt: { lat: 30.0444, lng: 31.2357 },
    Morocco: { lat: 31.6295, lng: -7.9811 },
    Algeria: { lat: 36.7529, lng: 3.042 },

    // Oceania
    Australia: { lat: -33.8688, lng: 151.2093 },
    New_Zealand: { lat: -36.8485, lng: 174.7633 },

    // Middle East
    United_Arab_Emirates: { lat: 25.2048, lng: 55.2708 },
    Qatar: { lat: 25.276987, lng: 51.520008 },
  };

  return positions[country];
};

export { CountryListCode, getCountryPosition };
