import { useState } from "react";
import "./Clients.css";
import { countries } from "countries-list";
import DeleteMessage from "../../../../DeleteMessage/DeleteMessage";
import PopupClientContainer from "../../../../Ventes/Client/PopupClient/PopupClientContainer/PopupClientContainer";

const Clients = ({
  currentData,
  reFetchAllLead,
  leads,
  reFetchClients,
  clients,
  ventes,
}) => {
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [openPopupClient, setOpenPopupClient] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleNumberOfAppartements = (clientId) => {
    const findNumberOfAppartment = ventes.filter(
      (v) => v.client._id === clientId
    );
    return findNumberOfAppartment.length;
  };

  const handleTotalPayments = (clientId) => {
    const clientSales = ventes.filter((v) => v.client._id === clientId);
    const totalPayments = clientSales.reduce((acc, sale) => {
      const totalPaymentForApartment = sale.appartment.payment.reduce(
        (sum, payment) => sum + payment.amount,
        0
      );
      return acc + totalPaymentForApartment;
    }, 0);
    return totalPayments;
  };

  const handleTotalAppartmentValue = (clientId) => {
    const clientSales = ventes.filter((v) => v.client._id === clientId);
    const totalValue = clientSales.reduce((acc, sale) => {
      return acc + sale.appartment.price;
    }, 0);
    return totalValue;
  };

  const handleOpenDeleteMessage = (clientId) => {
    const findClientInVentes = ventes.some((v) => v.client._id === clientId);
    if (findClientInVentes) {
      alert(
        "Ce client est associée a une vente, veuillez d'abord supprimer la vente"
      );
    } else {
      setOpenDeleteMessage(true);
      setSelectedDeletedId(clientId);
    }
  };

  const handleOpenPopupClient = (item) => {
    setSelectedData(item);
    setOpenPopupClient(true);
  };

  return (
    <>
      <div className="containerDatatableHistory">
        <div className="datatableHistory">
          <table className="tableDataHistory">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Nom</div>
                </th>

                <th>
                  <div className="headerWithIcon">Appt</div>
                </th>
                <th>
                  <div className="headerWithIcon">Paye / CA</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>

                {/* <th>
                  <div className="headerWithIcon">Add</div>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {currentData &&
              currentData.client &&
              Array.isArray(currentData.client) &&
              currentData.client.length > 0 ? (
                currentData.client.map((item, index) => (
                  <tr>
                    <td onClick={() => handleOpenPopupClient(item, index)}>
                      <span>{item.name}</span>
                    </td>

                    <td>{handleNumberOfAppartements(item._id)}</td>
                    <td className="progressContainerVenteTd">
                      <div className="progressContainerVente">
                        {ventes
                          .filter((v) => v.client._id === item._id)
                          .flatMap((v) => v.appartment.payment)
                          .map((payment, index, array) => {
                            const percentage =
                              (payment.amount /
                                handleTotalAppartmentValue(item._id)) *
                              100;

                            let backgroundColor;
                            if (array.length === 1) {
                              backgroundColor = "#00B161";
                            } else {
                              const startColor = "#00B161";
                              const endColor = "#00C6FF";
                              const colorStep = index / (array.length - 1);
                              backgroundColor = `rgba(${Math.round(
                                (1 - colorStep) *
                                  parseInt(startColor.slice(1, 3), 16) +
                                  colorStep * parseInt(endColor.slice(1, 3), 16)
                              )}, ${Math.round(
                                (1 - colorStep) *
                                  parseInt(startColor.slice(3, 5), 16) +
                                  colorStep * parseInt(endColor.slice(3, 5), 16)
                              )}, ${Math.round(
                                (1 - colorStep) *
                                  parseInt(startColor.slice(5, 7), 16) +
                                  colorStep * parseInt(endColor.slice(5, 7), 16)
                              )}, 0.8)`;
                            }

                            return (
                              <div
                                key={index}
                                className="progressSegment"
                                style={{
                                  width: `${percentage}%`,
                                  backgroundColor,
                                }}
                              >
                                <div className="paymentTooltip">
                                  <span>
                                    {payment.amount.toLocaleString("en-US")} € -{" "}
                                    {new Date(
                                      payment.paymentDate
                                    ).toLocaleDateString()}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        <div className="progressPriceVente">
                          {handleTotalPayments(item._id).toLocaleString(
                            "en-US"
                          )}{" "}
                          € /{" "}
                          {handleTotalAppartmentValue(item._id).toLocaleString(
                            "en-US"
                          )}{" "}
                          €
                        </div>
                      </div>
                    </td>
                    {/* <td>
                      <span>{getCountryNameByISO(item.country)}</span>
                    </td> */}
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() =>
                          handleOpenDeleteMessage(item._id, item._id)
                        }
                      >
                        del
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {openDeleteMessage && (
          <DeleteMessage
            setOpen={setOpenDeleteMessage}
            reFetchFromTable={reFetchAllLead}
            endPoint={"client"}
            id={selectedDeletedId}
          />
        )}
        {openPopupClient && (
          <PopupClientContainer
            setOpen={setOpenPopupClient}
            data={selectedData}
            reFetchFromTable={reFetchAllLead}
            clients={clients}
            reFetchClients={reFetchClients}
          />
        )}
      </div>
    </>
  );
};

export default Clients;
