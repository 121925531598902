import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProviderWrapper } from "./context/auth.context";
import { OpenTabProvider } from "./context/OpenTabContext";
import { SocketProvider } from "./context/SocketContext";
import { DataProvider } from "./context/DataContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <SocketProvider>
      <AuthProviderWrapper>
        <DataProvider>
          <OpenTabProvider>
            <App />
          </OpenTabProvider>
        </DataProvider>
      </AuthProviderWrapper>
    </SocketProvider>
  </Router>
);
