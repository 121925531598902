import "./EditAppartment.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useEffect, useState } from "react";
import axios from "axios";
import { countries } from "countries-list";
import useFetch from "../../../../../hooks/useFetch";
import { useData } from "../../../../../context/DataContext";
const EditAppartment = ({ setOpen, reFetchFromTable, selectedData }) => {
  const { data: projects } = useFetch("project");
  const [annexeOptions, setAnnexeOptions] = useState([]);
  const [outsideOptions, setOutsideOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const { reFetchProjects } = useData();
  const [imagePreviews, setImagePreviews] = useState([]);
  const [accessibilityOptions, setAccessibilityOptions] = useState([]);
  const [imagesToRemove, setImagesToRemove] = useState([]); // track removed images
  const [images, setImages] = useState([]); // new images
  const [showBalconySurfaceInput, setShowBalconySurfaceInput] = useState(false);

  const [updatedAppartment, setUpdatedAppartment] = useState({
    reference: selectedData.reference || "",
    type: selectedData.type || "",
    surface: selectedData.surface || "",
    landArea: selectedData.landArea || "",
    rooms: selectedData.rooms || "",
    bedrooms: selectedData.bedrooms || "",
    bathrooms: selectedData.bathrooms || "",
    balconySurface: selectedData.balconySurface || "",
    price: selectedData.price || "",
  });

  useEffect(() => {
    if (selectedData.projects && selectedData.projects._id) {
        setSelectedProject(selectedData.projects._id);
    }
    if (selectedData.images && selectedData.images.length > 0) {
        const baseUrl = "http://localhost:5005/uploads/";
        const existingImageUrls = selectedData.images.map(
            (image) => `${baseUrl}${image}`
        );
        setImagePreviews(existingImageUrls);
    }

    // Set the initial options for annexe, outside, and accessibility
    if (selectedData) {
        setAnnexeOptions(selectedData.annexe || []);
        setOutsideOptions(selectedData.outside || []);
        setAccessibilityOptions(selectedData.accessibility || []);
        setShowBalconySurfaceInput(
            selectedData.outside?.includes("Terrasse/Balcon")
        );
    }
}, [selectedData]);


  // Images handler
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    // Convert the files to URLs for previews
    const filePreviews = files.map((file) => URL.createObjectURL(file));

    setImagePreviews((prevPreviews) => [...prevPreviews, ...filePreviews]);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const wrapperStyle =
    images.length > 0
      ? "imageUploadSectionWrapper start"
      : "imageUploadSectionWrapper";

  const handleRemoveImage = (index) => {
    const newImagePreviews = [...imagePreviews];

    if (index < selectedData.images.length) {
      // If it's an existing image, mark it for removal
      setImagesToRemove([...imagesToRemove, selectedData.images[index]]);
    } else {
      // If it's a new image, remove it from the images array
      const newImages = [...images];
      newImages.splice(index - selectedData.images.length, 1);
      setImages(newImages);
    }

    newImagePreviews.splice(index, 1);
    setImagePreviews(newImagePreviews);
  };

  const handleProjectChange = (event) => {
    const projectId = event.target.value;
    setSelectedProject(projectId); // Mettez simplement à jour l'ID du projet sélectionné
};

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUpdatedAppartment((prevApartment) => ({
      ...prevApartment,
      [name]: value,
    }));
  };

  const handleEditAppartment = async () => {
    try {
        const updatedData = {
            ...updatedAppartment,
            projects: selectedProject, // Inclure l'ID du projet sélectionné
        };

        await axios.put(
            `${process.env.REACT_APP_SERVER_URL}/api/apartment/${selectedData._id}`,
            {
                appartment: JSON.stringify(updatedData)
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        reFetchFromTable();
        setOpen(false);
    } catch (err) {
        console.error(err);
    }
};


  const handleAnnexeOptionChange = (option) => {
    const updatedOptions = annexeOptions.includes(option)
      ? annexeOptions.filter((item) => item !== option)
      : [...annexeOptions, option];

    setAnnexeOptions(updatedOptions);
    setUpdatedAppartment((prev) => ({ ...prev, annexe: updatedOptions }));
  };

  const handleOutsideOptionChange = (option) => {
    const updatedOptions = outsideOptions.includes(option)
      ? outsideOptions.filter((item) => item !== option)
      : [...outsideOptions, option];

    setOutsideOptions(updatedOptions);
    setUpdatedAppartment((prev) => ({ ...prev, outside: updatedOptions }));

    if (option === "Terrasse/Balcon") {
      setShowBalconySurfaceInput(!showBalconySurfaceInput);
    }
  };

  const handleAccessibilityOptionChange = (option) => {
    const updatedOptions = accessibilityOptions.includes(option)
      ? accessibilityOptions.filter((item) => item !== option)
      : [...accessibilityOptions, option];

    setAccessibilityOptions(updatedOptions);
    setUpdatedAppartment((prev) => ({
      ...prev,
      accessibility: updatedOptions,
    }));
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}></div>
      <div className="createApartment">
        <div className="closeIconStatus">
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        <div className="topEditAppartment">
          <span>Edit Apartment</span>
        </div>
        <div className="createApartmentWrapper">
          <div className="middleEditAppartment">
            <div className="imageUploadSection">
              <div className={wrapperStyle}>
                {imagePreviews.map((preview, index) => (
                  <div className="uploadedImageContainer" key={index}>
                    <div
                      className="imageHoverOverlay"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <DeleteIcon className="deleteIcon" />
                    </div>
                    <img src={preview} alt={`Uploaded ${index}`} />{" "}
                    {/* Ensure alt text is descriptive */}
                  </div>
                ))}
                <div className="buttonUploadImage">
                  <label htmlFor="image-upload" className="uploadButton">
                    <CloudDownloadIcon />
                    <span>Upload images</span>
                  </label>
                  <input
                    type="file"
                    id="image-upload"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>

            <div className="inputRow">
              <label>Reference</label>
              <input
                type="text"
                name="reference"
                value={updatedAppartment.reference}
                onChange={handleChange}
              />
              <label>Type</label>
              <select
                name="type"
                value={updatedAppartment.type}
                onChange={handleChange}
              >
                <option value="Apartment">Apartment</option>
                <option value="Local">Local</option>
                <option value="Loft">Loft</option>
                <option value="Home">Home</option>
                <option value="Studio">Studio</option>
              </select>
            </div>
            <div className="inputRow">
              <label>Surface</label>
              <input
                type="number"
                name="surface"
                value={updatedAppartment.surface}
                onChange={handleChange}
              />
              <label>Land Area</label>
              <input
                type="number"
                name="landArea"
                value={updatedAppartment.landArea}
                onChange={handleChange}
              />
            </div>
            <div className="inputRow">
              <label>Rooms</label>
              <input
                type="number"
                name="rooms"
                value={updatedAppartment.rooms}
                onChange={handleChange}
              />
              <label>Bedrooms</label>
              <input
                type="number"
                name="bedrooms"
                value={updatedAppartment.bedrooms}
                onChange={handleChange}
              />
            </div>
            <div className="inputRow">
              <label>Bathrooms</label>
              <input
                type="number"
                name="bathrooms"
                value={updatedAppartment.bathrooms}
                onChange={handleChange}
              />
              <label>Floors</label>
              <input
                type="number"
                name="floor"
                value={updatedAppartment.floor}
                onChange={handleChange}
              />
            </div>
            {showBalconySurfaceInput && (
              <div className="inputRow">
                <label>Balcony Surface</label>
                <input
                  type="number"
                  name="balconySurface"
                  value={updatedAppartment.balconySurface}
                  onChange={handleChange}
                />
              </div>
            )}

            {/* Checkbox */}
            <div className="checkboxSection">
              <div className="checkboxGroup">
                <label className="checkboxTitle">Annexe:</label>
                <div className="checkboxOptions">
                  {["Box", "Cave", "Garage", "Parking", "Sous-sol"].map(
                    (option) => (
                      <div key={option} className="checkboxItem">
                        <input
                          type="checkbox"
                          checked={annexeOptions.includes(option)}
                          onChange={() => handleAnnexeOptionChange(option)}
                        />
                        <label>{option}</label>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className="checkboxGroup">
                <label className="checkboxTitle">Accessibility:</label>
                <div className="checkboxOptions">
                  {["Accès Handicapé", "Ascenseur"].map((option) => (
                    <div key={option} className="checkboxItem">
                      <input
                        type="checkbox"
                        checked={accessibilityOptions.includes(option)}
                        onChange={() => handleAccessibilityOptionChange(option)}
                      />
                      <label>{option}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="checkboxGroup">
                <label className="checkboxTitle">Outside:</label>
                <div className="checkboxOptions">
                  {["Jardin", "Piscine", "Terrasse/Balcon"].map((option) => (
                    <div key={option} className="checkboxItem">
                      <input
                        type="checkbox"
                        checked={outsideOptions.includes(option)}
                        onChange={() => handleOutsideOptionChange(option)}
                      />
                      <label>{option}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="inputRow">
            <label>Price</label>
            <input
              type="number"
              name="price"
              value={updatedAppartment.price}
              onChange={handleChange}
            />
          </div>
          <div className="bottomTitleEditAppartment">
            <span>Assign to a project</span>
          </div>

          <div className="inputRowBottom">
            <label>Select Project</label>
            <select
              name="project"
              value={selectedProject || "default"}
              onChange={handleProjectChange}
            >
              <option value="default" disabled>
                Select
              </option>
              {projects.map((project) => (
                <option key={project._id} value={project._id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="bottomEditAppartment">
          <button className="cancelButton" onClick={() => setOpen(false)}>
            Cancel
          </button>
          <button className="saveButton" onClick={handleEditAppartment}>
            <CheckIcon />
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default EditAppartment;
