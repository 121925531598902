import { useEffect, useState } from "react";
import "./Vente.css";
import DeleteMessage from "../../../../Vente/DeleteMessage/DeleteMessage";
import { useData } from "../../../../../../context/DataContext";
import AddPaymentPopup from "../../../../Vente/AddPaymentPopup/AddPaymentPopup";
import EditAppartment from "../../../../../Projet/Appartment/AppartmentTable/EditAppartment/EditAppartment";

const Vente = ({ currentData, reFetchAllLead, ventes, refetchVentes }) => {
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [openPopupPayment, setOpenPopupPayment] = useState(false);
  const { paymentsType } = useData();
  const [openPopUpEditAppartment, setOpenPopUpEditAppartment] = useState(false);

  const updatePayments = (updatedPayments) => {
    setSelectedData((prevData) => {
      const updatedAppartment = {
        ...prevData.appartment,
        payment: updatedPayments,
      };

      const updatedItem = { ...prevData, appartment: updatedAppartment };

      // Update the main data table as well
      const updatedData = ventes.map((item) =>
        item._id === selectedData._id ? updatedItem : item
      );
      refetchVentes(updatedData);

      return updatedItem;
    });
  };

  const findCorrespondingVentes = ventes.filter(
    (v) => v.client._id === currentData._id
  );

  const handleOpenDeleteMessage = (vente) => {
    if (vente.appartment.payment.length > 0) {
      alert(
        "Vous devez d'abord effacer tous les paiements avant de supprimer cette vente."
      );
    } else {
      setOpenDeleteMessage(true);
      setSelectedDeletedId(vente);
    }
  };

  const handleOpenPopupPaiement = (item) => {
    setSelectedData(item);
    setOpenPopupPayment(true);
  };

  const handleOpenPopuEditAppartment = (data) => {
    setSelectedData(data);
    setOpenPopUpEditAppartment(true);
  };

  return (
    <>
      <div className="containerDatatableHistory">
        <div className="datatableHistory">
          <table className="tableDataHistory">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Projets</div>
                </th>
                <th>
                  <div className="headerWithIcon">Appt</div>
                </th>

                <th>
                  <div className="headerWithIcon">Montant</div>
                </th>
                {/* <th>
                  <div className="headerWithIcon">Date</div>
                </th> */}

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {findCorrespondingVentes && findCorrespondingVentes.length > 0 ? (
                findCorrespondingVentes.map((item, index) => (
                  <tr>
                    <td>
                      <span>{item.project.name}</span>
                    </td>
                    <td
                      onClick={() =>
                        handleOpenPopuEditAppartment(item.appartment)
                      }
                    >
                      <span>{item.appartment.reference} </span>
                    </td>

                    <td
                      className="progressContainerVenteTd"
                      onClick={() => handleOpenPopupPaiement(item, index)}
                    >
                      <div className="progressContainerVente">
                        {item.appartment.payment.map(
                          (payment, index, array) => {
                            const percentage =
                              (payment.amount / item.appartment.price) * 100;

                            let backgroundColor;
                            if (array.length === 1) {
                              // Si un seul paiement, appliquer la couleur de départ
                              backgroundColor = "#00B161";
                            } else {
                              const startColor = "#00B161";
                              const endColor = "#00C6FF";
                              const colorStep = index / (array.length - 1);
                              backgroundColor = `rgba(${Math.round(
                                (1 - colorStep) *
                                  parseInt(startColor.slice(1, 3), 16) +
                                  colorStep * parseInt(endColor.slice(1, 3), 16)
                              )}, ${Math.round(
                                (1 - colorStep) *
                                  parseInt(startColor.slice(3, 5), 16) +
                                  colorStep * parseInt(endColor.slice(3, 5), 16)
                              )}, ${Math.round(
                                (1 - colorStep) *
                                  parseInt(startColor.slice(5, 7), 16) +
                                  colorStep * parseInt(endColor.slice(5, 7), 16)
                              )}, 0.8)`;
                            }

                            return (
                              <div
                                key={index}
                                className="progressSegment"
                                style={{
                                  width: `${percentage}%`,
                                  backgroundColor,
                                }}
                              >
                                <div className="paymentTooltip">
                                  <span>
                                    {payment.amount.toLocaleString("en-US")} € -{" "}
                                    {new Date(
                                      payment.paymentDate
                                    ).toLocaleDateString()}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )}
                        <div className="progressPriceVente">
                          {item.appartment.payment
                            .reduce((acc, payment) => acc + payment.amount, 0)
                            .toLocaleString("en-US")}{" "}
                          € / {item.appartment.price.toLocaleString("en-US")} €
                        </div>
                      </div>
                    </td>
                    {/* <td>
                      <span>{moment(item.createdAt).format("DD/MM/YYYY")}</span>
                    </td> */}
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleOpenDeleteMessage(item)}
                      >
                        del
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {openDeleteMessage && (
          <DeleteMessage
            setOpen={setOpenDeleteMessage}
            reFetchFromTable={refetchVentes}
            id={selectedDeletedId}
          />
        )}
        {openPopupPayment && (
          <AddPaymentPopup
            setOpen={setOpenPopupPayment}
            paymentsType={paymentsType}
            currentData={selectedData}
            updatePayments={updatePayments}
          />
        )}
        {openPopUpEditAppartment && (
          <EditAppartment
            setOpen={setOpenPopUpEditAppartment}
            reFetchFromTable={reFetchAllLead}
            selectedData={selectedData}
          />
        )}
      </div>
    </>
  );
};

export default Vente;
