import "./Dashboard.css";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ModeIcon from "@mui/icons-material/Mode";
import Comment from "../Comment/Comment";
import "react-circular-progressbar/dist/styles.css";
import BalanceIcon from "@mui/icons-material/Balance";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useContext, useState } from "react";
import { useData } from "../../../../../../context/DataContext";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { AuthContext } from "../../../../../../context/auth.context";
import Vente from "../Vente/Vente";
import Paiement from "../Paiement/Paiement";
const Dashboard = ({ currentData, reFetchFromTable, reFetchClients }) => {
  const [activeTab, setActiveTab] = useState("Ventes");
  const { user } = useContext(AuthContext);
  const { ventes, clients, refetchVentes } = useData();

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const venteAssignedToClient = ventes.filter(
    (v) => v.client._id === currentData._id
  );

  const totalApartmentValue = venteAssignedToClient.reduce((sum, vente) => {
    if (vente.appartment && vente.appartment.price) {
      return sum + vente.appartment.price;
    }
    return sum;
  }, 0);

  // Calculate total payments made for the apartments by the client
  const totalPaymentsMade = venteAssignedToClient.reduce((sum, vente) => {
    if (
      vente.appartment &&
      vente.appartment.payment &&
      vente.appartment.payment.length > 0
    ) {
      return (
        sum +
        vente.appartment.payment.reduce((paymentSum, payment) => {
          return paymentSum + payment.amount;
        }, 0)
      );
    }
    return sum;
  }, 0);

  const allPaymentsForClient = venteAssignedToClient.flatMap((vente) => {
    return vente.appartment.payment.filter(
      (payment) => payment.client._id === currentData._id
    );
  });

  return (
    <>
      <div className="popupRigthSideBottomLeft">
        <div className="popupRigthSideBottomLeftTop">
          <div className="statsWrapper">
            <div className="statsWrapperPerTwo">
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTop">
                  <PermIdentityIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>Appartements</span>
                  <span>{venteAssignedToClient.length}</span>
                </div>
              </div>
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTop">
                  <LocalOfferIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>Ventes</span>
                  <span>{venteAssignedToClient.length}</span>
                </div>
              </div>
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTop">
                  <CreditCardIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>CA</span>
                  <span>{totalApartmentValue.toLocaleString("en-US")} €</span>
                </div>
              </div>
            </div>
            <div className="statsWrapperPerTwo">
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTopAbsolute">
                  <CurrencyExchangeIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>Payé / Total</span>
                  <div className="progressComparisonCommission">
                    <span>
                      {totalPaymentsMade.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      € /{" "}
                      {totalApartmentValue.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      €
                    </span>
                  </div>
                  <div className="progressContainerCommissionTd">
                    <div className="progressContainerCommission">
                      {venteAssignedToClient.flatMap((vente) =>
                        vente.appartment.payment.map(
                          (payment, index, array) => {
                            const percentage =
                              (payment.amount / totalApartmentValue) * 100;

                            const startColor = "#00B161";
                            const endColor = "#00C6FF";
                            const colorStep = index / (array.length - 1);
                            const backgroundColor = `rgba(${Math.round(
                              (1 - colorStep) *
                                parseInt(startColor.slice(1, 3), 16) +
                                colorStep * parseInt(endColor.slice(1, 3), 16)
                            )}, ${Math.round(
                              (1 - colorStep) *
                                parseInt(startColor.slice(3, 5), 16) +
                                colorStep * parseInt(endColor.slice(3, 5), 16)
                            )}, ${Math.round(
                              (1 - colorStep) *
                                parseInt(startColor.slice(5, 7), 16) +
                                colorStep * parseInt(endColor.slice(5, 7), 16)
                            )}, 0.8)`;

                            return (
                              <div
                                key={payment._id}
                                className="progressSegmentCommission"
                                style={{
                                  width: `${percentage}%`,
                                  backgroundColor,
                                }}
                              >
                                <div className="paymentTooltipCommission">
                                  <span>
                                    {payment.amount.toLocaleString("en-US")} € -{" "}
                                    {new Date(
                                      payment.paymentDate
                                    ).toLocaleDateString()}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popupTopNav">
          <div className="popupRigthSideTop">
            <div
              className={`popupRigthSideTopItem ${
                activeTab === "Ventes" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Ventes")}
            >
              <span>Ventes</span>
            </div>
            <div
              className={`popupRigthSideTopItem ${
                activeTab === "Paiement" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Paiement")}
            >
              <span>Paiement</span>
            </div>
          </div>
        </div>
        <div className="popupRigthSideBottomLeftBottom">
          {activeTab === "Ventes" && (
            <Vente
              currentData={currentData}
              reFetchFromTable={reFetchFromTable}
              ventes={ventes}
              refetchVentes={refetchVentes}
              reFetchClients={reFetchClients}
            />
          )}
          {activeTab === "Paiement" && (
            <Paiement
              currentData={currentData}
              reFetchFromTable={reFetchFromTable}
              payments={allPaymentsForClient}
            />
          )}
        </div>
      </div>
      <div className="popupRigthSideBottomRight">
        <div className="iconWithTitle">
          <ModeIcon />
          <span>COMMENTS</span>
        </div>
        <Comment
          currentData={currentData}
          reFetchFromTable={reFetchFromTable}
          clients={clients}
        />
      </div>
    </>
  );
};

export default Dashboard;
