import "./CommentPopup.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { DateRangePicker } from "react-date-range";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { AuthContext } from "../../../../context/auth.context";
import moment from "moment";
import CommentDeleteMessage from "../CommentDeleteMessage/CommentDeleteMessage";
import useFetch from "../../../../hooks/useFetch";
import { useSocket } from "../../../../context/SocketContext";

const CommentPopup = ({ setOpen, reFetchFromTable, data }) => {
  const { user } = useContext(AuthContext);
  const { data: leads, reFetch } = useFetch("lead");
  const [comments, setComments] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [time, setTime] = useState("12:00");
  const [showPicker, setShowPicker] = useState(false);
  const [dateRange, setDateRange] = useState([
    { startDate: new Date(), endDate: new Date(), key: "selection" },
  ]);
  const [newComment, setNewComment] = useState({
    author: "",
    content: "",
  });
  const [isDateTimeChanged, setIsDateTimeChanged] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const { emitRefreshData } = useSocket();
  const pickerRef = useRef();

  useEffect(() => {
    if (data) {
      setComments(data.comment);
    }
  }, [leads, data._id]);

  const handleOpenDeleteMessage = (comId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId(comId);
  };

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleSelectDate = (ranges) => {
    const newDate = ranges.selection.startDate;
    setDateRange([ranges.selection]);
    setAppointmentDate(newDate);
    setShowPicker(false);
    setIsDateTimeChanged(true);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
    setIsDateTimeChanged(true);
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewComment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateComment = async (e) => {
    e.preventDefault();

    try {
      const authorInfo = {
        _id: user._id,
        name: user.name,
        pPicture: user.pPicture,
      };

      const appointmentDateTime = new Date(appointmentDate);
      appointmentDateTime.setHours(parseInt(time.split(":")[0]));
      appointmentDateTime.setMinutes(parseInt(time.split(":")[1]));

      const commentToAdd = {
        author: authorInfo,
        content: newComment.content,
        createdAt: new Date(),
        appointment: isDateTimeChanged
          ? { date: appointmentDateTime, description: "RDV associé" }
          : undefined,
      };
      const updatedComments = [...comments, commentToAdd];
      setComments(updatedComments);

      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/${data._id}`,
        { comment: updatedComments }
      );

      if (response.data) {
        emitRefreshData();
      }

      // reFetch();
      reFetchFromTable();
      setNewComment({ content: "" });
      setAppointmentDate(new Date());
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="commentPopup" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="commentPopupWrapper">
            <div className="topCommentPopup">
              <span>Comments</span>
            </div>
            <div className="middleCommentPopup">
              <textarea
                name="content"
                value={newComment.content}
                onChange={handleChange}
                cols="40"
                rows="5"
                placeholder="Comments"
              ></textarea>
            </div>
            <div className="planComment">
              <span>Plan your comment</span>
              <div className="planCommentWrapper">
                <div className="planCommentWrapperContainer">
                  <div
                    onClick={() => setShowPicker(true)}
                    className="datePickerTrigger"
                  >
                    {formatDate(dateRange[0].startDate)} {time}
                  </div>
                  {showPicker && (
                    <div className="datePickerContainer" ref={pickerRef}>
                      <DateRangePicker
                        onChange={handleSelectDate}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={dateRange}
                        direction="horizontal"
                      />
                    </div>
                  )}
                  <input type="time" value={time} onChange={handleTimeChange} />
                </div>
                <div className="planCommentButton">
                  <button className="saveButton" onClick={handleCreateComment}>
                    <CheckIcon />
                    Save
                  </button>
                </div>
              </div>
            </div>

            <div className="commentAreaPopUp">
              <div className="commentAreaWrapper">
                <div className="commentAreaContainer">
                  {comments.map((comment, index) => (
                    <div className="singleComment" key={index}>
                      <div className="commentNameAndpic">
                        <img
                          src={
                            comment.author.pPicture || `/images/defaultImg.png`
                          }
                          alt={comment.author.pPicture}
                        />
                        <span className="commentAuthor">
                          {comment.author.name}
                        </span>
                      </div>
                      <span className="commentDate">
                        {new Date(comment.createdAt).toLocaleString()}
                      </span>
                      <span className="commentText">{comment.content}</span>
                      <div className="commentDelete">
                        <span
                          className="deleteLink"
                          onClick={() => handleOpenDeleteMessage(comment._id)}
                        >
                          del
                        </span>
                      </div>
                      {comment.appointment && (
                        <div className="appointmentInComment">
                          <QueryBuilderIcon />
                          <span>
                            {moment(comment.appointment.date).format(
                              "DD/MM/YY HH:mm"
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                  {openDeleteMessage && (
                    <CommentDeleteMessage
                      setOpen={setOpenDeleteMessage}
                      reFetchFromTable={reFetchFromTable}
                      id={selectedDeletedId}
                      data={data}
                      comments={comments}
                      setComments={setComments}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentPopup;
