import "./ClientTable.css";
import { handleCheckboxChange } from "../../../../utils/datatable.utils";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import AddIcon from "@mui/icons-material/Add";
import CreateSingleClient from "../CreateSingleClient/CreateSingleClient";
import DeleteMessage from "../../../DeleteMessage/DeleteMessage";
import PopupClientContainer from "../PopupClient/PopupClientContainer/PopupClientContainer";
import { useData } from "../../../../context/DataContext";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { countries } from "countries-list";
import CommentPopup from "../CommentPopup/CommentPopup";

const ClientTable = ({
  data,
  reFetch,
  setSelectedFilters,
  selectedFilters,
  setCurrentPage,
  currentPage,
  setPage,
}) => {
  const [perPage, setPerPage] = useState(50);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [openPopupClient, setOpenPopupClient] = useState(false);
  const { clients, ventes, reFetchClients } = useData();
  const tableRef = useRef(null);
  const [openCommentPopUp, setOpenCommentPopUp] = useState(null);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };
  const selectCurrentPage = () => {
    const start = currentPage * perPage;
    const end = start + perPage;
    const currentPageItems = data.slice(start, end).map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = () => {
    const allItems = data.map((item) => item._id);
    setCheckedItems(allItems);
  };

  const handleOpenDeleteMessage = (clientId) => {
    const findClientInVentes = ventes.some((v) => v.client._id === clientId);
    if (findClientInVentes) {
      alert(
        "Ce client est associée a une vente, veuillez d'abord supprimer la vente"
      );
    } else {
      setOpenDeleteMessage(true);
      setSelectedDeletedId(clientId);
    }
  };

  const handleOpenPopupClient = (data) => {
    setSelectedData(data);
    setOpenPopupClient(true);
  };

  const handleNumberOfAppartements = (clientId) => {
    const findNumberOfAppartment = ventes.filter(
      (v) => v.client._id === clientId
    );
    return findNumberOfAppartment.length;
  };

  const handleTotalPayments = (clientId) => {
    const clientSales = ventes.filter((v) => v.client._id === clientId);
    const totalPayments = clientSales.reduce((acc, sale) => {
      const totalPaymentForApartment = sale.appartment.payment.reduce(
        (sum, payment) => sum + payment.amount,
        0
      );
      return acc + totalPaymentForApartment;
    }, 0);
    return totalPayments;
  };

  const handleTotalAppartmentValue = (clientId) => {
    const clientSales = ventes.filter((v) => v.client._id === clientId);
    const totalValue = clientSales.reduce((acc, sale) => {
      return acc + sale.appartment.price;
    }, 0);
    return totalValue;
  };

  const handleCountryFilterChange = (e) => {
    setSelectedFilters((filters) => ({ ...filters, country: e.target.value }));
    setPage(1);
  };

  // Get unique countries from clients
  const clientCountries = [...new Set(clients.map((client) => client.country))];

  // Filter available countries based on the clients
  const availableCountries = Object.keys(countries)
    .filter((code) => clientCountries.includes(code))
    .map((code) => ({
      code: code,
      name: countries[code]?.name,
    }));

  const sortedAvailableCountries = availableCountries.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  // Calculer le CA total pour les clients filtrés
  const totalCA = data.reduce((sum, client) => {
    // Trouver toutes les ventes (ventes) associées à ce client
    const salesForClient = ventes.filter(
      (vente) =>
        vente.client && vente.client._id.toString() === client._id.toString()
    );

    // Additionner les prix de tous les appartements vendus à ce client
    const totalSalesValueForClient = salesForClient.reduce(
      (clientSum, vente) => {
        if (vente.appartment && vente.appartment.price) {
          return clientSum + vente.appartment.price;
        }
        return clientSum;
      },
      0
    );

    return sum + totalSalesValueForClient;
  }, 0);

  // Calculer le montant total payé pour les clients filtrés
  const totalPaid = data.reduce((sum, client) => {
    // Trouver toutes les ventes (ventes) associées à ce client
    const salesForClient = ventes.filter(
      (vente) =>
        vente.client && vente.client._id.toString() === client._id.toString()
    );

    // Additionner tous les montants payés pour ce client
    const totalPaidForClient = salesForClient.reduce((clientSum, vente) => {
      if (vente.appartment && vente.appartment.payment) {
        return (
          clientSum +
          vente.appartment.payment.reduce(
            (paymentSum, payment) => paymentSum + payment.amount,
            0
          )
        );
      }
      return clientSum;
    }, 0);

    return sum + totalPaidForClient;
  }, 0);

  function getCountryNameByISO(code) {
    return countries[code]?.name || code;
  }

  const handleReferenceClick = (data) => {
    setSelectedData(data);
    setOpenCommentPopUp(true);
  };

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>
                Clients : {data.length}&nbsp;&nbsp;-&nbsp;&nbsp;CA :{" "}
                {totalCA.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                €&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Payé :{" "}
                {totalPaid.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                €
              </span>{" "}
              <div className="selectWithArrow">
                <UnfoldMoreIcon
                  className="selectWithArrowIcon"
                  style={{
                    fontSize: "13px",
                    color:
                      selectedFilters.country !== "All Countries"
                        ? "var(--primary-color)"
                        : "gray",
                  }}
                />
                <select
                  value={selectedFilters.country}
                  onChange={handleCountryFilterChange}
                  className={
                    selectedFilters.country !== "All Countries"
                      ? "greenBackgroundAfterSelect"
                      : ""
                  }
                >
                  <option value="All Countries">All Countries</option>
                  {sortedAvailableCountries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="rightElements">
              <button onClick={() => setOpenCreateClient(true)}>
                <AddIcon />
                <span>Crée Client</span>
              </button>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />

                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">Nom</div>
                </th>
                <th>
                  <div className="headerWithIcon">Telephone</div>
                </th>

                <th>
                  <div className="headerWithIcon">Appartements</div>
                </th>
                <th>
                  <div className="headerWithIcon">Payé / CA</div>
                </th>

                <th>
                  <div className="headerWithIcon">Pays</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <label className="container">
                      <input
                        type="checkbox"
                        checked={checkedItems.includes(item._id)}
                        onChange={(e) =>
                          handleCheckboxChangeWrapper(e, item._id)
                        }
                      />
                      <div className="checkmark"></div>
                    </label>
                  </td>

                  <td onClick={() => handleOpenPopupClient(item, index)}>
                    <div className="nameAndStatus">
                      <span style={{ color: "#00B161" }}>{item.name}</span>
                    </div>
                  </td>

                  <td>{item.phone}</td>

                  <td>{handleNumberOfAppartements(item._id)}</td>

                  <td className="progressContainerVenteTd">
                    <div className="progressContainerVente">
                      {ventes
                        .filter((v) => v.client._id === item._id)
                        .flatMap((v) => v.appartment.payment)
                        .map((payment, index, array) => {
                          const percentage =
                            (payment.amount /
                              handleTotalAppartmentValue(item._id)) *
                            100;

                          let backgroundColor;
                          if (array.length === 1) {
                            backgroundColor = "#00B161";
                          } else {
                            const startColor = "#00B161";
                            const endColor = "#00C6FF";
                            const colorStep = index / (array.length - 1);
                            backgroundColor = `rgba(${Math.round(
                              (1 - colorStep) *
                                parseInt(startColor.slice(1, 3), 16) +
                                colorStep * parseInt(endColor.slice(1, 3), 16)
                            )}, ${Math.round(
                              (1 - colorStep) *
                                parseInt(startColor.slice(3, 5), 16) +
                                colorStep * parseInt(endColor.slice(3, 5), 16)
                            )}, ${Math.round(
                              (1 - colorStep) *
                                parseInt(startColor.slice(5, 7), 16) +
                                colorStep * parseInt(endColor.slice(5, 7), 16)
                            )}, 0.8)`;
                          }

                          return (
                            <div
                              key={index}
                              className="progressSegment"
                              style={{
                                width: `${percentage}%`,
                                backgroundColor,
                              }}
                            >
                              <div className="paymentTooltip">
                                <span>
                                  {payment.amount.toLocaleString("en-US")} € -{" "}
                                  {new Date(
                                    payment.paymentDate
                                  ).toLocaleDateString()}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      <div className="progressPriceVente">
                        {handleTotalPayments(item._id).toLocaleString("en-US")}{" "}
                        € /{" "}
                        {handleTotalAppartmentValue(item._id).toLocaleString(
                          "en-US"
                        )}{" "}
                        €
                      </div>
                    </div>
                  </td>

                  <td>
                    <span>{getCountryNameByISO(item.country)}</span>
                  </td>
                  <td>
                    <span
                      className="hover-effect"
                      onClick={() => handleReferenceClick(item)}
                    >
                      com({item.comment.length})
                    </span>
                    <span
                      className="hover-effect"
                      onClick={() =>
                        handleOpenDeleteMessage(item._id, item._id)
                      }
                    >
                      del
                    </span>
                  </td>
                </tr>
              ))}
              {data.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {data.length > 0 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(data.length / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}

          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={data}
              pageData={data}
              setIsAllChecked={setIsAllChecked}
            />
          )}
          {openCreateClient && (
            <CreateSingleClient
              setOpen={setOpenCreateClient}
              reFetchFromTable={reFetch}
            />
          )}
          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              endPoint={"client"}
              id={selectedDeletedId}
            />
          )}
          {openPopupClient && (
            <PopupClientContainer
              setOpen={setOpenPopupClient}
              data={selectedData}
              reFetchFromTable={reFetch}
              clients={clients}
              reFetchClients={reFetchClients}
            />
          )}
          {openCommentPopUp && (
            <CommentPopup
              data={selectedData}
              setOpen={setOpenCommentPopUp}
              reFetchFromTable={reFetch}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientTable;
