import "./AgenceTable.css";
import {
  handleExportButtonClick,
  handleCheckboxChange,
} from "../../../../utils/datatable.utils";
import SearchIcon from "@mui/icons-material/Search";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import moment from "moment";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import DeleteMessage from "../../../DeleteMessage/DeleteMessage";
import CommentPopup from "../CommentPopup/CommentPopup";
import DeleteAllMessageCustomers from "../DeleteAllMessageCustomers/DeleteAllMessageCustomers";
import AssignAllLeadCustomers from "../AssignAllLeadCustomers/AssignAllLeadCustomers";
import { countries } from "countries-list";
import PulseLoaderPopup from "../../../Loaders/PulseLoaderPopup/PulseLoaderPopup";
import ClimbingBoxLoaderPopup from "../../../Loaders/ClimbingBoxLoaderPopup/ClimbingBoxLoaderPopup";
import PopupCustomerContainer from "../../PopupCustomer/PopupClientContainer/PopupCustomerContainer";
import { useSocket } from "../../../../context/SocketContext";
import { AuthContext } from "../../../../context/auth.context";
import SignePopUp from "../../Customers/SignePopUp/SignePopUp";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
const AgenceTable = ({
  data,
  reFetch,
  currentPage,
  setPage,
  totalLeads,
  selectedFilters,
  setSelectedFilters,
  userId,
  status,
  users,
  reFetchUsers,
  allLeads,
  reFetchAllLead,
}) => {
  const [perPage, setPerPage] = useState(50);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [isAllRowsHighlighted, setIsAllRowsHighlighted] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [openDeleteAllMessage, setOpenDeleteAllMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedData, setSelectedData] = useState(null);
  const [openCommentPopUp, setOpenCommentPopUp] = useState(null);
  const [openAssignAllLead, setOpenAssignAllLead] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Attr");
  const [openPopupCustomer, setOpenPopupCustomer] = useState(false);
  const [openSignaturePopup, setOpenSignaturePopup] = useState(false);
  const [showPulseLoader, setShowPulseLoader] = useState(false);
  const [showClimbingLoader, setShowClimbingLoader] = useState(false);
  const [leadsOnlineStatus, setLeadsOnlineStatus] = useState({});
  const [searchContainerRef, setSearchContainerRef] = useState(null);
  const { socket, emitCurrentLeadStatus, emitRefreshData } = useSocket();
  const { user } = useContext(AuthContext);

  const tableRef = useRef(null);

  useEffect(() => {
    reFetch();
  }, [currentPage]);

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected + 1);
  };

  const handleExportCsv = () => {
    if (checkedItems.length > 0) {
      handleExportButtonClick(data, checkedItems);
    } else {
      alert("Please select at least one checkbox to export.");
    }
  };

  //Gerez si le lead est connecte a la plateforme

  useEffect(() => {
    const handleCurrentLeadsStatus = (initialStatus) => {
      const statusMap = initialStatus.reduce((acc, lead) => {
        acc[lead.email] = lead.isOnline;
        return acc;
      }, {});
      setLeadsOnlineStatus(statusMap);
    };

    const handleUpdateLeadsStatus = (connectedLeads) => {
      setLeadsOnlineStatus((prevStatus) => {
        const newStatusMap = { ...prevStatus };
        Object.keys(connectedLeads).forEach((email) => {
          newStatusMap[email] = true;
        });
        return newStatusMap;
      });
    };

    emitCurrentLeadStatus();
    socket.on("currentLeadsStatus", handleCurrentLeadsStatus);
    socket.on("updateLeadsStatus", handleUpdateLeadsStatus);

    return () => {
      socket.off("currentLeadsStatus", handleCurrentLeadsStatus);
      socket.off("updateLeadsStatus", handleUpdateLeadsStatus);
    };
  }, [socket]);

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };

  const selectCurrentPage = () => {
    const currentPageItems = data.map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = async () => {
    try {
      const queryParams = {
        userId: userId,
        user: selectedFilters.user,
        campaign: selectedFilters.campaign,
        status: selectedFilters.status,
        country: selectedFilters.country,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/userLeadsWithFilters`,
        { params: queryParams }
      );
      const allFilteredLeads = response.data;
      const allItems = allFilteredLeads.map((item) => item._id);
      setCheckedItems(allItems);
    } catch (err) {
      console.error("Erreur lors de la récupération des leads filtrés:", err);
    }
  };

  const handleOpenDeleteMessage = (leadId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId(leadId);
  };

  const handleAssignmentChange = async (event, leadId) => {
    const newAssigneeId = event.target.value;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/assign-lead`,
        { leadId, newAssigneeId }
      );

      if (response.data) {
        emitRefreshData();
        reFetch();
        reFetchUsers();
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'assignation :", error);
    }
  };

  const handleStatusChange = async (event, leadId) => {
    const newStatutId = event.target.value;
    setSelectedStatus((prev) => ({ ...prev, [leadId]: newStatutId }));

    try {
      const correspondingLead = data.find((lead) => lead._id === leadId);
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/${correspondingLead._id}`,
        { status: newStatutId }
      );
      if (response.data) {
        emitRefreshData();
      }
      reFetch();
    } catch (err) {
      console.log(err);
    }
  };

  //Filter
  const handleUserFilterChange = (e) => {
    setSelectedFilters((filters) => ({ ...filters, user: e.target.value }));
    setPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setSelectedFilters((filters) => ({ ...filters, status: e.target.value }));
    setPage(1);
  };

  const handleCountryFilterChange = (e) => {
    setSelectedFilters((filters) => ({ ...filters, country: e.target.value }));
    setPage(1);
  };

  const handleReferenceClick = (data) => {
    setSelectedData(data);
    setOpenCommentPopUp(true);
  };

  const handleOpenPopupCustomer = (data) => {
    setSelectedData(data);
    setOpenPopupCustomer(true);
  };
  const handleOpenPopupSigned = (data) => {
    setSelectedData(data);
    setOpenSignaturePopup(true);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  function getCountryNameByISO(code) {
    return countries[code]?.name || code;
  }

  const agencyCountries = [
    ...new Set(allLeads.map((agency) => agency.country)),
  ];

  // Filtrer les pays disponibles à partir de la liste des agences
  const availableCountries = Object.keys(countries)
    .filter((code) => agencyCountries.includes(code))
    .map((code) => ({
      code: code,
      name: countries[code]?.name,
    }));

  const sortedAvailableCountries = availableCountries.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  // Filtrer pour obtenir uniquement les leads non attribués
  const unassignedLeads = allLeads.filter((lead) => lead.isAttribued);

  // Filtrer les leads non attribués basé sur la recherche
  const filteredLeads =
    searchValue.length > 0
      ? unassignedLeads.filter(
          (lead) =>
            lead.reference.toLowerCase().includes(searchValue.toLowerCase()) ||
            lead.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            lead.agencyName.toLowerCase().includes(searchValue.toLowerCase())
        )
      : unassignedLeads;

  const handleClickOutside = (event) => {
    if (searchContainerRef && !searchContainerRef.contains(event.target)) {
      setSearchActive(false);
      setSearchValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchContainerRef]);

  const getInitials = (agencyName) => {
    return agencyName.charAt(0).toUpperCase();
  };
  const getColorClass = (agencyName) => {
    const colorClasses = [
      "red",
      "blue",
      "purple",
      "green",
      "pink",
      "orange",
      "teal",
      "yellow",
      "brown",
      "gray",
    ];
    const index = agencyName.length % colorClasses.length;
    return colorClasses[index];
  };

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Liste d'agences : {totalLeads} </span>
              <div className="selectWithArrow">
                <UnfoldMoreIcon
                  className="selectWithArrowIcon"
                  style={{
                    fontSize: "13px",
                    color:
                      selectedFilters.country !== "All Countries"
                        ? "var(--primary-color)"
                        : "gray",
                  }}
                />
                <select
                  value={selectedFilters.country}
                  onChange={handleCountryFilterChange}
                  className={
                    selectedFilters.country !== "All Countries"
                      ? "greenBackgroundAfterSelect"
                      : ""
                  }
                >
                  <option value="All Countries">All Countries</option>
                  {sortedAvailableCountries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              {user.isSuperAdmin && (
                <div className="selectWithArrow">
                  <UnfoldMoreIcon
                    className="selectWithArrowIcon"
                    style={{
                      fontSize: "13px",
                      color:
                        selectedFilters.user !== "All Sales"
                          ? "var(--primary-color)"
                          : "gray",
                    }}
                  />
                  <select
                    value={selectedFilters.user}
                    onChange={handleUserFilterChange}
                    className={
                      selectedFilters.user !== "All Sales"
                        ? "greenBackgroundAfterSelect"
                        : ""
                    }
                  >
                    <option value="All Sales">All Sales</option>
                    {users
                      .filter((user) => user.isSales || user.isAdmin)
                      .map((user) => (
                        <option key={user._id} value={user._id}>
                          {user.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              <div className="selectWithArrow">
                <UnfoldMoreIcon
                  className="selectWithArrowIcon"
                  style={{
                    fontSize: "13px",
                    color:
                      selectedFilters.status !== "All Status"
                        ? "var(--primary-color)"
                        : "gray",
                  }}
                />
                <select
                  value={selectedFilters.status}
                  onChange={handleStatusFilterChange}
                  className={
                    selectedFilters.status !== "All Status"
                      ? "greenBackgroundAfterSelect"
                      : ""
                  }
                >
                  <option value="All Status">All Status</option>
                  {status.map((stat) => (
                    <option key={stat._id} value={stat._id}>
                      {stat.title}
                    </option>
                  ))}
                </select>
              </div>

              {user.isSuperAdmin && (
                <div className="selectWithArrow">
                  <UnfoldMoreIcon
                    className="selectWithArrowIcon"
                    style={{ fontSize: "13px" }}
                  />
                  <select onChange={handleOptionChange}>
                    <option value="Attr">Attribution</option>
                    <option value="Reg">Registration</option>
                  </select>
                </div>
              )}
            </div>

            <div className="rightElements">
              {checkedItems.length > 0 && (
                <>
                  <button onClick={() => setOpenAssignAllLead(true)}>
                    <KeyboardArrowRightIcon />
                    <span>Assign To</span>
                  </button>
                  <button onClick={() => setOpenDeleteAllMessage(true)}>
                    <KeyboardArrowRightIcon />
                    <span>Delete All</span>
                  </button>
                </>
              )}
              {checkedItems.length > 0 && (
                <button onClick={handleExportCsv}>
                  <ImportExportIcon />
                  <span>Export</span>
                </button>
              )}
              <div className="searchContainer" ref={setSearchContainerRef}>
                {!searchActive && (
                  <button onClick={() => setSearchActive(true)}>
                    <SearchIcon />
                    <span>Search</span>
                  </button>
                )}
                {searchActive && (
                  <div>
                    <input
                      type="text"
                      className="searchInput"
                      onChange={(e) => setSearchValue(e.target.value)}
                      autoFocus
                    />
                    <div className="searchResults">
                      {searchValue.length > 0 &&
                        filteredLeads.map((lead, index) => (
                          <div
                            key={index}
                            className="searchResultItem"
                            onClick={() => handleOpenPopupCustomer(lead, index)}
                          >
                            {lead.reference} - {lead.agencyName}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />

                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th></th>
                <th>
                  <div className="headerWithIcon">Agence</div>
                </th>
                <th>
                  <div className="headerWithIcon">Nom contact</div>
                </th>

                <th>
                  <div className="headerWithIcon">Téléphone</div>
                </th>

                <th>
                  <div className="headerWithIcon">
                    {selectedOption === "Attr" ? "Attr" : "Reg"}
                  </div>
                </th>

                <th>
                  <div className="headerWithIcon">Sales Agent</div>
                </th>
                <th>
                  <div className="headerWithIcon">Status</div>
                </th>
                <th>
                  <div className="headerWithIcon">Pays</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={
                    isAllRowsHighlighted || highlightedRows.includes(item._id)
                      ? "highlighted"
                      : ""
                  }
                >
                  <td>
                    <label className="container">
                      <input
                        type="checkbox"
                        checked={checkedItems.includes(item._id)}
                        onChange={(e) =>
                          handleCheckboxChangeWrapper(e, item._id)
                        }
                      />
                      <div className="checkmark"></div>
                    </label>
                  </td>

                  <td className="specialTd">
                    <span style={{ color: "var(--primary-color)" }}>
                      {item.reference}
                    </span>
                  </td>
                  <td onClick={() => handleOpenPopupCustomer(item, index)}>
                    <div className="nameAndStatus">
                      <div
                        className={`circleAgence ${getColorClass(
                          item.agencyName
                        )}`}
                      >
                        {getInitials(item.agencyName)}
                      </div>
                      <span style={{ color: "var(--primary-color)" }}>
                        {item.agencyName}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="nameAndStatus">
                      <span>{item.name}</span>
                    </div>
                  </td>

                  <td>
                    <span>{item.phone}</span>
                  </td>

                  <td>
                    <span>
                      {moment(
                        selectedOption === "Attr"
                          ? item.attributionDate
                          : item.createdAt
                      ).format("DD/MM/YYYY")}
                    </span>
                  </td>

                  <td className="tdWithArrow">
                    <UnfoldMoreIcon
                      className="selectWithArrowIconInTD"
                      style={{ fontSize: " 13px" }}
                    />
                    <select
                      className="statusLead"
                      value={
                        selectedAssignee[item._id] ||
                        (item.assignTo ? item.assignTo._id : "Not assign")
                      }
                      onChange={(e) => handleAssignmentChange(e, item._id)}
                    >
                      {item.assignTo ? (
                        <option value={item.assignTo._id}>
                          {item.assignTo.name}
                        </option>
                      ) : (
                        <option value="Not assign">Not assign</option>
                      )}
                      {users
                        .filter(
                          (user) =>
                            (user.isSales || user.isAdmin) &&
                            (!item.assignTo || user._id !== item.assignTo._id)
                        )
                        .map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        ))}
                    </select>
                  </td>

                  <td className="tdWithArrow">
                    <UnfoldMoreIcon
                      className="selectWithArrowIconInTD"
                      style={{ fontSize: "13px" }}
                    />
                    <select
                      className="statusLead"
                      value={
                        selectedStatus[item._id] ||
                        (item.status ? item.status._id : "default")
                      }
                      onChange={(e) => handleStatusChange(e, item._id)}
                    >
                      {/* Option par défaut (statut assigné) */}
                      {item.status ? (
                        <option value={item.status._id}>
                          {item.status.title}
                        </option>
                      ) : (
                        <option value="default">Select Status</option>
                      )}

                      {/* Autres statuts (exclure l'assigné) */}
                      {status
                        .filter(
                          (stat) =>
                            stat._id !== (item.status ? item.status._id : "")
                        )
                        .map((stat) => (
                          <option key={stat._id} value={stat._id}>
                            {stat.title}
                          </option>
                        ))}
                    </select>
                  </td>

                  <td>
                    <span>{getCountryNameByISO(item.country)}</span>
                  </td>

                  <td>
                    <span
                      className="hover-effect"
                      onClick={() => handleReferenceClick(item)}
                    >
                      com({item.comment.length}){" "}
                    </span>

                    <span
                      className="hover-effect"
                      onClick={() => handleOpenDeleteMessage(item._id)}
                    >
                      del
                    </span>
                    <span
                      className="hover-effect"
                      onClick={() => handleOpenPopupSigned(item)}
                    >
                      signé
                    </span>
                  </td>
                </tr>
              ))}
              {data.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {data.length > 0 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(totalLeads / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={currentPage - 1}
            />
          )}
          {openAssignAllLead && (
            <AssignAllLeadCustomers
              setOpen={setOpenAssignAllLead}
              reFetchFromTable={reFetch}
              checkedItems={checkedItems}
              setIsAllChecked={setIsAllChecked}
              setShowLoader={setShowClimbingLoader}
            />
          )}
          {showClimbingLoader && <ClimbingBoxLoaderPopup />}

          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={totalLeads}
              pageData={data.length}
              setIsAllChecked={setIsAllChecked}
              name="customer"
            />
          )}

          {openPopupCustomer && (
            <PopupCustomerContainer
              setOpen={setOpenPopupCustomer}
              data={selectedData}
              leads={allLeads}
              reFetchAllLead={reFetchAllLead}
              reFetchFromTable={reFetch}
            />
          )}
          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              endPoint={"agence"}
              id={selectedDeletedId}
              allLeads={allLeads}
            />
          )}
          {openSignaturePopup && (
            <SignePopUp
              setOpen={setOpenSignaturePopup}
              data={selectedData}
              reFetchFromTable={reFetch}
            />
          )}
          {openDeleteAllMessage && (
            <DeleteAllMessageCustomers
              setOpen={setOpenDeleteAllMessage}
              reFetchFromTable={reFetch}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              setShowLoader={setShowPulseLoader}
              setIsAllChecked={setIsAllChecked}
              allLeads={allLeads}
            />
          )}
          {showPulseLoader && <PulseLoaderPopup />}
          {openCommentPopUp && (
            <CommentPopup
              data={selectedData}
              setOpen={setOpenCommentPopUp}
              reFetchFromTable={reFetch}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AgenceTable;
