import "./HomePage.css";
import Sidebar from "../../components/Navigation/Sidebar/Sidebar";
import Dashboard from "../../components/Dashboard/Dashboard";
import NavigationTabsAndFilterDate from "../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { DashboardTitles } from "../../constants/navigationTitles";
import { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";

function HomePage() {
  const [allStats, setAllStats] = useState({}); // Pour stocker les données des widgets

  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate,
    endDate,
  });

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  // Fonction pour récupérer les données du backend
  const fetchAllStats = async () => {
    const queryParams = new URLSearchParams({
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/stats?${queryParams}`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setAllStats(response.data); // Stocke les données des widgets
    } catch (err) {
      console.log("Erreur lors de la récupération des statistiques", err);
    }
  };

  // Effect pour récupérer les statistiques à chaque changement de date
  useEffect(() => {
    fetchAllStats();
  }, [selectedDateRange]);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            tabs={DashboardTitles}
            onFilteredDataChange={handleFilteredDataChange}
          />
        </div>
        <div className="bottomCrmPage">
          <Dashboard allStats={allStats} />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
