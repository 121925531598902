import axios from "axios";
import Deposit from "../../../components/Banking/Deposit/DepositTable/DepositTable";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { BankingTitles } from "../../../constants/navigationTitles";
import "./DepositPage.css";
import { useCallback, useEffect, useState } from "react";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { useData } from "../../../context/DataContext";

const DepositPage = () => {
  const [leadDeposits, setLeadDeposits] = useState([]);
  const { allLeads, reFetchAllLead, users, reFetchUsers } = useData();
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const fetchAllDeposits = async () => {
    const queryParams = new URLSearchParams({
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();
    try {
      const storedToken = localStorage.getItem("authToken");

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/banking?${queryParams}`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      setLeadDeposits(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchAllDeposits();
  }, [selectedDateRange]);

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            onFilteredDataChange={handleFilteredDataChange}
            tabs={BankingTitles}
          />
        </div>
        <div className="bottomCrmPage">
          <Deposit
            data={leadDeposits}
            reFetch={fetchAllDeposits}
            allLeads={allLeads}
            reFetchLead={reFetchAllLead}
            users={users}
            reFetchUsers={reFetchUsers}
          />
        </div>
      </div>
    </div>
  );
};

export default DepositPage;
