import "./PicturesLibrary.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
const PicturesLibrary = ({ setOpen, selectedImage, setSelectedImage }) => {

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const avatarImages = Array.from({ length: 36 }, (_, i) => i + 1);

  const handleSelectedImage = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  
  return (
    <>
      <div className="overlay"></div>
      <div className="picturesLibrary" onClick={handleClickInside}>
        <div className="closeIconStatus">
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        <div className="picturesLibraryWrapper">
          <div className="topCreateStatus">
            <span>Images Library</span>
          </div>

          <div className="middlePictureLibrary">
            {avatarImages.map((image) => {
              const imageSrc = `/images/avatar/avatar${image}.png`;
              return (
                <img
                  key={image}
                  src={imageSrc}
                  alt={`Avatar ${image}`}
                  className={`pictureItem ${
                    selectedImage === imageSrc ? "selectedPictureLibrary" : ""
                  }`}
                  onClick={() => handleSelectedImage(imageSrc)}
                />
              );
            })}
          </div>
          <div className="bottomCreateUser">
            <button className="cancelButton" onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button className="saveButton" onClick={() => setOpen(false)}>
              <CheckIcon />
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PicturesLibrary;
