import "./EditPayment.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";

const EditPayment = ({ setOpen, reFetchFromTable, id }) => {
  const { data: payments } = useFetch("payment");
  const [editPayment, setEditPayment] = useState({ title: "" });

  useEffect(() => {
    if (payments) {
      const findCorrespondingPayment = payments.find((s) => s._id === id);
      if (findCorrespondingPayment) {
        setEditPayment({ title: findCorrespondingPayment.title });
      }
    }
  }, [payments, id]);

  
  const handleChange = (e) => {
    setEditPayment({ ...editPayment, [e.target.name]: e.target.value });
  };
  
  const handleEditPayment = async (e) => {
    e.preventDefault();
    
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/payment/${id}`,
        editPayment
        );
        reFetchFromTable();
        setEditPayment({ title: "" });
        setOpen(false);
      } catch (err) {
        console.log(err);
      }
    };
    
    const handleClickInside = (e) => {
      e.stopPropagation();
    };


  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>Edit Payment</span>
            </div>
            <div className="middleCreateStatus">
              <label>Name</label>
              <input
                type="text"
                name="title"
                value={editPayment.title}
                onChange={handleChange}
              />
            </div>
            <div className="bottomCreateStatus">
              <button className="cancelButton">Cancel</button>
              <button className="saveButton" onClick={handleEditPayment}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPayment;
