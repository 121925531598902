import { useEffect, useState } from "react";
import "./Commission.css";
import moment from "moment";
import AddPayment from "./AddPayment/AddPayment";
import { useData } from "../../../../../context/DataContext";
import DeleteMessage from "../../../../DeleteMessage/DeleteMessage";
const Commission = ({ currentData, reFetchAllLead, leads }) => {
  const { paymentAgence, refetchPaymentAgence } = useData();
  const [openAddPaiement, setOpenAddPaiement] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);

  const handleDeletePayment = (paymentAgenceId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId(paymentAgenceId);
  };

  const findCorrespondingPayment = (paymentAgence || []).filter(
    (p) => p?.agence?._id === currentData?._id
  );
  return (
    <>
      <div className="containerDatatableHistory">
        <div className="datatableHistory">
          <span
            className="addFundPopup"
            onClick={() => setOpenAddPaiement(true)}
          >
            + Ajouter un paiement
          </span>
          <table className="tableDataHistory">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Type</div>
                </th>
                <th>
                  <div className="headerWithIcon">Montant</div>
                </th>

                <th>
                  <div className="headerWithIcon">Date</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {findCorrespondingPayment &&
              findCorrespondingPayment.length > 0 ? (
                findCorrespondingPayment.map((item) => (
                  <tr>
                    <td>{item.typePayment.title}</td>
                    <td>{item.amount}</td>
                    <td>{moment(item.paymentDate).format("DD/MM/YYYY")}</td>
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleDeletePayment(item._id)}
                      >
                        del
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {openAddPaiement && (
          <AddPayment
            setOpen={setOpenAddPaiement}
            refetch={refetchPaymentAgence}
            currentData={currentData}
          />
        )}
        {openDeleteMessage && (
          <DeleteMessage
            setOpen={setOpenDeleteMessage}
            reFetchFromTable={refetchPaymentAgence}
            endPoint={"paymentAgence"}
            id={selectedDeletedId}
          />
        )}
      </div>
    </>
  );
};

export default Commission;
