import "./StartPromoteNew.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { useData } from "../../../../context/DataContext";
import { getCountryCode } from "../../../../utils/countryCodes";
import { useEffect, useState } from "react";
const StartPromoteNew = ({ setOpen, reFetchFromTable, selectedData }) => {
  const { allLeads } = useData();
  const [templates, setTemplates] = useState([]);

  // Récupérer les templates via l'API Facebook
  const fetchAllTemplates = async () => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v20.0/${process.env.REACT_APP_BUSINESS_ID}/message_templates`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_FB}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.data) {
        setTemplates(response.data.data); // Mettre les templates dans l'état
      } else {
        console.log("Aucun modèle trouvé.");
      }
    } catch (err) {
      console.log("Erreur lors de la récupération des modèles :", err);
    }
  };

  useEffect(() => {
    fetchAllTemplates();
  }, []);

  const formatPhoneNumber = (phone, country) => {
    const countryCode = getCountryCode(country);
    return `${countryCode}${phone}`;
  };

  const exampleValues = {
    clientName: "Marc Jones",
    clientEmail: "marcjones@example.com",
    clientPhone: "+123456789",
    clientCountry: "USA",
  };

  console.log(templates);
  // Associer les exemples récupérés aux valeurs réelles de l'agence
  const getCorrespondingValue = (exampleText, agenceDetails) => {
    if (exampleText.includes("Marc Jones")) {
      return agenceDetails.name || "";
    } else if (exampleText.includes("marcjones@example.com")) {
      return agenceDetails.email || "";
    } else if (exampleText.includes("+123456789")) {
      return agenceDetails.phone || "";
    } else if (exampleText.includes("USA")) {
      return agenceDetails.country || "";
    }
    return String(exampleText); // Si aucun exemple ne correspond, garder l'exemple initial comme chaîne
  };

  const replacePlaceholdersInComponents = (
    templateComponents,
    agenceDetails,
    placeholders
  ) => {
    return templateComponents.map((component) => {
      if (component.text) {
        // Remplacer chaque placeholder par la valeur correcte en utilisant `placeholders`
        const updatedParams = component.text
          .match(/\{\{\d+\}\}/g)
          .map((placeholder) => {
            const matchedPlaceholder = placeholders.find(
              (p) => p.placeholder === placeholder
            );

            // Trouver la valeur correspondante dans `agenceDetails` en fonction de l'exemple du placeholder
            if (matchedPlaceholder) {
              if (matchedPlaceholder.example === "clientEmail") {
                return {
                  type: "text",
                  text: agenceDetails.email || "Email non disponible",
                };
              } else if (matchedPlaceholder.example === "clientName") {
                return {
                  type: "text",
                  text: agenceDetails.name || "Nom non disponible",
                };
              } else if (matchedPlaceholder.example === "clientPhone") {
                return {
                  type: "text",
                  text: agenceDetails.phone || "Téléphone non disponible",
                };
              } else if (matchedPlaceholder.example === "clientCountry") {
                return {
                  type: "text",
                  text: agenceDetails.country || "Pays non disponible",
                };
              } else {
                return {
                  type: "text",
                  text: "Valeur par défaut",
                };
              }
            }
            return {
              type: "text",
              text: "Valeur par défaut",
            };
          });

        return {
          type: component.type, // Conserver le type du composant (BODY)
          parameters: updatedParams, // Assigner les valeurs actualisées
        };
      }
      return component; // Si pas de texte, retourner le composant tel quel
    });
  };

  const sendMessageWithTemplate = async (
    phoneNumber,
    templateName,
    agenceDetails,
    templateComponents,
    placeholders,
    templateLanguage,
    campaignId // Ajout de l'ID de la campagne ici
  ) => {
    // Remplacer les placeholders avec les valeurs correspondantes
    const updatedComponents = replacePlaceholdersInComponents(
      templateComponents,
      agenceDetails,
      placeholders
    );

    try {
      const response = await axios.post(
        `https://graph.facebook.com/v20.0/${process.env.REACT_APP_PHONE_ID}/messages`,
        {
          messaging_product: "whatsapp",
          to: phoneNumber,
          type: "template",
          template: {
            name: templateName,
            language: {
              code: templateLanguage,
            },
            components: updatedComponents,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_FB}`,
            "Content-Type": "application/json",
          },
        }
      );

      const sentMessageId = response.data.messages[0].id;
      console.log(`Message template envoyé à ${phoneNumber}: ${sentMessageId}`);

      // **AJOUT**: Sauvegarder le `messageId` immédiatement après l'envoi
      try {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/promote/storeMessageId`,
          {
            campaignId, // Utilise l'ID de la campagne
            messageId: sentMessageId, // Sauvegarde du messageId unique
          }
        );
        console.log(`Message ID ${sentMessageId} sauvegardé avec succès.`);
      } catch (error) {
        console.error(
          `Erreur lors de la sauvegarde du messageId ${sentMessageId} :`,
          error
        );
      }

      return sentMessageId;
    } catch (error) {
      console.error(
        `Échec de l'envoi du message template à ${phoneNumber}:`,
        error
      );
      return null;
    }
  };

  console.log(selectedData);

  // Démarrer la campagne en envoyant les messages basés sur les templates
  const startCampaign = async () => {
    const { agence, messagesByCountry, _id: campaignId } = selectedData; // Utilise l'ID de la campagne

    for (const agenceItem of agence) {
      const agenceDetails = allLeads.find((a) => a._id === agenceItem._id);

      if (agenceDetails) {
        const { phone, country } = agenceDetails;
        const phoneNumber = formatPhoneNumber(phone, country);

        // Récupérer les détails du message et des placeholders pour le pays
        const messageData = messagesByCountry[country];
        const templateName = messageData?.templateName;
        const placeholders = messageData?.placeholders;

        // Trouver le template dans ceux récupérés de Facebook
        const template = templates.find((t) => t.name === templateName);
        if (template) {
          const templateComponents = template.components;
          const templateLanguage = template.language;

          // Envoyer le message et enregistrer l'ID du message dans la campagne
          await sendMessageWithTemplate(
            phoneNumber,
            templateName,
            agenceDetails,
            templateComponents,
            placeholders,
            templateLanguage,
            campaignId // Transmettre l'ID de la campagne à `sendMessageWithTemplate`
          );
        } else {
          console.error(`Aucun modèle trouvé pour le template ${templateName}`);
        }
      }
    }

    setOpen(false); // Fermer la fenêtre après l'envoi
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus">
          <div className="closeIconStatus">
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>Start Campaign</span>
            </div>
            <div className="middleCreateStatus">
              <span>Are you sure you want to perform this action?</span>
            </div>
            <div className="bottomCreateStatus">
              <button
                className="cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="saveButton"
                onClick={(e) => {
                  e.stopPropagation();
                  startCampaign(); // Lancer la campagne lorsqu'on clique sur "Confirm"
                }}
              >
                <CheckIcon />
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartPromoteNew;
