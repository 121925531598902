import "./CreateSingleClient.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";
import CountrySelect from "../../../CountrySelect/CountrySelect";
import { useData } from "../../../../context/DataContext";

const CreateSingleClient = ({
  setOpen,
  reFetchFromTable,
  onClose,
  reFetchAllLead,
  selectedAgence,
}) => {
  const { data: clients } = useFetch("client");
  const { data: agences } = useFetch("agence");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState({});
  const { reFetchClients } = useData();

  const [newClient, setNewClient] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    postalCode: "",
    city: "",
    idendity: "",
    country: "",
    agence: selectedAgence || "", // Agence pré-sélectionnée
  });

  const isFormValid = () => {
    let isValid = true;
    let newError = {};

    if (!newClient.name.trim()) {
      newError.name = "Name is required";
      isValid = false;
    }
    if (!lastName.trim()) {
      newError.lastName = "Last name is required";
      isValid = false;
    }
    if (!newClient.email.trim()) {
      newError.email = "Email is required";
      isValid = false;
    }

    setError(newError);
    return isValid;
  };

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "lastName") {
      setLastName(value);
    } else {
      setNewClient((prev) => ({ ...prev, [name]: value }));
    }

    // Réinitialiser l'erreur spécifique à ce champ
    setError((prevError) => ({ ...prevError, [name]: "" }));
  };

  const handleAddClient = async (e) => {
    e.preventDefault();

    if (!isFormValid()) return;

    const emailExists = clients.some(
      (lead) => lead.email === newClient.email.trim()
    );
    if (emailExists) {
      setError((prevError) => ({
        ...prevError,
        email: "Email already exists",
      }));
      return;
    }

    const combinedClientName = {
      ...newClient,
      name: `${newClient.name} ${lastName}`,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/client/`,
        combinedClientName
      );

      const createdClient = response.data;

      reFetchFromTable();
      reFetchClients();
      if (reFetchAllLead) reFetchAllLead();
      setNewClient({
        name: "",
        email: "",
        phone: "",
        country: "",
        identity: "",
        postalCode: "",
        city: "",
        address: "",
        agence: "",
      });
      setLastName("");
      setOpen(false);
      if (onClose) {
        onClose(createdClient);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createLead" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createLeadWrapper">
            <div className="topCreateStatus">
              <span>Crée Client</span>
            </div>
            <div className="middleCreateClient">
              <label>Prenom</label>
              <input
                type="text"
                name="name"
                value={newClient.name}
                onChange={handleChange}
              />
              {error.name && <div className="errormessage">{error.name}</div>}
              <label>Nom</label>
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {error.lastName && (
                <div className="errormessage">{error.lastName}</div>
              )}
              <label>Email</label>
              <input
                type="text"
                name="email"
                value={newClient.email}
                onChange={handleChange}
              />
              {error.email && <div className="errormessage">{error.email}</div>}
              <label>Telephone</label>
              <input
                type="text"
                name="phone"
                value={newClient.phone}
                onChange={handleChange}
              />
              <label>Adresse</label>
              <input
                type="text"
                name="address"
                value={newClient.address}
                onChange={handleChange}
              />
              <label>Code postal</label>
              <input
                type="text"
                name="postalCode"
                value={newClient.postalCode}
                onChange={handleChange}
              />
              <label>Ville</label>
              <input
                type="text"
                name="city"
                value={newClient.city}
                onChange={handleChange}
              />
              <CountrySelect
                label="Country"
                className="countrySelectCreateLead"
                value={newClient.country}
                onChange={handleChange}
                name="country"
              />
              <label>Carte d'identité</label>
              <input
                type="text"
                name="idendity"
                value={newClient.idendity}
                onChange={handleChange}
              />
              <label>Agence</label>
              <select
                name="agence"
                className="countrySelectCreateLead"
                onChange={handleChange}
                value={newClient.agence}
              >
                <option value="">Select</option>
                {agences
                  .filter((a) => a.signed.isSigned)
                  .map((a) => (
                    <option key={a._id} value={a._id}>
                      {a.agencyName}
                    </option>
                  ))}
              </select>
            </div>
            <div className="bottomCreateLead">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleAddClient}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSingleClient;
