import React, { useState } from "react";
import "./AgencePerfomance.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const AgencePerfomance = ({ allStats }) => {
  const [sortConfig, setSortConfig] = useState({
    key: "caAgence", // Par défaut, tri par CA
    direction: "desc", // Par défaut, tri décroissant
  });

  if (!allStats || !allStats.performanceAgence) {
    return <div>Loading...</div>;
  }

  const sortData = (key) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    } else if (sortConfig.key !== key) {
      direction = "desc"; // Par défaut pour la nouvelle colonne
    }
    setSortConfig({ key, direction });
  };

  const sortedAgencies = [...allStats.performanceAgence].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const getInitials = (agencyName) => {
    return agencyName.charAt(0).toUpperCase();
  };
  const getColorClass = (agencyName) => {
    const colorClasses = [
      "red",
      "blue",
      "purple",
      "green",
      "pink",
      "orange",
      "teal",
      "yellow",
      "brown",
      "gray",
    ];
    const index = agencyName.length % colorClasses.length;
    return colorClasses[index];
  };

  return (
    <div class="tableContainerDashboard">
      <div class="tableWrapper">
        <table class="customTable">
          <thead class="customThead">
            <tr>
              <th style={{ width: "50%" }}>
                <div class="customHeaderWithIcon">Agences</div>
              </th>
              <th onClick={() => sortData("numberOfClients")} style={{ width: "15%" }}>
                <div class="customHeaderWithIcon">
                  Clients
                  {sortConfig.key === "numberOfClients" &&
                    (sortConfig.direction === "asc" ? (
                      <KeyboardArrowUpIcon style={{ fontSize: "12px" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fontSize: "12px" }} />
                    ))}
                </div>
              </th>
              <th onClick={() => sortData("numberOfSales")} style={{ width: "15%" }}>
                <div class="customHeaderWithIcon">
                  Ventes
                  {sortConfig.key === "numberOfSales" &&
                    (sortConfig.direction === "asc" ? (
                      <KeyboardArrowUpIcon style={{ fontSize: "12px" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fontSize: "12px" }} />
                    ))}
                </div>
              </th>
              <th onClick={() => sortData("caAgence")} style={{ width: "20%" }}>
                <div class="customHeaderWithIcon">
                  CA
                  {sortConfig.key === "caAgence" &&
                    (sortConfig.direction === "asc" ? (
                      <KeyboardArrowUpIcon style={{ fontSize: "12px" }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fontSize: "12px" }} />
                    ))}
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="tableBodyWrapper">
        <table class="customTable">
          <tbody class="customTableBody">
            {sortedAgencies.map((agency) => (
              <tr key={agency.agencyName}>
                <td class="customAgencyTableCell" style={{ width: "50%" }}>
                  <div
                    class={`customAgencyCircle ${getColorClass(
                      agency.agencyName
                    )}`}
                  >
                    <span class="customAgencyInitial">
                      {getInitials(agency.agencyName)}
                    </span>
                  </div>
                  <span>{agency.agencyName}</span>
                </td>
                <td style={{ width: "15%" }}>{agency.numberOfClients}</td>
                <td style={{ width: "15%" }}>{agency.numberOfSales}</td>
                <td style={{ width: "20%" }}>
                  {agency.caAgence.toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AgencePerfomance;
