import "./Dashboard.css";
import DottedMap from "dotted-map/without-countries";
import MyMapString from "../../myMap.json";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useState } from "react";
import Widgets from "./Widgets/Widgets";
import SalesPerfomance from "./SalesPerfomance/SalesPerfomance";
import AgencePerfomance from "./AgencePerfomance/AgencePerfomance";
import Activite from "./Activite/Activite";


const Dashboard = ({ allStats }) => {
  const [activeTab, setActiveTab] = useState("ca");

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Update active tab on click
  };

  return (
    <div className="dashboardContainer">
      <Widgets allStats={allStats} />
      <div className="dashboardContainerMiddle">
        <div className="dashboardContainerMiddleTitle">
          <div className="dashboardContainerMiddleTitleLeft">
            Activité Géographique
          </div>
          <div className="dashboardContainerMiddleTitleRight">
            <div
              className={`dashboardContainerMiddleTitleRightTitle ${
                activeTab === "ca" ? "active" : ""
              }`}
              onClick={() => handleTabClick("ca")}
            >
              Chiffre d'affaire
            </div>
            <div
              className={`dashboardContainerMiddleTitleRightTitle ${
                activeTab === "agence" ? "active" : ""
              }`}
              onClick={() => handleTabClick("agence")}
            >
              Agence signees
            </div>
            <div
              className={`dashboardContainerMiddleTitleRightTitle ${
                activeTab === "vente" ? "active" : ""
              }`}
              onClick={() => handleTabClick("vente")}
            >
              Vente
            </div>
          </div>
        </div>

        <Activite activeTab={activeTab} allStats={allStats} />
      </div>
      <div className="dashboardContainerBottom">
        <div className="dashboardContainerBottomLeft">
          <div className="dashboardContainerMiddleTitle">Sales Perfomances</div>
          <SalesPerfomance allStats={allStats} />
        </div>
        <div className="dashboardContainerBottomLeft">
          <div className="dashboardContainerMiddleTitle">
            Performances Agence
          </div>
          <AgencePerfomance allStats={allStats} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
