import "./Widgets.css";

const Widgets = ({ allStats }) => {
  return (
    <div className="dashboardContainerTop">
      <div className="dashboardContainerTopWidget">
        <span className="dashboardContainerTopWidgetTitle">
          Paiement / Vendu
        </span>
        <span className="dashboardContainerTopWidgetNumber">
          {allStats.widget1?.totalPaid.toLocaleString("en-US")}€
        </span>
        <div className="dashboardContainerTopWidgetVignette">
          <span className="dashboardContainerTopWidgetVignetteContent">
            sur {allStats.widget1?.totalValue.toLocaleString("en-US")}€
          </span>
        </div>
      </div>
      <div className="dashboardContainerTopWidget">
        <span className="dashboardContainerTopWidgetTitle">
          Vendu / Total
        </span>
        <span className="dashboardContainerTopWidgetNumber">
          {allStats.widget2?.totalValue.toLocaleString("en-US")}€
        </span>
        <div className="dashboardContainerTopWidgetVignette">
          <span className="dashboardContainerTopWidgetVignetteContent">
            sur {allStats.widget2?.totalValueAllAppartements.toLocaleString("en-US")}€
          </span>
        </div>
      </div>
      <div className="dashboardContainerTopWidget">
        <span className="dashboardContainerTopWidgetTitle">Agences signée</span>
        <span className="dashboardContainerTopWidgetNumber">
          {allStats.widget3?.totalSignedAgences}
        </span>
        <div className="dashboardContainerTopWidgetVignette">
          <span className="dashboardContainerTopWidgetVignetteContent">
            sur {allStats.widget3?.totalUnsignedAgences}
          </span>
        </div>
      </div>
      <div className="dashboardContainerTopWidget">
        <span className="dashboardContainerTopWidgetTitle">
          Appartements Vendus
        </span>
        <span className="dashboardContainerTopWidgetNumber">
          {allStats.widget4?.totalSoldApartments}
        </span>
        <div className="dashboardContainerTopWidgetVignette">
          <span className="dashboardContainerTopWidgetVignetteContent">
            sur {allStats.widget4?.totalApartments}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Widgets;

// .toLocaleString("en-US")
