import "./PromotePage.css";
import axios from "axios";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { PromoteTitles } from "../../../constants/navigationTitles";
import { useCallback, useEffect, useState } from "react";
import { useData } from "../../../context/DataContext";
import PromoteTable from "../../../components/Promote/Promote/PromoteTable/PromoteTable";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import io from "socket.io-client";

const PromotePage = () => {
  const { promote, refetchPromote } = useData();

  useEffect(() => {
    // Fonction pour rafraîchir les données toutes les 10 secondes
    const interval = setInterval(() => {
      console.log("Rafraîchissement des données...");
      refetchPromote(); // Appel de la fonction de rafraîchissement
    }, 10000); // 10 secondes

    // Cleanup pour arrêter l'intervalle lorsqu'on quitte la page
    return () => clearInterval(interval);
  }, [refetchPromote]);

  console.log(promote);

  // const { promote, refetchPromote } = useData();

  // const socket = io(process.env.REACT_APP_SERVER_URL);

  // useEffect(() => {
  //   if (socket) {
  //     console.log("Socket connected, waiting for events...");

  //     socket.on("refreshPromoteData", () => {
  //       console.log("Event 'refreshPromoteData' received");
  //       refetchPromote();
  //     });

  //     socket.on("newMessageReply", (data) => {
  //       console.log("New message reply received:", data);
  //       refetchPromote();
  //     });
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.off("refreshPromoteData");
  //       socket.off("newMessageReply");
  //     }
  //   };
  // }, [socket]);

  // console.log(promote);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabs tabs={PromoteTitles} />
        </div>
        <div className="bottomCrmPage">
          <PromoteTable data={promote} reFetch={refetchPromote} />
        </div>
      </div>
    </div>
  );
};

export default PromotePage;
