import "./NavigationTabsAndFilterDate.css";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { DateRangePicker } from "react-date-range";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
} from "date-fns";
import { AuthContext } from "../../../context/auth.context";

const NavigationTabsAndFilterDate = ({ tabs, onFilteredDataChange }) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const isActive = (path) => {
    return location.pathname === path;
  };
  
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(1970, 0, 1),
      endDate: new Date(2999, 11, 31),
      key: "selection",
    },
  ]);

  const [isPickerOpen, setPickerOpen] = useState(false);

  const [clickedIndex, setClickedIndex] = useState(0);
  
  const handleItemClick = (index) => {
    setClickedIndex(index);

    let newStartDate, newEndDate;

    switch (index) {
      case 0: // Infini
        newStartDate = new Date(1970, 0, 1);
        newEndDate = new Date(2999, 11, 31);
        break;
      case 1: // Année
        newStartDate = startOfYear(new Date());
        newEndDate = endOfYear(new Date());
        break;
      case 2: // Mois
        newStartDate = startOfMonth(new Date());
        newEndDate = endOfMonth(new Date());
        break;
      case 3: // Semaine
        newStartDate = startOfWeek(new Date());
        newEndDate = endOfWeek(new Date());
        break;
      case 4: // Jour
        newStartDate = startOfDay(new Date());
        newEndDate = endOfDay(new Date());
        break;
      default:
        newStartDate = startOfMonth(new Date());
        newEndDate = endOfMonth(new Date());
    }

    setDateRange([
      { startDate: newStartDate, endDate: newEndDate, key: "selection" },
    ]);

    // Appeler onFilteredDataChange avec les nouvelles dates
    onFilteredDataChange({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const pickerElement = document.querySelector(".dateRangePickerContainer");
      if (pickerElement && !pickerElement.contains(event.target)) {
        setPickerOpen(false);
      }
    };

    if (isPickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPickerOpen]);

  useEffect(() => {
    onFilteredDataChange({
      startDate: dateRange[0].startDate,
      endDate: dateRange[0].endDate,
    });
  }, [dateRange, onFilteredDataChange]);

  // Filtrer les onglets en fonction du rôle de l'utilisateur
  const filteredTabs = tabs.filter((tab) => {
    if (tab.name === "Attribution" && !user.isSuperAdmin) {
      return false;
    } else if (tab.name ===  "Sales Performance" && !user.isSuperAdmin) {
      return false;
    }
    return true;
  });

  return (
    <>
      <div className="navigationAndFilterLeft">
        <nav className="topTabNavigation">
          <ul>
            {filteredTabs.map(({ name, path }) => (
              <li key={name}>
                <Link
                  to={path}
                  className={`tabLink ${isActive(path) ? "tabSelected" : ""}`}
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="navigationAndFilterRight">
        <ul>
          {[<AllInclusiveIcon />, "Y", "M", "W", "D"].map((item, index) => (
            <li
              key={index}
              className={clickedIndex === index ? "clicked" : ""}
              onClick={() => handleItemClick(index)}
            >
              {item}
            </li>
          ))}
        </ul>
        <div className="champDateContainer">
          <div
            className="champDate"
            onClick={() => setPickerOpen(!isPickerOpen)}
          >
            <span>{format(dateRange[0].startDate, "dd/MM/yyyy")}</span>
            <CalendarMonthIcon />
          </div>
          <div
            className="champDate"
            onClick={() => setPickerOpen(!isPickerOpen)}
          >
            <span>{format(dateRange[0].endDate, "dd/MM/yyyy")}</span>
            <CalendarMonthIcon />
          </div>
        </div>
        {isPickerOpen && (
          <div className="dateRangePickerContainer">
            <DateRangePicker
              onChange={(item) => setDateRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRange}
              direction="horizontal"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default NavigationTabsAndFilterDate;
