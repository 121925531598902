import "./LoginPage.css";
import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import authService from "../../services/auth.service";
import axios from "axios";
function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);

  const navigate = useNavigate();

  const { storeToken, authenticateUser } = useContext(AuthContext);

  const handleEmail = (e) => setUsername(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const requestBody = { username, password };

    authService
      .login(requestBody)
      .then((response) => {
       
        storeToken(response.data.authToken);
        authenticateUser();
        navigate("/management");
      })
      .catch((error) => {
        const errorDescription = error.response.data.message;
        setErrorMessage(errorDescription);
      });
  };

  return (
    <div className="loginPage">
      <div className="loginPageContainer">
        <img src="/images/mobestate.png" alt="logo" className="logoLogin" />
        <p>mobestate.com and Customers Management</p>
        <form className="formLogin" onSubmit={handleLoginSubmit}>
          <div className="zonechamp">
            <input
              className="input"
              type="text"
              placeholder="Your Login"
              value={username}
              onChange={handleEmail}
            />
            <input
              className="input"
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePassword}
            />
            <input className="submit" type="submit" value="GO !" />
          </div>
          {errorMessage && <p className="error">{errorMessage}</p>}
        </form>
      </div>
      <footer id="footer">
        <p>Copyright © 2023 mobestate.com.com All right reserved</p>
      </footer>
    </div>
  );
}

export default LoginPage;

// <div className="LoginPage">
//   <h1>Login</h1>

//   <form onSubmit={handleLoginSubmit}>
//     <label>Email:</label>
//     <input type="email" name="email" value={email} onChange={handleEmail} />

//     <label>Password:</label>
//     <input
//       type="password"
//       name="password"
//       value={password}
//       onChange={handlePassword}
//     />

//     <button type="submit">Login</button>
//   </form>
//   {errorMessage && <p className="error-message">{errorMessage}</p>}

//   <p>Don't have an account yet?</p>
//   <Link to={"/signup"}> Sign Up</Link>
// </div>
