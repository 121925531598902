import "./DeleteMessage.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { useSocket } from "../../../../context/SocketContext";
import { useData } from "../../../../context/DataContext";

const DeleteMessage = ({ setOpen, reFetchFromTable, id }) => {
  const { emitRefreshData } = useSocket();
  const { reFetchProjects, refetchVentes } = useData();
  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/vente/${id._id}`
      );

      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/apartment/${id.appartment._id}`,
        { isSold: false }
      );

      if (response.status === 200) {
        emitRefreshData();
        reFetchFromTable();
        reFetchProjects();
        refetchVentes();
      }
    } catch (err) {
      if (err.response) {
        alert(err.response.data);
      } else {
        console.error("Error", err.message);
        alert("An error occurred while deleting.");
      }
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus">
          <div className="closeIconStatus">
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>DEL</span>
            </div>
            <div className="middleCreateStatus">
              <span>Are you sure you want to perform this action?</span>
            </div>
            <div className="bottomCreateStatus">
              <button
                className="cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button className="saveButton" onClick={handleDelete}>
                <CheckIcon />
                Comfirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteMessage;
