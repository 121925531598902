import "./VentePage.css";
import axios from "axios";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { VenteTitles } from "../../../constants/navigationTitles";
import { useCallback, useContext, useEffect, useState } from "react";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { useSocket } from "../../../context/SocketContext";
import { useData } from "../../../context/DataContext";
import AppartmentTable from "../../../components/Projet/Appartment/AppartmentTable/AppartmentTable";
import VenteTable from "../../../components/Ventes/Vente/VenteTable/VenteTable";
import { AuthContext } from "../../../context/auth.context";

const VentePage = () => {
  const { clients, projects, allLeads, status, appartments } = useData();
  const [ventes, setVentes] = useState([]);
  const { user } = useContext(AuthContext);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalVentes, setTotalVentes] = useState(0); // Ajout pour stocker le total des ventes

  const [selectedFilters, setSelectedFilters] = useState({
    agence: "All Agencies",
    appartment: "All Appartments",
    project: "All Projects",
  });

  const fetchAllVentes = async () => {
    const queryParams = new URLSearchParams({
      ...selectedFilters,
      page: currentPage,
      limit: 50,
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();

    try {
      const storedToken = localStorage.getItem("authToken");

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/vente/allVentes?${queryParams}`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      setVentes(response.data.ventes);
      setTotalVentes(response.data.total); // Met à jour le total des ventes
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllVentes();
  }, [currentPage, selectedFilters, selectedDateRange]); 
  
  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            onFilteredDataChange={handleFilteredDataChange}
            tabs={VenteTitles}
          />
        </div>
        <div className="bottomCrmPage">
          <VenteTable
            data={ventes}
            reFetch={fetchAllVentes}
            clients={clients}
            projects={projects}
            allLeads={allLeads}
            appartments={appartments}
            status={status}
            currentPage={currentPage}
            setPage={setCurrentPage}
            totalVentes={totalVentes} // Passer le total au tableau
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default VentePage;
