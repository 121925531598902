import "./Comment.css";
import { DateRangePicker } from "react-date-range";
import CheckIcon from "@mui/icons-material/Check";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { AuthContext } from "../../../../../../context/auth.context";
import axios from "axios";
import CommentDeleteMessage from "../../../CommentDeleteMessage/CommentDeleteMessage";
import { useSocket } from "../../../../../../context/SocketContext";

const Comment = ({ currentData, reFetchFromTable, leads }) => {
  const { user } = useContext(AuthContext);
  const { emitRefreshData } = useSocket();
  const [dateRange, setDateRange] = useState([
    { startDate: new Date(), endDate: new Date(), key: "selection" },
  ]);
  const [time, setTime] = useState("12:00");
  const [showPicker, setShowPicker] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [isDateTimeChanged, setIsDateTimeChanged] = useState(false);
  const [comments, setComments] = useState([]);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [newComment, setNewComment] = useState({
    author: "",
    content: "",
  });

  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const pickerRef = useRef();

  useEffect(() => {
    if (currentData) {
      setComments(currentData.comment);
    }
  }, [leads, openDeleteMessage._id]);

  const handleTimeChange = (e) => {
    setTime(e.target.value);
    setIsDateTimeChanged(true);
  };

  const handleSelectDate = (ranges) => {
    const newDate = ranges.selection.startDate;
    setDateRange([ranges.selection]);
    setAppointmentDate(newDate);
    setShowPicker(false);
    setIsDateTimeChanged(true);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  const handleOpenDeleteMessage = (comId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId(comId);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewComment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateComment = async (e) => {
    e.preventDefault();
    const storedToken = localStorage.getItem("authToken");

    try {
      const authorInfo = {
        _id: user._id,
        name: user.name,
        pPicture: user.pPicture,
      };

      const appointmentDateTime = new Date(appointmentDate);
      appointmentDateTime.setHours(parseInt(time.split(":")[0]));
      appointmentDateTime.setMinutes(parseInt(time.split(":")[1]));

      const commentToAdd = {
        author: authorInfo._id,
        content: newComment.content,
        createdAt: new Date(),
        appointment: isDateTimeChanged
          ? { date: appointmentDateTime, description: "RDV associé" }
          : undefined,
      };

      // Ajoute le commentaire via l'API
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/client/add-comment`,
        { clientId: currentData._id, comment: commentToAdd },
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );

      // Mets à jour les commentaires avec les données renvoyées par le serveur
      if (response.data) {
        setComments(response.data);
        emitRefreshData();
      }

      setNewComment({ content: "" });
      setAppointmentDate(new Date());
    } catch (err) {
      console.log(err);
    }
  };

  comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return (
    <div className="commentAreaPopupCustomer">
      <textarea
        name="content"
        value={newComment.content}
        onChange={handleChange}
        cols="29"
        rows="5"
        placeholder="Comments"
        className="textAreaCommentPopup"
      ></textarea>
      <div className="planCommentPopup">
        <span>Plan your comment</span>
        <div className="planCommentWrapperPopup">
          <div className="planCommentWrapperContainerPopup">
            <div
              onClick={() => setShowPicker(true)}
              className="datePickerTriggerPopup"
            >
              {formatDate(dateRange[0].startDate)} {time}
            </div>
            {showPicker && (
              <div className="datePickerContainerPopup" ref={pickerRef}>
                <DateRangePicker
                  onChange={handleSelectDate}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={dateRange}
                  direction="horizontal"
                />
              </div>
            )}
            <input type="time" value={time} onChange={handleTimeChange} />
          </div>
          <div className="planCommentButtonPopup">
            <button className="saveButtonPopup" onClick={handleCreateComment}>
              <CheckIcon />
              Add
            </button>
          </div>
        </div>
      </div>
      <div className="commentAreaPopUp">
        <div className="commentAreaWrapperCustomer">
          <div className="commentAreaContainerCustomer">
            {comments.map((comment, index) => (
              <div className="singleCommentCustomer" key={index}>
                <div className="commentNameAndpic">
                  <img
                    src={comment.author?.pPicture || `/images/defaultImg.png`}
                    alt={comment.author?.pPicture || "default"}
                  />
                  <span className="commentAuthor">
                    {comment.author?.name || " "}
                  </span>
                </div>
                <span className="commentDateCustomer">
                  {new Date(comment.createdAt).toLocaleString()}
                </span>
                <span className="commentTextCustomer">{comment.content}</span>
                <div className="commentDeleteCustomer">
                  <span
                    className="deleteLinkCustomer"
                    onClick={() => handleOpenDeleteMessage(comment._id)}
                  >
                    del
                  </span>
                </div>
                {comment.appointment && (
                  <div className="appointmentInCommentCustomer">
                    <QueryBuilderIcon />
                    <span>
                      {moment(comment.appointment.date).format(
                        "DD/MM/YY HH:mm"
                      )}
                    </span>
                  </div>
                )}
              </div>
            ))}
            {openDeleteMessage && (
              <CommentDeleteMessage
                setOpen={setOpenDeleteMessage}
                reFetchFromTable={reFetchFromTable}
                id={selectedDeletedId}
                data={currentData}
                comments={comments}
                setComments={setComments}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
