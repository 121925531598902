import "./CountrySelect.css";
import { countries } from "countries-list";

const CountrySelect = ({ label, className, ...props }) => {
  function convertCountryNameToISO(name) {
    const customMapping = {
      France: "FR",
      Belgique: "BE",
      Suisse: "CH",
      Allemagne: "DE",
    };

    if (customMapping[name]) {
      return customMapping[name];
    }

    let isoCode = null;
    Object.entries(countries).forEach(([code, country]) => {
      if (country.name === name) {
        isoCode = code;
      }
    });

    return isoCode || name;
  }

  const countriesArray = Object.values(countries);

  // Trier les pays par nom, mais s'assurer que France n'est pas inclus
  const sortedCountries = countriesArray
    .filter((country) => country.code !== "FR")
    .sort((a, b) => a.name.localeCompare(b.name));


  return (
    <div className="country">
      <label>
        {label}
        <select className={className} {...props}>
          <option value="default">Select</option>
          {/* France en tant que première option */}
          <option value="FR">France</option>
          {sortedCountries.map((country) => (
            <option
              key={country.code}
              value={convertCountryNameToISO(country.name)}
            >
              {country.name}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default CountrySelect;
