import "./AssignAllLeadCustomers.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";
import AddIcon from "@mui/icons-material/Add";

const AssignAllLeadCustomers = ({
  setOpen,
  reFetchFromTable,
  checkedItems,
  setIsAllChecked,
  setShowLoader,
}) => {
  const { data: users, loading, reFetch: reFetchUsers } = useFetch("user");
  const { data: status } = useFetch("status");
  const [salesSelections, setSalesSelections] = useState([
    { userId: "", number: checkedItems.length },
  ]);
  const [selectedNewStatus, setSelectedNewStatus] = useState("");
  const [checkedDeletedComments, setCheckedDeletedComments] = useState("");

  useEffect(() => {
    setSalesSelections([{ userId: "", number: checkedItems.length }]);
  }, [checkedItems.length]);

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleAddSalesClick = () => {
    setSalesSelections([...salesSelections, { userId: "", number: 0 }]);
  };

  const handleSalesChange = (index, value) => {
    const updatedSalesSelections = salesSelections.map((selection, i) => {
      if (i === index) {
        return { ...selection, userId: value };
      }
      return selection;
    });
    setSalesSelections(updatedSalesSelections);
  };

  const handleNumberChange = (index, value) => {
    let newValue = Math.max(0, Math.min(value, checkedItems.length));
    let updatedSalesSelections = [...salesSelections];
    updatedSalesSelections[index].number = newValue;

    let totalAssigned = updatedSalesSelections.reduce(
      (sum, item) => sum + item.number,
      0
    );
    let remainingLeads = checkedItems.length - totalAssigned;

    // Redistribute remaining leads to other users if necessary
    if (remainingLeads > 0) {
      updatedSalesSelections.forEach((selection, i) => {
        if (i !== index && remainingLeads > 0) {
          let possibleIncrement = Math.min(
            remainingLeads,
            checkedItems.length - selection.number
          );
          updatedSalesSelections[i].number += possibleIncrement;
          remainingLeads -= possibleIncrement;
        }
      });
    } else if (remainingLeads < 0) {
      // Existing logic to reduce the number of leads for other users
      for (let i = 0; i < updatedSalesSelections.length; i++) {
        if (i !== index) {
          const reduction = Math.min(
            updatedSalesSelections[i].number,
            -remainingLeads
          );
          updatedSalesSelections[i].number -= reduction;
          remainingLeads += reduction;
          if (remainingLeads >= 0) {
            break;
          }
        }
      }
    }

    setSalesSelections(updatedSalesSelections);
  };

  const handleCheckedDeleteComments = (e) => {
    setCheckedDeletedComments(e.target.checked);
  };

  const handleAssignRandom = (event) => {
    // Check if the checkbox is checked
    const shouldRandomize = event.target.checked;

    if (shouldRandomize) {
      // Calculate the total number of leads available
      const totalLeads = checkedItems.length;
      // Determine an equal distribution of leads per sales agent
      const leadsPerUser = Math.floor(totalLeads / salesSelections.length);
      // Calculate the number of leads remaining after equal distribution
      let remainingLeads = totalLeads - leadsPerUser * salesSelections.length;

      // Assign an equal number of leads to each sales agent
      const updatedSalesSelections = salesSelections.map((selection) => ({
        ...selection,
        number: leadsPerUser,
      }));

      // Randomly distribute the remaining leads among the sales agents
      while (remainingLeads > 0) {
        // Select a random sales agent
        const randomIndex = Math.floor(
          Math.random() * updatedSalesSelections.length
        );
        // Increment the lead count for the selected agent
        updatedSalesSelections[randomIndex].number += 1;
        // Decrease the count of remaining leads
        remainingLeads -= 1;
      }

      // Update the state with the new distribution of leads
      setSalesSelections(updatedSalesSelections);
    } else {
    }
  };

  const handleValidation = async () => {
    setOpen(false);
    setShowLoader(true);
    try {
      const assignments = salesSelections
        .filter((selection) => selection.userId && selection.number > 0)
        .map((selection) => ({
          userId: selection.userId,
          leadsForUser: checkedItems.splice(0, selection.number),
          newStatus: selectedNewStatus,
          deletedComments: checkedDeletedComments,
        }));

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/reassign`,
        { assignments }
      );

      setShowLoader(false);
      setIsAllChecked(false);
      reFetchFromTable();
    } catch (error) {
      console.error("Error during the reassignment:", error);
    }
  };

  const handleStatusChange = (e) => {
    setSelectedNewStatus(e.target.value);
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="assignAllLead" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="assignAllLeadWrapper">
            <div className="topAssignAllLead">
              <span>Assign Sales</span>
            </div>
            <div className="numberLeadToAssign">
              <span>{checkedItems.length} leads to assign</span>
            </div>
            <div className="middleAssignAllLead">
              <div className="wrapperAssignAllLead">
                <div className="wrapperAssignAllLeadSelect">
                  {salesSelections.map((selection, index) => (
                    <div className="assignAllLeadItem" key={index}>
                      <select
                        value={selection.userId}
                        onChange={(e) =>
                          handleSalesChange(index, e.target.value)
                        }
                      >
                        <option value="">All Sales</option>
                        {users.map(
                          (user) =>
                            (user.isSales || user.isAdmin) && (
                              <option key={user._id} value={user._id}>
                                {user.name}
                              </option>
                            )
                        )}
                      </select>
                      <input
                        type="number"
                        min="0"
                        value={selection.number}
                        onChange={(e) =>
                          handleNumberChange(index, e.target.value)
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="addSalesAgent" onClick={handleAddSalesClick}>
                  <AddIcon />
                  <span>Add sales</span>
                </div>
                <div className="randomizeLeads">
                  <span>Randomize :</span>
                  <input type="checkbox" onChange={handleAssignRandom} />
                </div>
                <div className="randomizeLeads">
                  <span>Delete Comment :</span>
                  <input
                    type="checkbox"
                    onChange={handleCheckedDeleteComments}
                  />
                </div>
                <div className="assignAllLeadItemStatus">
                  <label>Change status</label>
                  <select
                    name="status"
                    onChange={handleStatusChange}
                    defaultValue="default"
                  >
                    <option value="default">Select</option>
                    {status.map((s) => (
                      <>
                        <option key={s._id} value={s._id}>
                          {s.title}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="bottomAssignAllLead">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleValidation}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignAllLeadCustomers;
