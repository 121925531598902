import "./ProjetPage.css";
import axios from "axios";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { ProjetTitles } from "../../../constants/navigationTitles";
import { useCallback, useEffect, useState } from "react";
import { useData } from "../../../context/DataContext";
import ProjetTable from "../../../components/Projet/Projet/ProjetTable/ProjetTable";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";

const ProjetPage = () => {
  const { projects, reFetchProjects, users, reFetchUsers } = useData();

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabs tabs={ProjetTitles} />
        </div>
        <div className="bottomCrmPage">
          <ProjetTable data={projects} reFetch={reFetchProjects} />
        </div>
      </div>
    </div>
  );
};

export default ProjetPage;
