import "./DeleteSigne.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";
import { useState } from "react";

const DeleteSigne = ({
  setOpen,
  reFetchFromTable,

  agence,
}) => {
  const handleDelete = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/${agence._id}`,
        {
          signed: { isSigned: false, signedDate: null },
          percentage: 0,
        }
      );

      reFetchFromTable();
      setOpen(false);
    } catch (err) {
      console.error("Error while deleting signed Agence", err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus">
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>Cancel</span>
            </div>
            <div className="middleCreateStatus">
              <span>Are you sure you want to perform this action?</span>
            </div>
            <div className="bottomCreateStatus">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleDelete}>
                <CheckIcon />
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteSigne;
