import "./ProjetTable.css";
import { handleCheckboxChange } from "../../../../utils/datatable.utils";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import AddIcon from "@mui/icons-material/Add";
import CreateProject from "../CreateProject/CreateProject";
import DeleteMessage from "../../../DeleteMessage/DeleteMessage";
import { useData } from "../../../../context/DataContext";
import PopupProjetContainer from "../PopupProjet/PopupProjetContainer/PopupProjetContainer";

const ProjetTable = ({ data, reFetch }) => {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openCreateProjet, setOpenCreateProjet] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [openPopupProject, setOpenPopupProject] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const { projects, refetchProjects } = useData();

  const tableRef = useRef(null);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };
  const selectCurrentPage = () => {
    const start = currentPage * perPage;
    const end = start + perPage;
    const currentPageItems = data.slice(start, end).map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = () => {
    const allItems = data.map((item) => item._id);
    setCheckedItems(allItems);
  };

  const handleOpenDeleteMessage = (projetId) => {
    const findCorrespondingProject = projects.find((p) => p._id === projetId);
    if (findCorrespondingProject.apartments.length > 0) {
      alert(
        "Ce projet contient des appartments, veuillez les supprimez avant."
      );
    } else {
      setOpenDeleteMessage(true);
      setSelectedDeletedId(projetId);
    }
  };

  const handleOpenPopupProject = (data) => {
    setSelectedData(data);
    setOpenPopupProject(true);
  };

  const totalAppartements = data.flatMap((p) => p.apartments);
  const soldAppartments = totalAppartements.filter((a) => a.isSold);
  const soldAppartmentValue = soldAppartments.reduce(
    (acc, apartment) => acc + apartment.price,
    0
  );

  const totalSoldAppartmentPayment = soldAppartments.reduce(
    (acc, apartment) => {
      const totalPaymentForApartment = apartment.payment.reduce(
        (sum, payment) => sum + payment.amount,
        0
      );
      return acc + totalPaymentForApartment;
    },
    0
  );

  const totalValueForAllAppartments = totalAppartements.reduce(
    (acc, apartment) => acc + apartment.price,
    0
  );

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>
                Projets : {data.length} &nbsp;&nbsp; - &nbsp;Appartements :{" "}
                {totalAppartements.length}
                &nbsp;&nbsp; - &nbsp; Vendu : {soldAppartments.length}{" "}
                &nbsp;&nbsp; - &nbsp; En vente :{" "}
                {totalAppartements.length - soldAppartments.length}
                &nbsp;&nbsp; - &nbsp;&nbsp;Paiement / Vendu :{" "}
                {totalSoldAppartmentPayment.toLocaleString("en-US", {
                  style: "currency",
                  currency: "EUR",
                })}{" "}
                &nbsp;/&nbsp;{" "}
                {soldAppartmentValue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "EUR",
                })}
                &nbsp;&nbsp; - &nbsp;&nbsp;Vendu / Total :{" "}
                {soldAppartmentValue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "EUR",
                })}
                &nbsp;&nbsp; /{" "}
                {totalValueForAllAppartments.toLocaleString("en-US", {
                  style: "currency",
                  currency: "EUR",
                })}
              </span>
            </div>
            <div className="rightElements">
              <button onClick={() => setOpenCreateProjet(true)}>
                <AddIcon />
                <span>Crée Projet</span>
              </button>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />
                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">Projet</div>
                </th>
                <th>
                  <div className="headerWithIcon">Appartements</div>
                </th>
                <th>
                  <div className="headerWithIcon">Paiement / Vendu</div>
                </th>
                <th>
                  <div className="headerWithIcon">Vendu / Total</div>
                </th>
                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                .slice(currentPage * perPage, (currentPage + 1) * perPage)
                .map((project, index) => {
                  // Calculate per-project values
                  // Calculate per-project values
                  const soldAppartments = project.apartments.filter(
                    (a) => a.isSold
                  );

                  const soldAppartmentValue = soldAppartments.reduce(
                    (acc, apartment) => acc + apartment.price,
                    0
                  );

                  const totalSoldAppartmentPayment = soldAppartments.reduce(
                    (acc, apartment) => {
                      const totalPaymentForApartment = apartment.payment.reduce(
                        (sum, payment) => sum + payment.amount,
                        0
                      );
                      return acc + totalPaymentForApartment;
                    },
                    0
                  );

                  // Calculate total values for all apartments (sold and unsold)
                  const totalValueForProject = project.apartments.reduce(
                    (sum, apartment) => sum + apartment.price,
                    0
                  );

                  return (
                    <tr key={index}>
                      <td>
                        <label className="container">
                          <input
                            type="checkbox"
                            checked={checkedItems.includes(project.leadId)}
                            onChange={(e) =>
                              handleCheckboxChangeWrapper(e, project.leadId)
                            }
                          />
                          <div className="checkmark"></div>
                        </label>
                      </td>

                      <td>
                        <div className="nameAndStatus">
                          <span style={{ color: "#00B161" }}>
                            {project.name}
                          </span>
                        </div>
                      </td>

                      <td>
                        {project.apartments.length} appartements
                        &nbsp;&nbsp;-&nbsp;&nbsp;
                        {soldAppartments.length} vendu &nbsp;&nbsp;-&nbsp;{" "}
                        {project.apartments.length - soldAppartments.length} en
                        vente{" "}
                      </td>

                      <td className="progressContainerProjetTd">
                        <div className="tdProgressBar">
                          <div className="progressContainerProjet">
                            {soldAppartments.flatMap((apartment) =>
                              apartment.payment.map(
                                (payment, paymentIndex, array) => {
                                  const percentage =
                                    (payment.amount / soldAppartmentValue) *
                                    100;

                                  let backgroundColor;
                                  if (array.length === 1) {
                                    // Si un seul paiement, appliquer la couleur de départ
                                    backgroundColor = "#00B161";
                                  } else {
                                    const startColor = "#00B161";
                                    const endColor = "#00C6FF";
                                    const colorStep =
                                      paymentIndex / (array.length - 1);
                                    backgroundColor = `rgba(${Math.round(
                                      (1 - colorStep) *
                                        parseInt(startColor.slice(1, 3), 16) +
                                        colorStep *
                                          parseInt(endColor.slice(1, 3), 16)
                                    )}, ${Math.round(
                                      (1 - colorStep) *
                                        parseInt(startColor.slice(3, 5), 16) +
                                        colorStep *
                                          parseInt(endColor.slice(3, 5), 16)
                                    )}, ${Math.round(
                                      (1 - colorStep) *
                                        parseInt(startColor.slice(5, 7), 16) +
                                        colorStep *
                                          parseInt(endColor.slice(5, 7), 16)
                                    )}, 0.8)`;
                                  }

                                  return (
                                    <div
                                      key={paymentIndex}
                                      className="progressSegment"
                                      style={{
                                        width: `${percentage}%`,
                                        backgroundColor,
                                      }}
                                    >
                                      <div className="paymentTooltip">
                                        <span>
                                          {payment.amount.toLocaleString(
                                            "en-US"
                                          )}{" "}
                                          € -{" "}
                                          {new Date(
                                            payment.paymentDate
                                          ).toLocaleDateString()}{" "}
                                          - {payment.client.name}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            )}
                            <div className="progressPriceProjet">
                              {totalSoldAppartmentPayment.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}{" "}
                              € /{" "}
                              {soldAppartmentValue.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                              €
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="progressContainerProjetTd">
                        <div className="tdProgressBar">
                          <div className="progressContainerProjet">
                            <div
                              className="progressSegment"
                              style={{
                                width: `${
                                  (soldAppartmentValue / totalValueForProject) *
                                  100
                                }%`,
                                backgroundColor: "rgb(62 62 62)",
                              }}
                            ></div>
                            <div className="progressPriceProjet">
                              {soldAppartmentValue.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                              € /{" "}
                              {totalValueForProject.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                              €
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <span
                          className="hover-effect"
                          onClick={() => handleOpenDeleteMessage(project._id)}
                        >
                          del
                        </span>
                      </td>
                    </tr>
                  );
                })}
              {data.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {data.length > 0 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(data.length / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}

          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={data}
              pageData={data}
              setIsAllChecked={setIsAllChecked}
            />
          )}
          {openCreateProjet && (
            <CreateProject
              setOpen={setOpenCreateProjet}
              reFetchFromTable={reFetch}
            />
          )}
          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              endPoint={"project"}
              id={selectedDeletedId}
            />
          )}
          {/* {openPopupProject && (
            <PopupProjetContainer
              setOpen={setOpenPopupProject}
              data={selectedData}
              reFetchFromTable={reFetch}
              projects={projects}
              refetchProjects={refetchProjects}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ProjetTable;
