import "./ImportLeadCSV.css";
import React, { useState, useCallback, useRef } from "react";
import Papa from "papaparse";
import useFetch from "../../../../../hooks/useFetch";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import { countries } from "countries-list";
const ImportLeadCSV = ({
  setOpen,
  setShowLoader,
  reFetchFromTable,
  reFetchAllLead,
  setIsAllChecked,
}) => {
  const { data: leads, reFetch } = useFetch("agence");
  const [csvData, setCsvData] = useState([]);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [fileName, setFileName] = useState("");
  const [numberOfLead, setNumberOfLeads] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedHeaders, setSelectedHeaders] = useState({
    AgencyName: "",
    Name: { firstName: "", lastName: "" },
    Mail: "",
    Phone: "",
    Country: "",
  });

  function convertCountryNameToISO(name) {
    const customMapping = {
      France: "FR",
      Belgique: "BE",
      Suisse: "CH",
      Allemagne: "DE",
      Espagne: "ES",
      Italie: "IT",
      Royaume_Uni: "GB",
      Portugal: "PT",
      Pays_Bas: "NL",
      Suède: "SE",
      Norvège: "NO",
      Danemark: "DK",
      Finlande: "FI",
      Irlande: "IE",
      Pologne: "PL",
      Autriche: "AT",
      Hongrie: "HU",
      République_Tchèque: "CZ",
      Slovaquie: "SK",
      Slovénie: "SI",
      Croatie: "HR",
      Bosnie_Herzégovine: "BA",
      Serbie: "RS",
      Monténégro: "ME",
      Kosovo: "XK",
      Albanie: "AL",
      Grèce: "GR",
      Chypre: "CY",
      Bulgarie: "BG",
      Roumanie: "RO",
      Lituanie: "LT",
      Lettonie: "LV",
      Estonie: "EE",
      Russie: "RU",
      Ukraine: "UA",
      Biélorussie: "BY",
      Moldavie: "MD",
      Géorgie: "GE",
      Arménie: "AM",
      Azerbaïdjan: "AZ",
      Turquie: "TR",
      États_Unis: "US",
      Canada: "CA",
      Mexique: "MX",
      Brésil: "BR",
      Argentine: "AR",
      Chili: "CL",
      Pérou: "PE",
      Colombie: "CO",
      Venezuela: "VE",
      Uruguay: "UY",
      Paraguay: "PY",
      Bolivie: "BO",
      Équateur: "EC",
      Guyana: "GY",
      Suriname: "SR",
      Jamaïque: "JM",
      Cuba: "CU",
      Haïti: "HT",
      République_Dominicaine: "DO",
      Australie: "AU",
      Nouvelle_Zélande: "NZ",
      Japon: "JP",
      Chine: "CN",
      Inde: "IN",
      Pakistan: "PK",
      Bangladesh: "BD",
      Sri_Lanka: "LK",
      Népal: "NP",
      Bhoutan: "BT",
      Maldives: "MV",
      Thaïlande: "TH",
      Vietnam: "VN",
      Laos: "LA",
      Cambodge: "KH",
      Malaisie: "MY",
      Singapour: "SG",
      Indonésie: "ID",
      Philippines: "PH",
      Birmanie: "MM",
      Brunei: "BN",
      Timor_Oriental: "TL",
      Corée_du_Sud: "KR",
      Corée_du_Nord: "KP",
      Mongolie: "MN",
      Kazakhstan: "KZ",
      Ouzbékistan: "UZ",
      Turkménistan: "TM",
      Tadjikistan: "TJ",
      Kirghizistan: "KG",
      Afghanistan: "AF",
      Iran: "IR",
      Irak: "IQ",
      Syrie: "SY",
      Liban: "LB",
      Israël: "IL",
      Jordanie: "JO",
      Arabie_Saoudite: "SA",
      Yémen: "YE",
      Oman: "OM",
      Émirats_Arabes_Unis: "AE",
      Qatar: "QA",
      Bahreïn: "BH",
      Koweït: "KW",
      Égypte: "EG",
      Libye: "LY",
      Tunisie: "TN",
      Algérie: "DZ",
      Maroc: "MA",
      Mauritanie: "MR",
      Mali: "ML",
      Niger: "NE",
      Tchad: "TD",
      Soudan: "SD",
      Soudan_du_Sud: "SS",
      Érythrée: "ER",
      Djibouti: "DJ",
      Somalie: "SO",
      Éthiopie: "ET",
      Kenya: "KE",
      Ouganda: "UG",
      Rwanda: "RW",
      Burundi: "BI",
      Tanzanie: "TZ",
      Zambie: "ZM",
      Angola: "AO",
      Mozambique: "MZ",
      Madagascar: "MG",
      Zimbabwe: "ZW",
      Botswana: "BW",
      Namibie: "NA",
      Afrique_du_Sud: "ZA",
      Lesotho: "LS",
      Eswatini: "SZ",
      Cap_Vert: "CV",
      Sénégal: "SN",
      Gambie: "GM",
      Guinée: "GN",
      Sierra_Leone: "SL",
      Libéria: "LR",
      Côte_d_Ivoire: "CI",
      Ghana: "GH",
      Togo: "TG",
      Bénin: "BJ",
      Burkina_Faso: "BF",
      Cameroun: "CM",
      Gabon: "GA",
      Guinée_Équatoriale: "GQ",
      Congo_Brazzaville: "CG",
      Congo_Kinshasa: "CD",
      Centrafrique: "CF",
      Guinée_Bissau: "GW",
      Sao_Tomé_et_Príncipe: "ST",
      Malawi: "MW",
      Seychelles: "SC",
      Comores: "KM",
      Maurice: "MU",
    };
  
    if (customMapping[name]) {
      return customMapping[name];
    }
  
    let isoCode = null;
    Object.entries(countries).forEach(([code, country]) => {
      if (country.name === name) {
        isoCode = code;
      }
    });
  
    return isoCode || name;
  }
  
  const formatLeads = () => {
    return csvData.map((row) => {
      return {
        name: `${row[selectedHeaders.Name.firstName] || ""} ${
          row[selectedHeaders.Name.lastName] || ""
        }`.trim(),
        email: row[selectedHeaders.Mail],
        phone: row[selectedHeaders.Phone],
        agencyName: row[selectedHeaders.AgencyName],
        country: convertCountryNameToISO(row[selectedHeaders.Country]),
      };
    });
  };

  const checkDoublons = () => {
    const importedLeads = formatLeads();

    let doublonsDansImport = 0;
    let phoneVus = new Set();

    importedLeads.forEach((lead) => {
      const phone = lead.phone;
      if (phone && phoneVus.has(phone)) {
        doublonsDansImport++;
      } else if (phone) {
        phoneVus.add(phone);
      }
    });
    const doublonsAvecDB = Array.isArray(leads)
      ? leads.filter((existingLead) => phoneVus.has(existingLead.phone))
      : [];

    const nombreDeDoublonsAvecDB = doublonsAvecDB.length;

    return { doublonsDansImport, nombreDeDoublonsAvecDB };
  };

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsFileLoaded(true);
    setFileName(file.name);
    setNumberOfLeads(file.length);

    const fileType = file.type || file.name.split(".").pop().toLowerCase();
    switch (fileType) {
      case "text/csv":
      case "csv":
        parseCSV(file);
        break;
      case "text/tab-separated-values":
      case "tsv":
        parseTSV(file);
        break;
      default:
        console.error("Type de fichier non pris en charge");
      // Gérer les types de fichiers non pris en charge
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        setCsvHeaders(result.meta.fields);
        setCsvData(result.data);
      },
    });
  };

  const parseTSV = (file) => {
    Papa.parse(file, {
      header: true,
      delimiter: "\t",
      complete: (result) => {
        setCsvHeaders(result.meta.fields);
        setCsvData(result.data);
      },
    });
  };

  const handleHeaderMappingChange = (header, csvHeader, subfield = null) => {
    if (subfield) {
      setSelectedHeaders((prev) => ({
        ...prev,
        [header]: { ...prev[header], [subfield]: csvHeader },
      }));
    } else {
      setSelectedHeaders({ ...selectedHeaders, [header]: csvHeader });
    }
  };

  // Vérifie les doublons dans la liste CSV
  const checkForDuplicatesInCSV = (phone, rowIndex) => {
    if (!phone) return false; // S'assurer que l'phone est valide

    return csvData.some((row, index) => {
      return index !== rowIndex && row[selectedHeaders.Phone] === phone;
    });
  };

  // Vérifie les doublons dans les leads existants
  const checkForDuplicatesInLeads = (phone) => {
    if (!phone) return false; // S'assurer que le telephone est valide

    return Array.isArray(leads) && leads.some((lead) => lead.phone === phone);
  };

  const createTableRows = () => {
    return csvData.map((row, rowIndex) => {
      const phone = row[selectedHeaders.Phone];
      const isDuplicateInCSV = phone
        ? checkForDuplicatesInCSV(phone, rowIndex)
        : false;
      const isDuplicateInLeads = phone
        ? checkForDuplicatesInLeads(phone)
        : false;
      const isDuplicate = isDuplicateInCSV || isDuplicateInLeads;

      return (
        <tr key={rowIndex} className={isDuplicate ? "duplicate" : ""}>
          {Object.entries(selectedHeaders).map(
            ([header, mapping], colIndex) => {
              let cellValue = "";
              if (header === "Name") {
                cellValue = `${row[mapping.firstName] || ""} ${
                  row[mapping.lastName] || ""
                }`.trim();
              } else if (mapping) {
                cellValue = row[mapping];
              }
              return <td key={colIndex}>{cellValue}</td>;
            }
          )}
        </tr>
      );
    });
  };

  const handleCreateListOfLead = async () => {
    const formattedLeads = formatLeads();
    let phoneTraites = new Set();
    let leadsToSend = [];

    formattedLeads.forEach((lead) => {
      if (!phoneTraites.has(lead.phone)) {
        phoneTraites.add(lead.phone);
        leadsToSend.push(lead); 
      }
    });

    setOpen(false);
    setShowLoader(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/batchCreate`,
        { agences: leadsToSend }
      );
      setShowLoader(false);
      reFetchFromTable();
      reFetchAllLead();
      setIsAllChecked(false);
    } catch (err) {
      console.error("Error while sending leads for processing:", err);
    }
  };

  const { doublonsDansImport, nombreDeDoublonsAvecDB } = checkDoublons();

  return (
    <>
      <div className="overlay"></div>
      <div className="popUpImportLeadCsv">
        <div className="closeIconContainer">
          <CancelIcon onClick={() => setOpen(false)} />
        </div>
        <div
          className={`importContainer ${isFileLoaded ? "afterFileLoad" : ""}`}
        >
          {!isFileLoaded && (
            <div className="fileUploadContainer">
              <button
                className="fileUploadButton"
                onClick={() => fileInputRef.current.click()}
              >
                Importer un fichier (.csv, .tsv)
              </button>
              <input
                ref={fileInputRef}
                type="file"
                accept=".csv, .tsv"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
          )}
          {isFileLoaded && (
            <div className="topImportContainer">
              <span>
                Fichier Importé :
                <span style={{ color: "var(--primary-color)" }}>
                  {fileName}
                </span>
              </span>
            </div>
          )}

          <div
            className={`bottomImportContainer ${
              isFileLoaded ? "afterFileLoad" : ""
            }`}
          >
            {isFileLoaded && (
              <div className="headerMappingContainer">
                {Object.keys(selectedHeaders).map((header) => (
                  <div key={header} className="headerMapping">
                    <span>{header}: </span>
                    {header === "Name" ? (
                      <>
                        <select
                          onChange={(e) =>
                            handleHeaderMappingChange(
                              header,
                              e.target.value,
                              "firstName"
                            )
                          }
                        >
                          <option value="Select">Select</option>
                          {csvHeaders.map((h) => (
                            <option key={h} value={h}>
                              {h}
                            </option>
                          ))}
                        </select>
                        <select
                          onChange={(e) =>
                            handleHeaderMappingChange(
                              header,
                              e.target.value,
                              "lastName"
                            )
                          }
                        >
                          <option value="Select">Select</option>
                          {csvHeaders.map((h) => (
                            <option key={h} value={h}>
                              {h}
                            </option>
                          ))}
                        </select>
                      </>
                    ) : (
                      <select
                        onChange={(e) =>
                          handleHeaderMappingChange(header, e.target.value)
                        }
                      >
                        <option value="Select">Select</option>
                        {csvHeaders.map((h) => (
                          <option key={h} value={h}>
                            {h}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                ))}
              </div>
            )}

            {isFileLoaded && (
              <div className="tableContainer">
                <table className="previewTable">
                  <thead>
                    <tr>
                      {Object.keys(selectedHeaders).map((header) => (
                        <th key={header}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{createTableRows()}</tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="separatordiv">
          <div className="linediv"></div>
        </div>
        <div className="rightDivImportCsbLead">
          <div className="numberOfleadDisplay">
            <span>Nombre de leads</span>
            <span> {csvData.length}</span>
            <span> Nombre de doublons</span>
            <span
              style={{ color: "#e76161", fontWeight: "bold", fontSize: 18 }}
            >
              {nombreDeDoublonsAvecDB}
            </span>
            <span> Nombre de doublons dans l'import</span>
            <span
              style={{ color: "#e76161", fontWeight: "bold", fontSize: 18 }}
            >
              {doublonsDansImport}
            </span>
          </div>

          <button onClick={handleCreateListOfLead}>
            Enregistrer les Leads
          </button>
        </div>
      </div>
    </>
  );
};

export default ImportLeadCSV;
