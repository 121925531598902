import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SERVER_URL);
    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, []);

  const emitDeposit = () => {
    if (socket) socket.emit("deposit");
  };
  const emitRefreshData = () => {
    if (socket) socket.emit("refreshData");
  };

  const emitRefreshDataPlatform = () => {
    if (socket) socket.emit("refreshDataPlatform");
  };
  const emitAddBalanceToPayment = () => {
    if (socket) socket.emit("addBalanceToPayment");
  };

  const emitOfferUpdated = () => {
    if (socket) socket.emit("offerUpdated");
  };
  const emitProductAddedToSafe = () => {
    if (socket) socket.emit("productAddedToSafe");
  };
  const emitWithdrawal = () => {
    if (socket) socket.emit("withdrawal");
  };

  const emitLeadConnected = (email) => {
    if (socket) socket.emit("leadConnected", { email });
  };

  const emitLeadPasswordChanged = (leadId) => {
    if (socket) socket.emit("leadPasswordChanged", { leadId });
  };

  const emitCurrentLeadStatus = () => {
    if (socket) socket.emit("requestCurrentLeadsStatus");
  };

  const emitPromoteUpdated = () => {
    if (socket) socket.emit("promoteUpdated"); 
  };
  return (
    <SocketContext.Provider
      value={{
        socket,
        emitDeposit,
        emitRefreshDataPlatform,
        emitLeadConnected,
        emitOfferUpdated,
        emitCurrentLeadStatus,
        emitWithdrawal,
        emitRefreshData,
        emitProductAddedToSafe,
        emitAddBalanceToPayment,
        emitLeadPasswordChanged,
        emitPromoteUpdated,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
