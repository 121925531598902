import "./EditAccount.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState, useEffect } from "react";
import axios from "axios";
import useFetch from "../../../hooks/useFetch";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PicturesLibrary from "../PicturesLibrary/PicturesLibrary";

const EditAccount = ({ setOpen, reFetchFromTable, id }) => {
  const { data: users } = useFetch("user");
  const [openPicturesLibrary, setOpenPicturesLibrary] = useState(false);
  const [error, setError] = useState({});
  const [editUserData, setEditUserData] = useState({
    name: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    pPicture: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const userToEdit = users.find((user) => user._id === id);
    if (userToEdit) {
      const splitName = userToEdit.name.split(" ");
      setEditUserData({
        name: splitName[0],
        lastName: splitName.slice(1).join(" ") || "",
        username: userToEdit.username,
        email: userToEdit.email,
        pPicture: userToEdit.pPicture,
      });
    }
  }, [id, users]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (error[name]) {
      setError((prevError) => ({ ...prevError, [name]: "" }));
    }
  };

  const handleEditUser = async (e) => {
    e.preventDefault();

    const combinedName = `${editUserData.name} ${editUserData.lastName}`.trim();

    const updatedUser = {
      ...editUserData,
      name: combinedName,
    };

    if (selectedImage) {
      updatedUser.pPicture = selectedImage;
    }
    delete updatedUser.lastName;
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/user/${id}`,
        updatedUser
      );
      setOpen(false);
      reFetchFromTable();
    } catch (err) {
      console.error("Error updating users", err.response?.data || err.message);
    }
  };

  const handleClickInside = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createUser" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createUserWrapper">
            <div className="topCreateStatus">
              <span>Edit Account</span>
            </div>
            <div className="profilepictureAdd">
              {selectedImage ? (
                <div className="profilePictureContainer">
                  <img src={selectedImage} alt="" />
                  <div
                    className="editImagesContainer"
                    onClick={() => setOpenPicturesLibrary(true)}
                  >
                    <EditIcon />
                  </div>
                </div>
              ) : (
                <div className="profilePictureContainer">
                  <img src={editUserData.pPicture} alt="" />
                  <div
                    className="editImagesContainer"
                    onClick={() => setOpenPicturesLibrary(true)}
                  >
                    <EditIcon />
                  </div>
                </div>
              )}
              {/* {selectedImage && (
                <div className="profilePictureContainer">
                  <img src={selectedImage} alt="" />
                  <div className="editImagesContainer">
                    <EditIcon />
                  </div>
                </div>
              )} */}
            </div>
            <div className="middleCreateUser">
              <div className="inputRow">
                <div className="inputGroup">
                  <input
                    type="text"
                    name="name"
                    value={editUserData.name}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                  {error.name && (
                    <div className="errormessage">{error.name}</div>
                  )}
                </div>

                <div className="inputGroup">
                  <input
                    type="text"
                    name="lastName"
                    value={editUserData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                  {error.lastName && (
                    <div className="errormessage">{error.lastName}</div>
                  )}
                </div>
              </div>

              <div className="inputRow">
                <div className="inputGroup">
                  <input
                    type="text"
                    name="username"
                    value={editUserData.username}
                    onChange={handleChange}
                    placeholder="Username"
                  />
                  {error.username && (
                    <div className="errormessage">{error.username}</div>
                  )}
                </div>

                <div className="inputGroup">
                  <input
                    type="text"
                    name="extension"
                    value={editUserData.extension}
                    onChange={handleChange}
                    placeholder="Extention"
                  />
                </div>
              </div>

              <div className="fullWidthInput">
                <input
                  type="text"
                  name="password"
                  value={editUserData.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
              </div>
              <div className="fullWidthInput">
                <input
                  type="text"
                  name="email"
                  value={editUserData.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                {error.email && (
                  <div className="errormessage">{error.email}</div>
                )}
              </div>
            </div>

            <div className="bottomCreateUser">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleEditUser}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
        {openPicturesLibrary && (
          <PicturesLibrary
            setOpen={setOpenPicturesLibrary}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        )}
      </div>
    </>
  );
};

export default EditAccount;
