import "./AddPayment.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../../../hooks/useFetch";

const AddPayment = ({ setOpen, currentData, refetch }) => {
  const { data: payments } = useFetch("payment");

  const [newPayment, setNewPayment] = useState({
    amount: 0,
    paymentDate: new Date().toISOString(),
    typePayment: "",
  });

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setNewPayment({ ...newPayment, [name]: value });
  };

  const handleAddNewPaymentAgence = async () => {
    let paymentAgenceDateTime = new Date(newPayment.paymentDate);
    const now = new Date();
    paymentAgenceDateTime.setHours(
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    );

    const updatePayment = {
      amount: parseFloat(newPayment.amount),
      paymentDate: newPayment.paymentDate,
      typePayment: newPayment.typePayment,
      agence: currentData._id,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/paymentAgence`,
        updatePayment
      );

      refetch();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createDeposit" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createDepositWrapper">
            <div className="topCreateStatus">
              <span>Ajouter un paiement</span>
            </div>
            <div className="middleCreateDeposit">
              <label>Type Payment</label>
              <select
                className="depositSelect"
                onChange={handleChange}
                name="typePayment"
              >
                <option value="Select">Select</option>
                {payments &&
                  payments.map((payment) => (
                    <option key={payment._id} value={payment._id}>
                      {payment.title}
                    </option>
                  ))}
              </select>
              <label>Amount</label>
              <input type="number" name="amount" onChange={handleChange} />
              <label>Date</label>
              <input
                type="date"
                name="paymentDate"
                value={newPayment.paymentDate}
                onChange={handleChange}
              />
            </div>
            <div className="bottomCreateDeposit">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button
                className="saveButton"
                type="submit"
                onClick={handleAddNewPaymentAgence}
              >
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPayment;
