import "./SignePopUp.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import axios from "axios";
import { useData } from "../../../../context/DataContext";

const SignePopUp = ({ setOpen, data, reFetchFromTable }) => {
  const [updatePercentage, setPercentage] = useState("");
  const { reFetchAllLead } = useData();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/agence/${data._id}`,
        {
          percentage: Number(updatePercentage),
          signed: {
            isSigned: true,
            signedDate: new Date(),
          },
        }
      );
      reFetchFromTable();
      reFetchAllLead();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div
          className="createSignature"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="closeIconStatus">
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>Signature</span>
            </div>
            <div className="middleCreateStatus">
              <div className="containerSignature">
                <div className="containerSignatureTop">
                  <div className="containerSignatureLeft">
                    <div className="logoSignatureAgence"></div>
                  </div>
                  <div className="containerSignatureRight">
                    <span>{data.agencyName}</span>
                    <span>{data.email || "No email"}</span>
                  </div>
                </div>
                <div className="containerSignatureBottom">
                  <div className="containerSignatureBottomLeft">
                    <span>Indiquez le pourcentage</span>
                    <div className="inputWithPercentage">
                      <input
                        type="text"
                        placeholder="0"
                        name="percentage"
                        onChange={handlePercentageChange}
                      />
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottomCreateStatus">
              <button
                className="cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button className="saveButton" onClick={handleSubmit}>
                <CheckIcon />
                Comfirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignePopUp;
