import "./CreateApartment.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useState } from "react";
import axios from "axios";
import { countries } from "countries-list";
import useFetch from "../../../../../hooks/useFetch";
import { useData } from "../../../../../context/DataContext";
const CreateApartment = ({ setOpen, reFetchFromTable }) => {
  const { data: projects } = useFetch("project");
  const [annexeOptions, setAnnexeOptions] = useState([]);
  const [outsideOptions, setOutsideOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const { reFetchProjects } = useData();
  const [imagePreviews, setImagePreviews] = useState([]);
  const [accessibilityOptions, setAccessibilityOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [showBalconySurfaceInput, setShowBalconySurfaceInput] = useState(false);
  const [newApartment, setNewApartment] = useState({
    reference: "",
    type: "",
    surface: "",
    landArea: "",
    rooms: "",
    bedrooms: "",
    bathrooms: "",
    annexe: [],
    outside: [],
    accessibility: [],
    balconySurface: "",
    price: "",
  });

  //Images handler
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    // Convertir les fichiers en URLs utilisables pour les aperçus
    const filePreviews = files.map((file) => URL.createObjectURL(file));

    setImagePreviews((prevPreviews) => [...prevPreviews, ...filePreviews]);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const wrapperStyle =
    images.length > 0
      ? "imageUploadSectionWrapper start"
      : "imageUploadSectionWrapper";

  const handleRemoveImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };
  const handleProjectChange = (event) => {
    const projectId = event.target.value;
    const project = projects.find((p) => p._id === projectId);
    setSelectedProject(project._id);
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      if (name === "annexe") {
        handleAnnexeOptionChange(value);
      } else if (name === "outside") {
        handleOutsideOptionChange(value);
      } else if (name === "accessibility") {
        handleAccessibilityOptionChange(value);
      }
    } else {
      setNewApartment((prevApartment) => ({
        ...prevApartment,
        [name]: value,
      }));
    }
  };

  const handleCreateApartment = async () => {
    try {
      const formData = new FormData();

      // Ajouter chaque image au FormData
      images.forEach((image) => {
        formData.append("images", image);
      });

      // Ajouter les autres données de l'appartement
      formData.append("appartment", JSON.stringify(newApartment)); // Convertir l'appartement en JSON
      formData.append("projectId", selectedProject); // Ajouter le projectId

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/apartment/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      reFetchProjects();
      reFetchFromTable();
      setOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleAnnexeOptionChange = (option) => {
    setAnnexeOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );

    setNewApartment((prev) => ({
      ...prev,
      annexe: prev.annexe.includes(option)
        ? prev.annexe.filter((item) => item !== option)
        : [...prev.annexe, option],
    }));
  };

  const handleOutsideOptionChange = (option) => {
    setOutsideOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );

    setNewApartment((prev) => ({
      ...prev,
      outside: prev.outside.includes(option)
        ? prev.outside.filter((item) => item !== option)
        : [...prev.outside, option],
    }));

    if (option === "Terrasse/Balcon") {
      setShowBalconySurfaceInput(!showBalconySurfaceInput);
    }
  };

  const handleAccessibilityOptionChange = (option) => {
    setAccessibilityOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );

    setNewApartment((prev) => ({
      ...prev,
      accessibility: prev.accessibility.includes(option)
        ? prev.accessibility.filter((item) => item !== option)
        : [...prev.accessibility, option],
    }));
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}></div>
      <div className="createApartment">
        <div className="closeIconStatus">
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        <div className="topCreateApartment">
          <span>Add Apartment</span>
        </div>
        <div className="createApartmentWrapper">
          <div className="middleCreateApartment">
            <div className="imageUploadSection">
              <div className={wrapperStyle}>
                {imagePreviews.map((preview, index) => (
                  <div className="uploadedImageContainer" key={index}>
                    <div
                      className="imageHoverOverlay"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <DeleteIcon className="deleteIcon" />
                    </div>
                    <img src={preview} alt="Uploaded" />
                  </div>
                ))}
                <div className="buttonUploadImage">
                  <label htmlFor="image-upload" className="uploadButton">
                    <CloudDownloadIcon />
                    <span>Upload images</span>
                  </label>
                  <input
                    type="file"
                    id="image-upload"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>

            <div className="inputRow">
              <label>Reference</label>
              <input type="text" name="reference" onChange={handleChange} />
              <label>Type</label>
              <select
                name="type"
                defaultValue="default"
                onChange={handleChange}
              >
                <option value="default" disabled>
                  Select
                </option>
                <option value="Apartment">Apartment</option>
                <option value="Penthouse">Penthouse</option>
                <option value="Local">Local</option>
                <option value="Loft">Loft</option>
                <option value="Home">Home</option>
                <option value="Studio">Studio</option>
              </select>
            </div>
            <div className="inputRow">
              <label>Surface</label>
              <input type="number" name="surface" onChange={handleChange} />
              <label>Land Area</label>
              <input type="number" name="landArea" onChange={handleChange} />
            </div>
            <div className="inputRow">
              <label>Rooms</label>
              <input type="number" name="rooms" onChange={handleChange} />
              <label>Bedrooms</label>
              <input type="number" name="bedrooms" onChange={handleChange} />
            </div>
            <div className="inputRow">
              <label>Bathrooms</label>
              <input type="number" name="bathrooms" onChange={handleChange} />
              <label>Floors</label>
              <input type="number" name="floor" onChange={handleChange} />
            </div>
            {showBalconySurfaceInput && (
              <div className="inputRow">
                <label>Surface du Balcon</label>
                <input
                  type="number"
                  name="balconySurface"
                  value={newApartment.balconySurface}
                  onChange={handleChange}
                />
              </div>
            )}
            {/* Checkbox */}
            <div className="checkboxSection">
              <div className="checkboxGroup">
                <label className="checkboxTitle">Annexe:</label>
                <div className="checkboxOptions">
                  {["Box", "Cave", "Garage", "Parking", "Sous-sol"].map(
                    (option) => (
                      <div key={option} className="checkboxItem">
                        <input
                          type="checkbox"
                          checked={annexeOptions.includes(option)}
                          onChange={() => handleAnnexeOptionChange(option)}
                        />
                        <label>{option}</label>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className="checkboxGroup">
                <label className="checkboxTitle">Accessibility:</label>
                <div className="checkboxOptions">
                  {["Accès Handicapé", "Ascenseur"].map((option) => (
                    <div key={option} className="checkboxItem">
                      <input
                        type="checkbox"
                        checked={accessibilityOptions.includes(option)}
                        onChange={() => handleAccessibilityOptionChange(option)}
                      />
                      <label>{option}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="checkboxGroup">
                <label className="checkboxTitle">Outside:</label>
                <div className="checkboxOptions">
                  {["Jardin", "Piscine", "Terrasse/Balcon"].map((option) => (
                    <div key={option} className="checkboxItem">
                      <input
                        type="checkbox"
                        checked={outsideOptions.includes(option)}
                        onChange={() => handleOutsideOptionChange(option)}
                      />
                      <label>{option}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="inputRow">
            <label>Price</label>
            <input type="number" name="price" onChange={handleChange} />
          </div>
          <div className="bottomTitleCreateApartment">
            <span>Assign to a project</span>
          </div>

          <div className="inputRowBottom">
            <label>Select Project</label>
            <select
              name="project"
              defaultValue="default"
              onChange={handleProjectChange}
            >
              <option value="default" disabled>
                Select
              </option>
              {projects.map((project) => (
                <option key={project._id} value={project._id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="bottomCreateApartment">
          <button className="cancelButton" onClick={() => setOpen(false)}>
            Cancel
          </button>
          <button className="saveButton" onClick={handleCreateApartment}>
            <CheckIcon />
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateApartment;
