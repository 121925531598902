import { useEffect } from "react";
import "./PacmanLoaderPopUp.css";
import PacmanLoader from "react-spinners/PacmanLoader";

const PacmanLoaderPopUp = () => {

  return (
    <>
      <div className="overlay"></div>
      <div className="pacmanloader">
        <div className="topPacman">
          <PacmanLoader size={35} color="#36d7b7" cssOverride={{position: "relative", display: "flex"}}/>
        </div>
        <div className="bottomPacman">
          <p>Import in progress...</p>
        </div>
      </div>
    </>
  );
};

export default PacmanLoaderPopUp;
