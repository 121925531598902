import "./ClientPage.css";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { VenteTitles } from "../../../constants/navigationTitles";
import { useData } from "../../../context/DataContext";
import ClientTable from "../../../components/Ventes/Client/ClientTable/ClientTable";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { useSocket } from "../../../context/SocketContext";

const ClientPage = () => {
  const [clients, setClients] = useState([]);
  const [totalClients, setTotalClients] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const { socket } = useSocket();

  const [selectedFilters, setSelectedFilters] = useState({
    country: "All Countries",
  });
  const [currentPage, setCurrentPage] = useState(1);

  const fetchAllClients = async () => {
    if (!selectedDateRange.startDate || !selectedDateRange.endDate) return;

    const queryParams = new URLSearchParams({
      ...selectedFilters,
      startDate: selectedDateRange.startDate.toISOString(),
      endDate: selectedDateRange.endDate.toISOString(),
      page: currentPage,
      limit: 50,
    }).toString();

    try {
      const storedToken = localStorage.getItem("authToken");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/client/allClients?${queryParams}`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      setClients(response.data.clients);
      setTotalClients(response.data.total);
    } catch (err) {
      console.log(err);
    }
  };

  
  useEffect(() => {
    fetchAllClients();
  }, [selectedDateRange, selectedFilters, currentPage]);

  useEffect(() => {
    if (socket == null) return;

    const handleRefresh = () => {
      fetchAllClients();
    };
    socket.on("refreshData", handleRefresh);

    return () => {
      socket.off("refreshData", handleRefresh);
    };
  }, [selectedFilters, socket]);
  

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    if (startDate && endDate) {
      setSelectedDateRange({ startDate, endDate });
    }
  }, []);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            tabs={VenteTitles}
            onFilteredDataChange={handleFilteredDataChange}
          />
        </div>
        <div className="bottomCrmPage">
          <ClientTable
            data={clients}
            reFetch={fetchAllClients}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            totalClients={totalClients}
            currentPage={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientPage;
